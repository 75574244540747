import React, { useState } from "react";
import { ListSvg } from "../../../components/Common/CommonSvg";
import { Button } from "reactstrap";
import planimg from "../../../assets/images/plans-img1.png";
import { connect } from "react-redux";
import { getSubscriptionRequest } from "../../../redux/subscription/subscriptionplans/action";
import { getPlansRequest } from "../../../redux/subscription/plans/action";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

const PlanSelection = ({ setSelectedPlan, planList, getList, doPayment }) => {
  const { id } = useParams();
  const handlePlanSelect = (plan) => {
    setSelectedPlan({ plan, id });
  };
  useEffect(() => {
    getList();
  }, []);
  return (
    <>
      <div div className="container subscription-main">
        <div className="subscription-main-plans">
          <div className="subscription-plans-title">
            <h3>Choose a Plan</h3>
            <p>Select the offer the best suits your need</p>
          </div>
          <div className="subscription-main-plans-list">
            {planList.map((data) => {
              return (
                <label className="radio-card" for="subscriptionId1">
                  {/* <img src={planimg} alt="Plans Image" /> */}
                  <input
                    type="radio"
                    className="form-check-input"
                    id="subscriptionId1"
                    name="subscriptionId"
                  />
                  <div className="card">
                    <div>
                      <h5>{data.plan_name}</h5>
                      <ul>
                        <li>
                          <ListSvg />
                          {data.total_story} Stories Create
                        </li>
                        <li>
                          <ListSvg />
                          {data.total_character} Characters
                        </li>
                        <li>
                          <ListSvg />
                          {data.total_place} Place
                        </li>
                        <li>
                          <ListSvg />
                          {data.total_event} Event
                        </li>
                        <li>
                          <ListSvg />
                          {data.total_scene} Scence
                        </li>
                      </ul>
                    </div>
                    <h3>${data.price}</h3>
                    <span class="check-icon"></span>
                    {/* {
                                                data.plan_name === "FREE" ? (
                                                    <Button
                                                        className="btn-light active"
                                                        title="Activated"
                                                        type="submit"
                                                    >
                                                        Activated
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        className="btn-light"
                                                        title="Purchase"
                                                        type="submit"
                                                        onClick={() => doPayment({ selectedPlan: data.id, userId: id })}
                                                    >
                                                        Purchase
                                                    </Button>
                                                )
                                            } */}
                  </div>
                </label>
              );
            })}
          </div>
          <button className="btn btn-primary" title="Purchase" type="submit">
            Purchase
          </button>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ plans }) => {
  let getDataList = [];
  if (plans?.plans?.list.length > 0 && plans?.plans?.list) {
    getDataList = plans.plans.list;
  }
  return {
    planList: getDataList,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getList: () => dispatch(getPlansRequest()),
    doPayment: (data) => dispatch(getSubscriptionRequest(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlanSelection);
