export const EMAIL_REQUEST = "EMAIL_REQUEST"
export const EMAIL_SUCCESS = "EMAIL_SUCCESS"
export const ROLE_REQUEST = "ROLE_REQUEST"
export const ROLE_SUCCESS = "ROLE_SUCCESS"
export const PLACEHOLDER_REQUEST = "PLACEHOLDER_REQUEST"
export const PLACEHOLDER_SUCCESS = "PLACEHOLDER_SUCCESS"
export const EMAIL_DETAILS_REQUEST = "EMAIL_DETAILS_REQUEST"
export const EMAIL_DETAILS_SUCCESS = "EMAIL_DETAILS_SUCCESS"
export const EMAIL_TEMPLATE_REQUEST = "EMAIL_TEMPLATE_REQUEST"
export const EMAIL_TEMPLATE_SUCCESS = "EMAIL_TEMPLATE_SUCCESS"
export const UPDATE_EMAIL_TEMPLATE_REQUEST = 'UPDATE_EMAIL_TEMPLATE_REQUEST'
export const UPDATE_EMAIL_TEMPLATE_RESPONSE = 'UPDATE_EMAIL_TEMPLATE_RESPONSE'

export const UPDATE_EMAIL_TEMPLATE_STATUS_REQUEST =
    'UPDATE_EMAIL_TEMPLATE_STATUS_REQUEST'
export const UPDATE_EMAIL_TEMPLATE_STATUS_RESPONSE =
    'UPDATE_EMAIL_TEMPLATE_STATUS_RESPONSE'
