import { REMOVE_STORY_REQUEST, REMOVE_STORY_SUCCESS, STORY_REQUEST, STORY_SUCCESS, VIEW_STORY_REQUEST, VIEW_STORY_SUCCESS } from "./type";

const initialState = {
    getStory: null,
    viewStory: null,
    removeStory: null
};
const storyReducer = (state = initialState, action) => {
    switch (action.type) {
        case STORY_REQUEST:
            return {
                ...state,
                getStory: action.payload,
            };
        case STORY_SUCCESS:
            return {
                ...state,
                getStory: action.payload,
            };
        case VIEW_STORY_REQUEST:
            return {
                ...state,
                viewStory: action.payload,
            };
        case VIEW_STORY_SUCCESS:
            return {
                ...state,
                viewStory: action.payload,
            };
        case REMOVE_STORY_REQUEST:
            return {
                ...state,
                removeStory: action.payload,
            };
        case REMOVE_STORY_SUCCESS:
            return {
                ...state,
                removeStory: action.payload,
            };
        default: return state
    }
};
export default storyReducer
