import { GET_WEB_SUBSCRIPTION_PLAN_REQUEST, GET_WEB_SUBSCRIPTION_PLAN_SUCCESS } from "./type";

const initialState = {
    webSubscription: null,
};
const WebSubscriptionReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_WEB_SUBSCRIPTION_PLAN_REQUEST:
            return {
                ...state,
                webSubscription: action.payload,
            };
        case GET_WEB_SUBSCRIPTION_PLAN_SUCCESS:
            return {
                ...state,
                webSubscription: action.payload,
            };
        default: return { ...state }
    }
};
export default WebSubscriptionReducer;
