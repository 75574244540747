import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { invokeApi } from "../../enverment/axiosHelper";
import {
    EMAIL_DETAILS_REQUEST,
    EMAIL_REQUEST,
    EMAIL_TEMPLATE_REQUEST,
    PLACEHOLDER_REQUEST,
    ROLE_REQUEST,
    UPDATE_EMAIL_TEMPLATE_REQUEST,
    UPDATE_EMAIL_TEMPLATE_STATUS_REQUEST,
} from "./type";
import {

    emailDetailsSuccess,
    emailSuccess,
    placeholderSuccess,
    roleSuccess,
    updateEmailTemplateResponse,
    updateEmailTemplateStatusResponse,
} from "./action";
import { ApiResponseMessage } from "../../helper/tosterHelpers";
import { DANGER, SUCCESS } from "../constant/commonConstants";
import { handleApiCallException } from "../shared/action";

function* getEmailData() {
    let response;
    try {
        response = yield call(invokeApi, "GET", `/emailTemplateTypes`);
        if (response.status) {
            yield put(emailSuccess(response.data));
            //
        } else {
            yield put(handleApiCallException(response.data));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getRoleData() {
    let response;
    try {
        response = yield call(invokeApi, "GET", `/roleList`);

        if (response.status) {
            yield put(roleSuccess(response.data));
        } else {
            yield put(handleApiCallException(response.data));
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getPlaceholderData() {
    let response;
    try {
        response = yield call(invokeApi, "GET", `/emailCustomFields`);
        if (response.status) {
            yield put(placeholderSuccess(response.data));
        } else {
            ApiResponseMessage(response.message, DANGER);
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getEmailDetails(action) {
    let response;
    try {
        response = yield call(
            invokeApi,
            "post",
            `/emailTemplateDetails`,
            action.payload
        );

        if (response.status) {
            yield put(emailDetailsSuccess(response.data));
        } else {
            ApiResponseMessage(response.message, DANGER);
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* getEmailTemplateList(action) {
    let response;
    try {
        response = yield call(
            invokeApi,
            "POST",
            `/emailTemplateList`,
            action.payload
        );
        if (response.status) {
            yield put(emailDetailsSuccess(response.data));
        } else {
            ApiResponseMessage(response.message, DANGER);
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* editTemplate({ payload: { data } }) {
    try {

        const response = yield call(
            invokeApi,
            "put",
            "/emailTemplate/" + data.templateId,
            data
        );
        if (response.status) {
            yield put(updateEmailTemplateResponse(response.message, response.status));
        } else {
            ApiResponseMessage(response.message, DANGER);
        }
        ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER);
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* emailTemplateDetailStatus({ payload: { data } }) {
    try {
        const response = yield call(
            invokeApi,
            "put",
            "/emailTemplateDetailStatus",
            data
        );
        if (response.status) {
            yield put(
                updateEmailTemplateStatusResponse(response.message, response.status)
            );
        } else {
            ApiResponseMessage(response.message, DANGER);
        }
        ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER);
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* watchEmailDataRequest() {
    yield takeEvery(ROLE_REQUEST, getRoleData);
    yield takeEvery(EMAIL_REQUEST, getEmailData);
    yield takeEvery(PLACEHOLDER_REQUEST, getPlaceholderData);
    yield takeEvery(EMAIL_DETAILS_REQUEST, getEmailDetails);
    yield takeEvery(EMAIL_TEMPLATE_REQUEST, getEmailTemplateList);
    yield takeEvery(EMAIL_TEMPLATE_REQUEST, getEmailTemplateList);
    yield takeEvery(
        UPDATE_EMAIL_TEMPLATE_STATUS_REQUEST,
        emailTemplateDetailStatus
    );
    yield takeEvery(UPDATE_EMAIL_TEMPLATE_REQUEST, editTemplate);
}
function* EmailSaga() {
    yield all([fork(watchEmailDataRequest)]);
}

export default EmailSaga;
