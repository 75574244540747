import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { handleApiCallException } from "../shared/action";
import { invokeApi } from "../../enverment/axiosHelper";
import { GET_RATE_US_REQUEST } from "./type";
import { getRateListSuccess } from "./action";

function* getRateList({ payload: data }) {
    try {
        const response = yield call(invokeApi, "post", "/rateUsList", data);
        if (response.status) {
            yield put(getRateListSuccess(response.data));
        } else {
            yield put(getRateListSuccess([]));
            handleApiCallException(response.message);
        }
    } catch (error) {
        handleApiCallException(error);
    }
}
export function* RateListRequest() {
    yield takeLatest(GET_RATE_US_REQUEST, getRateList);
}
function* RateListSaga() {
    yield all([fork(RateListRequest)]);
}
export default RateListSaga;
