import "./App.css";
import LoginForm from "./pages/Auth/Login";
import { Navigate, Route, Routes } from "react-router-dom";
import Dashboard from "./pages/Dashboard/Dashboard";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import SetNewPasswod from "./pages/Auth/SetNewPasswod";
import MasterCharacter from "./pages/Master/Character/MasterCharacter";
import Place from "./pages/Master/Place/Place";
import Event from "./pages/Master/Event/Event";
import Scene from "./pages/Master/Scene/Scene";
import SpiceUp from "./pages/Master/SpiceUp/SpiceUp";
import Theme from "./pages/Master/Theme/Theme";
import EmailTemplate from "./pages/Email/EmailTemplate";
import VoiceLanguage from "./pages/Master/Voice/VoiceLanguage";
import "react-player/dist/ReactPlayer";
import ComingSoon from "./components/ComingSoon/ComingSoon";
import NotFound from "./components/404/NotFound";
import React from "react";
import Coupons from "./pages/RedeemCoupons/Coupons";
import Support from "./pages/Support/Support";
import ReportStory from "./pages/ReportStory/ReportStory";
import MainRoute from "./components/Common/MainRoute";
import Layout from "./components/Common/Layout";
import Subscription from "./pages/Subscription/SubscriptionPlans/Subscription";
import Plans from "./pages/Subscription/Plans/Plans";
import ItemsAndPlan from "./pages/Subscription/ItemsAndPlan";
import RateList from "./pages/RateUs/RateList";
import ViewChildList from "./pages/User/UserManagement/ViewChildList";
import ParentList from "./pages/User/UserManagement/ParentList";
import UserStats from "./pages/User/UserStats/UserStats";
import HomePage from "./pages/Frontend/LandingPage/HomePage";
import AboutUs from "./pages/Frontend/LandingPage/AboutUs";
import TermsConditions from "./pages/Frontend/LandingPage/Termsconditions";
import PrivacyPolicy from "./pages/Frontend/LandingPage/Privacypolicy";
import Features from "./pages/Frontend/LandingPage/Features";
import "animate.css/animate.min.css";
import LogsView from "./pages/Logs/LogsView";
import ApiManagement from "./pages/Api/ApiManagement";
import Logs from "./pages/Logs/UserActivityLog/Logs";
import LayoutFront from "./pages/Frontend/common/Layout";
import PaymentHistory from "./pages/PaymentHistory/PaymentHistory";
import ThankYou from "./pages/ThankYouPage/ThankYou";
import FailPayment from "./pages/FailPaymentPage/FailPayment";
import WebSubsription from "./pages/WebSubsription/WebSubsription";

function App() {
  const PrivateRoutes = () => {
    const auth = localStorage.getItem("accessToken");
    if (!auth) {
      return <Navigate to="/" />;
    } else {
      return (
        <Layout>
          <MainRoute />
        </Layout>
      );
    }
  };
  return (
    <>
      <Routes>
        <Route path="/" element={<PrivateRoutes />}>
          <Route path="/home" element={<Dashboard />} exact />
          <Route path="/character" element={<MasterCharacter />} />
          <Route path="/place" element={<Place />} />
          <Route path="/event" element={<Event />} />
          <Route path="/scene" element={<Scene />} />
          <Route path="/spiceup" element={<SpiceUp />} />
          {/* <Route path="/language" element={<Language />} /> */}
          <Route path="/voice" element={<VoiceLanguage />} />
          <Route path="/websubsription" element={<WebSubsription />} />
          <Route path="/theme" element={<Theme />} />
          <Route path="/email" element={<EmailTemplate />} />
          <Route path="/userstats" element={<UserStats />} />
          <Route path="/usermanagement" element={<ParentList />} />
          <Route path="/usermanagement/view/:id" element={<ViewChildList />} />
          <Route path="/userlogs" element={<Logs />} />
          {/* <Route path="/usererrorlog" element={<ErrorLogList />} />
          <Route path="/usertransactionlog" element={<TransactionLogList />} /> */}
          {/* <Route path="/story" element={<Story />} /> */}
          <Route path="/reportstory" element={<ReportStory />} />
          <Route path="/payment" element={<PaymentHistory />} />
          <Route path="/share" element={<ComingSoon />} />
          <Route path="/share" element={<ComingSoon />} />

          {/* <Route path="/subscription" element={<Subscription />} /> */}
          <Route path="/items" element={<ItemsAndPlan />} />
          <Route path="/plans" element={<Plans />} />
          <Route path="/support" element={<Support />} />
          <Route path="/logs" element={<LogsView />} />
          <Route path="/api" element={<ApiManagement />} />
          <Route path="/coupons" element={<Coupons />} />
          <Route path="/themes" element={<ComingSoon />} />
          <Route path="/rateus" element={<RateList />} />
          <Route path="*" element={<NotFound />} />
        </Route>
        {/* <Route element={<LayoutFront />}>
          <Route index element={<HomePage />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/termsconditions" element={<TermsConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/features" element={<Features />} />
        </Route> */}

        <Route path="/thankYou" element={<ThankYou />} />
        <Route path="/failpayment" element={<FailPayment />} />
        <Route index element={<LoginForm />} />
        <Route path="/forgot" element={<ForgotPassword />} />
        {/* <Route path="/subscription/:id" element={<Subscription />} /> */}
        <Route path="/forgot-password" element={<SetNewPasswod />} />
      </Routes>
    </>
  );
}

export default App;
