import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Card } from "reactstrap";
import CustomPagination from "../../../components/Table/CommonPagination";
import CustomisedModal from "../../../components/Modal/CustomisedModal";
import {
  deleteSpiceUpRequest,
  getSpiceUpRequest,
} from "../../../redux/master/spiceup/action";
import {
  commonConfirmBox,
  commonMessangerBox,
} from "../../../helper/messageHelper";
import AddSpiceUp from "./AddSpiceUp";
import { EditSvg } from "../../../components/Common/CommonSvg";
import TableHeader from "../../../components/Table/TableHeader";

function SpiceUp(props) {
  const [getList, setGetList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage, setDataPerPage] = useState(12);
  useEffect(() => {
    props.getList({
      limit: dataPerPage,
      page: currentPage,
    });
  }, [currentPage]);
  useEffect(() => {
    setCurrentPage(1);
    props.getList({
      limit: dataPerPage,
      page: 1,
    });
  }, [dataPerPage]);
  useEffect(() => {
    props.getList({
      limit: dataPerPage,
      page: currentPage,
    });
  }, []);

  useEffect(() => {
    if (props.getData) {
      setGetList(props.getData);
    }
  }, [props.getData]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState("");
  const handleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleEditClick = (row) => {
    if (row?.isActive) {
      setIsModalOpen(!isModalOpen);
      setIsEdit(row);
    } else {
      commonMessangerBox(
        "Before updating spice up you have to activate status"
      );
    }
  };

  const handleDeleteClick = async (data) => {
    const response = await commonConfirmBox(
      data.isActive
        ? "Do you want to inactive this spice-up?"
        : "Do you want to active this spice-up?"
    );
    if (response) {
      props.removeList({
        data: {
          id: data.id,
          isActive: !data.isActive,
        },
        dispatch: {
          limit: dataPerPage,
          page: currentPage,
        }
      });
    }
  };

  return (
    <>
      <TableHeader href={handleModal} buttonName={"Create spice-up"} />
      <div className="master-card-list spiceup_card_list">
        {getList.length > 0 &&
          getList.map((data, index) => {
            return (
              <Card key={index}>
                <div className="spiceup_img">
                  <img
                    src={data.spice_upImage}
                    className="object-fit-cover"
                    alt="theme"
                  />
                </div>
                <div className="card-data">
                  <div className="switch">
                    <input
                      type="checkbox"
                      onChange={() => handleDeleteClick(data)}
                      checked={data.isActive}
                    />
                    <label
                      className="switch-check"
                      onClick={() => {
                        handleDeleteClick(data);
                      }}
                    >
                      <div className="switch-btn"></div>
                      <div className="active-switch">Active</div>
                      <div className="deactive-switch">Inactive</div>
                    </label>
                  </div>
                  <div className="overlay">
                    <a
                      className="btn btn-link edit-btn"
                      title="Edit"
                      onClick={() => handleEditClick(data)}
                    >
                      <EditSvg />
                    </a>
                  </div>
                </div>
                <h5>{data.spice_upName}</h5>
              </Card>
            );
          })}
      </div>
      <div>
        <CustomPagination
          dataPerPage={dataPerPage}
          currentPage={currentPage}
          totalData={props.totalRecords}
          setCurrentPage={setCurrentPage}
          setDataPerPage={setDataPerPage}
        />
      </div>
      {isModalOpen && (
        <CustomisedModal
          isModalOpen={isModalOpen}
          modalName={!isEdit ? "Add Spice-Up" : "Edit Spice-Up"}
          onModalDismiss={() => {
            handleModal();
            setIsEdit(false);
          }}
        >
          <AddSpiceUp
            onModalDismiss={() => {
              handleModal();
              setIsEdit(false);
            }}
            data={isEdit}
          />
        </CustomisedModal>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  let DataList;
  let totalRecords = 0;

  if (
    state?.spiceup?.getSpiceUp?.spiceuplist &&
    state?.spiceup?.getSpiceUp?.spiceuplist?.length > 0
  ) {
    DataList = state?.spiceup?.getSpiceUp?.spiceuplist;
    totalRecords = state?.spiceup?.getSpiceUp?.totalRecords;
  }
  return {
    getData: DataList,
    totalRecords: totalRecords,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getList: (data) => {
      dispatch(getSpiceUpRequest(data));
    },
    removeList: (data) => {
      dispatch(deleteSpiceUpRequest(data));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SpiceUp);
