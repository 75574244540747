import React, { useState } from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { createLoadingSelector } from "../../redux/shared/selector";
import { LoaderSvg } from "./CommonSvg";

function Layout(props) {
    const [isOpen, setIsOpen] = useState(false);
    const handleSubmit = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            {props?.loadingSelector === true && (
                <div className="pre_loader" >
                    <LoaderSvg />
                </div>
            )}
            <div className="admin-layout">
                <div className={isOpen ? "sidebar-main active" : "sidebar-main"}>
                    <Sidebar />
                </div>
                <div className={isOpen ? "main-wrapper active" : "main-wrapper"}>
                    <Header handleSubmit={handleSubmit} name={"dym"} />
                    <div className="wrapper">
                        <div className="small-scroll inner-wrapper">{props.children}</div>
                    </div>
                </div>
            </div>
        </>
    );
}

const mapStatetoProps = createStructuredSelector({
    loadingSelector: createLoadingSelector(),
});

export default connect(mapStatetoProps)(Layout);
