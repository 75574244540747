import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Progress } from "reactstrap";
import { Field, Form, Formik } from "formik";
import SimpleReactValidator from "simple-react-validator";
import { __t } from "../../../i18n/translator";
import { addVoiceInitialState } from "./CommonFunction";
import {
  addVoiceRequest,
  editVoiceRequest,
} from "../../../redux/master/voice/action";
import {
  BoySvg,
  DeleteSvg,
  FileIconSvg,
  GirlSvg,
  ImageSvg,
} from "../../../components/Common/CommonSvg";
import { handleFileUpload } from "../../../helper/imageUploadHelper";
import { ApiResponseMessage } from "../../../helper/tosterHelpers";
import { DANGER } from "../../../redux/constant/commonConstants";

function AddVoice(props) {
  const params = props?.data;
  const validator = useRef(new SimpleReactValidator());
  // const [gender, setGender] = useState(null);
  // const [currGender, setCurrGender] = useState(null);
  const [VoiceData] = useState(
    addVoiceInitialState(
      props?.data
      // gender
    )
  );
  const [imgUpload, setImgUpload] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const handleSubmit = async (values) => {
    if (validator.current.allValid()) {
      const formData = new FormData();
      formData.append("voiceImage", values.voiceImage);
      // formData.append("ssmlGender", gender);
      formData.append("languageCode", values.languageCode);
      formData.append("languageName", values.languageName);
      if (params.id) {
        if (VoiceData !== values) {
          formData.append("id", params.id);
          props.updateVoice(formData);
          props.onModalDismiss(false);
        }
        //  else if (gender !== currGender) {
        //     formData.append("id", params.id);
        //     props.updateVoice(formData);
        //     props.onModalDismiss(false);
        // }
        else {
          ApiResponseMessage(
            __t("validations.common.same_Data_warning"),
            DANGER
          );
        }
      } else {
        props.addVoice(formData);
      }
      setFormSubmitted(true);
    } else {
      validator.current.showMessages();
    }
  };

  useEffect(() => {
    if (formSubmitted) {
      props.onModalDismiss();
      setFormSubmitted(false);
    }
  }, [formSubmitted]);
  const [imageName, setImageName] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleFileChange = (event, setFieldValue) => {
    const { files } = event.currentTarget;
    const selectedFile = files[0];
    let FileType = "voiceImage";
    handleFileUpload(
      selectedFile,
      FileType,
      setFieldValue,
      setImgUpload,
      setImageName,
      setUploadProgress
    );
  };
  const handleClearImage = (setFieldValue) => {
    setFieldValue("voiceImage", null);
    setImgUpload([]);
    setImageName("");
    setUploadProgress(0);
  };

  useEffect(() => {
    // setGender(params.ssmlGender);
    // setCurrGender(params.ssmlGender);
  }, [params.id]);
  useEffect(() => {
    params.id && setUploadProgress(100);
  }, [params.id]);
  const operationType = !params.id ? "Submit" : "Update";
  return (
    <>
      <Formik initialValues={VoiceData} onSubmit={handleSubmit}>
        {({ isSubmitting, values, setFieldValue }) => (
          <Form>
            <div className="form-group mb-2">
              <label htmlFor="languageName">
                Name <span className="text-danger required">*</span>
              </label>
              <Field
                type="text"
                id="languageName"
                className="form-control"
                name="languageName"
                placeholder="Name"
              />
              {validator.current.message(
                __t("validations.Voice.languageName"),
                values.languageName,
                "required",
                {
                  messages: {
                    required: __t("validations.Voice.languageName"),
                  },
                }
              )}
            </div>
            <div className="form-group mb-2">
              <label htmlFor="languageCode">
                Language Code <span className="text-danger required">*</span>
              </label>
              <Field
                type="text"
                className="form-control"
                id="languageCode"
                name="languageCode"
                placeholder="Language code"
              />
              {validator.current.message(
                __t("validations.Voice.languageCode"),
                values.languageCode,
                "required",
                {
                  messages: {
                    required: __t("validations.Voice.languageCode"),
                  },
                }
              )}
            </div>
            {/* <div className="form-group gender-select-box mb-2">
                            <label htmlFor="ssmlGender" className="mb-3">
                                SSML Gender <span className="text-danger required">*</span>
                            </label>

                            <div>
                                <div className="form-check" onClick={() => setGender("FEMALE")}>
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        id="radio1"
                                        name="ssmlGender"
                                        value="female"
                                        checked={gender === "FEMALE"}
                                    />
                                    <label className="form-check-label gender-female" for="radio1">
                                        <GirlSvg />
                                    </label>
                                    <span className="d-block">Female</span>
                                </div>
                                <div className="form-check" onClick={() => setGender("MALE")}>
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        id="radio2"
                                        name="ssmlGender"
                                        value="male"
                                        checked={gender === "MALE"}
                                    />
                                    <label className="form-check-label gender-male" for="radio2">
                                        <BoySvg />
                                    </label>
                                    <span className="d-block">Male</span>
                                </div>
                            </div>
                            {validator.current.message(
                                __t("validations.Voice.ssmlGender"),
                                gender,
                                "required",
                                {
                                    messages: {
                                        required: __t("validations.Voice.ssmlGender"),
                                    },
                                }
                            )}
                        </div> */}
            <div className="dropzone mt-3">
              <>
                <label htmlFor="voiceImage" className="dropzone-container mb-2">
                  <div className="file-icon">
                    <ImageSvg />
                  </div>
                  <div className="dropzone-title">
                    Drag and drop your files or{" "}
                    <span className="browse">browse</span> your files
                  </div>
                </label>
                <input
                  id="voiceImage"
                  name="voiceImage"
                  type="file"
                  accept="image/*"
                  onChange={(event) => handleFileChange(event, setFieldValue)}
                  className="form-control"
                />
              </>

              {validator.current.message(
                __t("validations.Voice.voiceImage"),
                values.voiceImage,
                "required",
                {
                  messages: {
                    required: __t("validations.Voice.voiceImage"),
                  },
                }
              )}

              {values.voiceImage && (
                <div className="attachment-data mt-2">
                  <div className="attachment-head">
                    <a
                      href={imgUpload === null ? values.voiceImage : imgUpload}
                      title={
                        imgUpload === null &&
                        typeof values.voiceImage === "string"
                          ? values.voiceImage.substring(
                              values.voiceImage.lastIndexOf("/") + 1
                            )
                          : imageName
                          ? imageName
                          : "File Name"
                      }
                      className="file-box"
                    >
                      {/* <FileIconSvg />
                                            <h6>
                                                {imgUpload === null &&
                                                    typeof values.voiceImage === "string"
                                                    ? values.voiceImage.substring(
                                                        values.voiceImage.lastIndexOf("/") + 1
                                                    )
                                                    : imageName
                                                        ? imageName
                                                        : "File Name"}
                                            </h6> */}
                      <img
                        src={imgUpload === null ? values.voiceImage : imgUpload}
                        alt="Voice Image"
                      />
                    </a>
                    <a
                      onClick={() => handleClearImage(setFieldValue)}
                      className="remove-box"
                      title="remove"
                    >
                      <DeleteSvg />
                    </a>
                  </div>
                  {uploadProgress < 100 && (
                    <Progress
                      animated
                      color="primary"
                      className="progress_bar mt-1"
                      style={{
                        height: "0.5rem",
                      }}
                      value={uploadProgress}
                    ></Progress>
                  )}
                </div>
              )}
            </div>
            <div className="btn-box">
              <button
                className="btn btn-primary"
                type="submit"
                disabled={isSubmitting}
              >
                {operationType}
              </button>
              <button
                type="button"
                className="btn btn-outline"
                onClick={() => {
                  props.onModalDismiss(false);
                }}
              >
                cancel
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    createVoice: state?.voice?.createVoice,
    // getVoiceData: state?.Voice?.getVoice?.data?.Voicelist,
    updateVoice: state?.voice?.updateVoice,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addVoice: (data) => dispatch(addVoiceRequest(data)),
    updateVoice: (data) => dispatch(editVoiceRequest(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddVoice);
