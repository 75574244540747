import {
    ADD_SPICE_UP_REQUEST,
    ADD_SPICE_UP_SUCCESS,
    DELETE_SPICE_UP_REQUEST,
    DELETE_SPICE_UP_SUCCESS,
    EDIT_SPICE_UP_REQUEST,
    EDIT_SPICE_UP_SUCCESS,

    GET_SPICE_UP_REQUEST,
    GET_SPICE_UP_SUCCESS,
} from "./type";
const initialState = {
    getSpiceUp: null,
    createSpiceUp: null,
    updateSpiceUp: null,
    removeSpiceUp: null,
};

const spiceUpReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SPICE_UP_REQUEST:
            return {
                ...state,
            };
        case GET_SPICE_UP_SUCCESS:
            return {
                ...state,
                getSpiceUp: action.payload,
            };

        case ADD_SPICE_UP_REQUEST:
            return {
                ...state,
                createSpiceUp: action.payload,
            };
        case ADD_SPICE_UP_SUCCESS:
            return {
                ...state,
                createSpiceUp: action.payload,
            };
        case DELETE_SPICE_UP_REQUEST:
            return {
                ...state,
                removeSpiceUp: action.payload,
            };
        case DELETE_SPICE_UP_SUCCESS:
            return {
                ...state,
                removeSpiceUp: action.payload,
            };
        case EDIT_SPICE_UP_REQUEST:
            return {
                ...state,
            };
        case EDIT_SPICE_UP_SUCCESS:
            return {
                ...state,
                updateSpiceUp: action.payload,
            };
        default:
            return { ...state };
    }
};

export default spiceUpReducer;
