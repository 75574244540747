import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { GET_PAYMENT_HISTORY_DETAILS_REQUEST } from "./type";
import {
  getPaymentHistoryDetailsFailure,
  getPaymentHistoryDetailsSuccess,
} from "./action";
import { handleApiCallException } from "../shared/action";
import { invokeApi } from "../../enverment/axiosHelper";

function* getApiData(action) {
  let res;
  try {
    res = yield call(invokeApi, "post", "/paymentHistory", action.payload);
    if (res.status) {
      yield put(getPaymentHistoryDetailsSuccess(res.data));
    } else {
      yield put(getPaymentHistoryDetailsSuccess());
    }
  } catch (error) {
    handleApiCallException(error);
  }
}

function* watchPaymentHistory() {
  yield takeLatest(GET_PAYMENT_HISTORY_DETAILS_REQUEST, getApiData);
}

function* PaymentHistorySaga() {
  yield all([fork(watchPaymentHistory)]);
}
export default PaymentHistorySaga;
