import {
  ADD_ITEMS_PLAN_REQUEST,
  ADD_ITEMS_PLAN_SUCCESS,
  EDIT_ITEMS_PLAN_REQUEST,
  EDIT_ITEMS_PLAN_SUCCESS,
  GET_ITEMS_PLAN_REQUEST,
  GET_ITEMS_PLAN_SUCCESS,
  REMOVE_ITEMS_PLAN_REQUEST,
  REMOVE_ITEMS_PLAN_SUCCESS,
} from "./type";

export const getItemsPlanRequest = () => {
  return {
    type: GET_ITEMS_PLAN_REQUEST,
  };
};
export const getItemsPlanSuccess = (ItemsPlan) => {
  return {
    type: GET_ITEMS_PLAN_SUCCESS,
    payload: ItemsPlan,
  };
};

export const addItemsPlanRequest = (ItemsPlan) => {
  return {
    type: ADD_ITEMS_PLAN_REQUEST,
    payload: ItemsPlan,
  };
};
export const addItemsPlanSuccess = (ItemsPlan) => {
  return {
    type: ADD_ITEMS_PLAN_SUCCESS,
    payload: ItemsPlan,
  };
};
export const editItemsPlanRequest = (ItemsPlan) => {
  return {
    type: EDIT_ITEMS_PLAN_REQUEST,
    payload: ItemsPlan,
  };
};
export const editItemsPlanSuccess = (ItemsPlan) => {
  return {
    type: EDIT_ITEMS_PLAN_SUCCESS,
    payload: ItemsPlan,
  };
};
export const removeItemsPlanRequest = (ItemsPlan) => {
  return {
    type: REMOVE_ITEMS_PLAN_REQUEST,
    payload: ItemsPlan,
  };
};
export const removeItemsPlanSuccess = (ItemsPlan) => {
  return {
    type: REMOVE_ITEMS_PLAN_SUCCESS,
    payload: ItemsPlan,
  };
};
