import {
    ADD_THEME_REQUEST,
    ADD_THEME_SUCCESS,
    DELETE_THEME_REQUEST,
    DELETE_THEME_SUCCESS,
    EDIT_THEME_REQUEST,
    EDIT_THEME_SUCCESS,
    GET_THEME_FAILURE,
    GET_THEME_REQUEST,
    GET_THEME_SUCCESS,
} from "./type";
const initialState = {
    getTheme: null,
    createTheme: null,
    updateTheme: null,
    removeTheme: null,
};

const themeReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_THEME_REQUEST:
            return {
                ...state,
            };
        case GET_THEME_SUCCESS:
            return {
                ...state,
                getTheme: action.payload,
            };
        case GET_THEME_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case ADD_THEME_REQUEST:
            return {
                ...state,
                createTheme: action.payload,
            };
        case ADD_THEME_SUCCESS:
            return {
                ...state,
                createTheme: action.payload,
            };
        case DELETE_THEME_REQUEST:
            return {
                ...state,
                removeTheme: action.payload,
            };
        case DELETE_THEME_SUCCESS:
            return {
                ...state,
                removeTheme: action.payload,
            };
        case EDIT_THEME_REQUEST:
            return {
                ...state,
            };
        case EDIT_THEME_SUCCESS:
            return {
                ...state,
                Theme: action.payload,
            };
        default:
            return { ...state };
    }
};

export default themeReducer;
