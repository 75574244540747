import { GET_RATE_US_REQUEST, GET_RATE_US_SUCCESS } from "./type";

const initialState = {
  getRateList: null,
};

const rateReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_RATE_US_REQUEST:
      return {
        ...state,
        getRateList: null,
      };
    case GET_RATE_US_SUCCESS:
      return {
        ...state,
        getRateList: action.payload,
      };
    default:
      return { ...state };
  }
};
export default rateReducer;
