import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { GET_SUBSCRIPTION_PLAN_REQUEST, GET_WEB_SUBSCRIPTION_PLAN_REQUEST } from "./type";
import { invokeApi } from "../../../enverment/axiosHelper";
import { getSubscriptionSuccess, getWebSubscriptionSuccess } from "./action";
import { handleApiCallException } from "../../shared/action";
import { DANGER } from "../../constant/commonConstants";
import { ApiResponseMessage } from "../../../helper/tosterHelpers";

function* fetchSubscription({ payload: data }) {
    try {
        const response = yield call(invokeApi, "get", `/webSubscriptionList`, data);
        if (response.status) {
            yield put(getWebSubscriptionSuccess(response.data));

        } else {
            ApiResponseMessage(response.message, DANGER);
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* watchWebSubscriptionSagaRequest() {
    yield takeLatest(GET_WEB_SUBSCRIPTION_PLAN_REQUEST, fetchSubscription);
}

function* webSubscriptionSaga() {
    yield all([fork(watchWebSubscriptionSagaRequest)]);
}

export default webSubscriptionSaga;
