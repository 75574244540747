import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Progress } from "reactstrap";
import { Field, Form, Formik } from "formik";
import SimpleReactValidator from "simple-react-validator";
import { __t } from "../../../i18n/translator";
import { addLanguageRequest, editLanguageRequest } from "../../../redux/master/language/action";
import { addLanguageInitialState } from "./CommonFunction";
import { handleFileUpload } from "../../../helper/imageUploadHelper";
import { DeleteSvg, FileIconSvg, ImageSvg } from "../../../components/Common/CommonSvg";
import { ApiResponseMessage } from "../../../helper/tosterHelpers";
import { DANGER } from "../../../redux/constant/commonConstants";

function AddLanguage(props) {
    const params = props?.data || {};
    const validator = useRef(new SimpleReactValidator());
    const [LanguageData] = useState(
        addLanguageInitialState(props?.data)
    );
    const [imgUpload, setImgUpload] = useState(null)
    const [formSubmitted, setFormSubmitted] = useState(false)
    const handleSubmit = async (values) => {
        if (validator.current.allValid()) {
            const formData = new FormData();
            formData.append("languageImage", values.languageImage);
            formData.append("languageName", values.languageName);
            if (params.id) {
                if (LanguageData !== values) {
                    formData.append("id", params.id)
                    props.updateLanguage(formData)
                    props.onModalDismiss(false);
                } else {
                    ApiResponseMessage(__t("validations.common.same_Data_warning"), DANGER)
                }
            } else {
                props.addLanguage(formData)
            }
            setFormSubmitted(true)
        } else {
            validator.current.showMessages();
        }
    };

    useEffect(() => {
        if (formSubmitted) {
            props.onModalDismiss(false)
            setFormSubmitted(false)
        }
    }, [formSubmitted]);
    const operationType = !params.id ? "Submit" : "Update";
    const [imageName, setImageName] = useState("");
    const [uploadProgress, setUploadProgress] = useState(0);

    const handleFileChange = (event, setFieldValue) => {
        const { files } = event.currentTarget;
        const selectedFile = files[0];
        let FileType = "languageImage";
        handleFileUpload(
            selectedFile,
            FileType,
            setFieldValue,
            setImgUpload,
            setImageName,
            setUploadProgress
        );
    };
    const handleClearImage = (setFieldValue) => {
        setFieldValue("languageImage", null);
        setImgUpload([]);
        setImageName("");
        setUploadProgress(0);
    };
    useEffect(() => {
        params.id && setUploadProgress(100);
    }, [params.id]);
    return (
        <>

            <Formik initialValues={LanguageData} onSubmit={handleSubmit}>
                {({ isSubmitting, values, setFieldValue }) => (
                    <Form>
                        <div className="form-group mb-2">
                            <label htmlFor="languageName">Name <span className="text-danger required">*</span></label>
                            <Field type="text" id="languageName" className="form-control" placeholder="Name" name="languageName" />
                            {validator.current.message(
                                __t("validations.language.languageName"),
                                values.languageName,
                                "required",
                                {
                                    messages: {
                                        required: __t("validations.language.languageName"),
                                    },
                                }
                            )}
                        </div>

                        <div className="dropzone mt-3">

                            <label
                                htmlFor="languageImage"
                                className="dropzone-container mb-2"
                            >
                                <div className="file-icon">
                                    <ImageSvg />
                                </div>
                                <div className="dropzone-title">
                                    Drag and drop your files or{" "}
                                    <span className="browse">browse</span> your files
                                </div>
                            </label>
                            <input
                                id="languageImage"
                                name="languageImage"
                                type="file"
                                accept="image/*"
                                onChange={(event) => handleFileChange(event, setFieldValue)}
                                className="form-control"
                            />

                            {validator.current.message(
                                __t("validations.language.languageImage"),
                                values.languageImage,
                                "required",
                                {
                                    messages: {
                                        required: __t("validations.language.languageImage"),
                                    },
                                }
                            )}

                            {values.languageImage && (
                                <div className="attachment-data mt-2">
                                    <div className="attachment-head">
                                        <a
                                            href={imgUpload === null ? values.languageImage : imgUpload}
                                            title={imgUpload === null &&
                                                typeof values.languageImage === "string"
                                                ? values.languageImage.substring(
                                                    values.languageImage.lastIndexOf("/") + 1
                                                )
                                                : imageName
                                                    ? imageName
                                                    : "File Name"}                                            
                                            className="file-box"
                                        >
                                            {/* <FileIconSvg />
                                            <h6>
                                                {imgUpload === null &&
                                                    typeof values.languageImage === "string"
                                                    ? values.languageImage.substring(
                                                        values.languageImage.lastIndexOf("/") + 1
                                                    )
                                                    : imageName
                                                        ? imageName
                                                        : "File Name"}
                                            </h6> */}
                                            <img src={imgUpload === null ? values.languageImage : imgUpload} alt="Language Image"/>
                                        </a>
                                        <a
                                            onClick={() => handleClearImage(setFieldValue)}
                                            className="remove-box"
                                            title="remove"
                                        >
                                            <DeleteSvg />
                                        </a>
                                    </div>
                                    {uploadProgress < 100 && (
                                        <Progress
                                            animated
                                            color="primary"
                                            className="progress_bar mt-1"
                                            style={{
                                                height: "0.5rem",
                                            }}
                                            value={uploadProgress}
                                        ></Progress>
                                    )}
                                </div>
                            )}
                        </div>


                        <div className="btn-box">
                            <button className="btn btn-primary" type="submit" disabled={isSubmitting}>
                                {operationType}
                            </button>
                            <button
                                type="button"
                                className="btn btn-outline"
                                onClick={() => {
                                    props.onModalDismiss(false);
                                }}
                            >
                                cancel
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
}

const mapStateToProps = (state) => {

    return {
        createLanguage: state?.language?.createLanguage,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addLanguage: (data) => dispatch(addLanguageRequest(data)),
        updateLanguage: (data) => dispatch(editLanguageRequest(data)),

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddLanguage);
