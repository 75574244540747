export const lineChartOptionSeriesFunction = (dataList) => {
  const shortMonthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const formattedDate = dataList?.date.map((dateString) => {
    const date = new Date(dateString);
    return `${shortMonthNames[date.getMonth()]} ${date.getFullYear()}`;
  });
  const Failed = dataList && dataList?.MonthlyTotalFailedStories?.reverse();
  const Created = dataList && dataList?.MonthlyTotalStories?.reverse();

  let lineChart = {
    series: [
      {
        name: "Created  Stories",
        data: Created,
        //   data: [31, 40, 28, 51, 42, 109, 100],
      },
      {
        name: "Failed  Stories",
        data: Failed,
        // data: [11, 32, 45, 32, 34, 52, 41],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "area",
        toolbar: { show: false },
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },

      xaxis: {
        type: "category",
        categories: formattedDate?.reverse(),
        floating: false,
        decimalsInFloat: undefined,
        labels: {
          show: true,
        },
        tooltip: {
          enabled: false,
        },
      },
      tooltip: {
        x: {
          format: "dd/MM/yy",
        },
      },
      colors: ["#3f51b5", "#f4488d"],
      textDirection: "ltr",
    },
  };

  return lineChart;
};

export const donutChartOptionSeriesFunction = (dataList) => {
  const dataChart = {
    series: [dataList?.totalPaidUsers, dataList?.totalFreeUsers],
    options: {
      chart: {
        height: 400,
        type: "radialBar",
      },
      legend: {
        show: true,
        showForSingleSeries: false,
        showForNullSeries: true,
        showForZeroSeries: true,
        position: "bottom",
        horizontalAlign: "center",
        floating: false,
        fontSize: "14px",
        fontFamily: "Helvetica, Arial",
        fontWeight: 400,
        inverseOrder: false,
        offsetX: 0,
        offsetY: 0,
        labels: { colors: undefined, useSeriesColors: false },
      },
      fill: { colors: ["#99d665", "#f39272"] },
      plotOptions: {
        radialBar: {
          dataLabels: {
            colors: ["#c4e888", "#f39272"],
            name: {
              fontSize: "22px",
            },
            value: {
              formatter: function (val) {
                return val;
              },
              fontSize: "16px",
            },
            total: {
              show: true,
              label: "Total",
              formatter: (val) => {
                const total = val.config.series.reduce((acc, curr) => acc + curr, 0);
                return total;
              },
            },
          },
        },
        colors: ["#000", "#C4E888"],
      },
      labels: ["Paid", "Free"],
      colors: ["#c4e888", "#f39272"],
    },
  };

  return dataChart;
};


export const donutChartDeviceOptionSeriesFunction = (dataList) => {

  const dataChart = {
    series: [dataList?.androidCount, dataList?.iOSCount],
    options: {
      chart: {
        height: 400,
        type: "radialBar",
      },
      legend: {
        show: true,
        showForSingleSeries: false,
        showForNullSeries: true,
        showForZeroSeries: true,
        position: "bottom",
        horizontalAlign: "center",
        floating: false,
        fontSize: "14px",
        fontFamily: "Helvetica, Arial",
        fontWeight: 400,
        inverseOrder: false,
        offsetX: 0,
        offsetY: 0,
        labels: { colors: undefined, useSeriesColors: false },
      },
      fill: { colors: ["#ffc107", "#17C9FB"] },
      plotOptions: {
        radialBar: {
          dataLabels: {
            colors: ["#c4e888", "#f39272"],
            name: {
              fontSize: "22px",
            },
            value: {
              formatter: function (val) {
                return val;
              },
              fontSize: "16px",
            },
            total: {
              show: true,
              label: "Total",
              formatter: (val) => {
                console.log(val.config, "val.config")
                const total = val.config.series.reduce((acc, curr) => acc + curr, 0);
                return total;
              },
            },
          },
        },
        colors: ["#000", "#C4E888"],
      },
      labels: ["Android", "IOS"],
      colors: ["#ffc107", "#17C9FB"],
    },
  };

  return dataChart;
};

export const getLineChartData = (item) => {
  const MonthlyTotalFailedStories = item?.map((x) => x?.totalFailedStories);
  const MonthlyTotalStories = item?.map((x) => x?.totalStories);
  const DateArray = item?.map((item) => `${item?.month} ${item?.year}`);
  return { MonthlyTotalFailedStories, MonthlyTotalStories, DateArray };
};
// old Code
//   const [lineChart, setLineChart] = useState({
//     series: [
//       {
//         name: "TotalFailedStory",
//         data: dataList?.MonthlyTotalFailedStories,
//       },
//       {
//         name: "TotalCreatedStory",
//         data: dataList?.MonthlyTotalStories,
//       },
//     ],
//     options: {
//       chart: {
//         height: 350,
//         type: "area",
//         toolbar: { show: false },
//       },
//       dataLabels: {
//         enabled: false,
//       },
//       stroke: {
//         curve: "smooth",
//       },
//       xaxis: {
//         type: "datetime",
//         categories: [
//           "2018-09-19T00:00:00.000Z",
//           "2018-09-19T01:30:00.000Z",
//           "2018-09-19T02:30:00.000Z",
//           "2018-09-19T03:30:00.000Z",
//           "2018-09-19T04:30:00.000Z",
//           "2018-09-19T05:30:00.000Z",
//           "2018-09-19T06:30:00.000Z",
//         ],
//       },
//       tooltip: {
//         x: {
//           format: "dd/MM/yy HH:mm",
//         },
//       },
//       colors: ["#3f51b5", "#f4488d"],
//     },
//   });

//   const [chartData, setChartData] = useState({
//     series: [dataList?.totalPaidUsers, dataList?.totalFreeUsers],
//     options: {
//       chart: {
//         height: 400,
//         type: "radialBar",
//       },
//       legend: {
//         show: true,
//         showForSingleSeries: false,
//         showForNullSeries: true,
//         showForZeroSeries: true,
//         position: "bottom",
//         horizontalAlign: "center",
//         floating: false,
//         fontSize: "14px",
//         fontFamily: "Helvetica, Arial",
//         fontWeight: 400,
//         inverseOrder: false,
//         offsetX: 0,
//         offsetY: 0,
//         labels: { colors: undefined, useSeriesColors: false },
//       },
//       fill: { colors: ["#99d665", "#f39272"] },
//       plotOptions: {
//         radialBar: {
//           dataLabels: {
//             colors: ["#c4e888", "#f39272"],
//             name: {
//               fontSize: "22px",
//             },
//             value: {
//               fontSize: "16px",
//             },
//             total: {
//               show: true,
//               label: "Total",
//               formatter: function (w) {
//                 return 249;
//               },
//             },
//           },
//         },
//         colors: ["#000", "#C4E888"],
//       },
//       labels: ["Paid", "Free"],
//       colors: ["#c4e888", "#f39272"],
//     },
//   });

// useEffect(() => {
// setChartData((prevChartData) => ({
//   ...prevChartData,
//   series: [dataList?.totalPaidUsers, dataList?.totalFreeUsers],
// }));
// setLineChart((prevLineChart) => ({
//   ...prevLineChart,
//   series: [
//     {
//       name: "FailedStory",
//       data: [31, 40, 28, 51, 42, 109, 100],
//     },
//     {
//       name: "CreatedStory",
//       data: [11, 32, 45, 32, 34, 52, 41],
//     },
//   ],
// }));
// }, [dataList]);
