import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Progress } from "reactstrap";
import { Field, Form, Formik } from "formik";
import SimpleReactValidator from "simple-react-validator";
import {
    addPlaceDataRequest,
    editPlaceDataRequest,
    getPlaceDataRequest,
} from "../../../redux/master/place/action";
import { addPlaceInitialState } from "./CommonFunction";
import { handleFileUpload } from "../../../helper/imageUploadHelper";
import {
    DeleteSvg,
    FileIconSvg,
    ImageSvg,
} from "../../../components/Common/CommonSvg";
import { __t } from "../../../i18n/translator";
import { ApiResponseMessage } from "../../../helper/tosterHelpers";
import { DANGER } from "../../../redux/constant/commonConstants";

function AddPlace(props) {
    const params = props?.data ? props?.data : {};
    const validator = useRef(new SimpleReactValidator());
    const { dataPerPage, currentPage } = props;
    const [PlaceData] = useState(addPlaceInitialState(props?.data));
    const handleSubmit = async (values) => {
        if (validator.current.allValid()) {
            const formData = new FormData();
            formData.append("placeImage", values.placeImage);
            formData.append("placeName", values.placeName);
            if (params.id) {
                if (PlaceData !== values) {
                    formData.append("id", params.id);
                    props.updatePlace({ formData, dataPerPage, currentPage });
                    setFormSubmitted(true);
                } else {
                    ApiResponseMessage(
                        __t("validations.common.same_Data_warning"),
                        DANGER
                    );
                }
            } else {
                props.addPlace({ formData, dataPerPage, currentPage });
                setFormSubmitted(true);
            }
            props.onModalDismiss(false);
        } else {
            validator.current.showMessages();
        }
    };
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [imageName, setImageName] = useState();

    useEffect(() => {
        if (formSubmitted && props.createPlace != null) {
            props.onModalDismiss(false);
        }
    }, [props.createPlace]);
    const operationType = !params.id ? "Submit" : "Update";
    const [imgUpload, setImgUpload] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);

    const handleFileChange = (event, setFieldValue) => {
        const { files } = event.currentTarget;
        const selectedFile = files[0];
        const FileType = "placeImage";
        handleFileUpload(
            selectedFile,
            FileType,
            setFieldValue,
            setImgUpload,
            setImageName,
            setUploadProgress
        );
    };
    const handleClearImage = (setFieldValue) => {
        setFieldValue("placeImage", null);
        setImgUpload([]);
        setImageName("");
        setUploadProgress(0);
    };
    useEffect(() => {
        if (params !== null) {
            setUploadProgress(100);
        }
    }, [params]);

    return (
        <>
            <Formik initialValues={PlaceData} onSubmit={handleSubmit}>
                {({ isSubmitting, values, setFieldValue }) => (
                    <Form>
                        <div className="form-group mb-2">
                            <label htmlFor="placeName">
                                Place Name <span className="text-danger required">*</span>
                            </label>
                            <Field
                                type="text"
                                id="placeName"
                                className="form-control"
                                name="placeName"
                                placeholder="Place name"
                            />
                            {validator.current.message(
                                __t("validations.place.placeName"),
                                values.placeName,
                                "required",
                                {
                                    messages: {
                                        required: __t("validations.place.placeName"),
                                    },
                                }
                            )}
                        </div>

                        <div className="dropzone mt-3">
                            <label htmlFor="placeImage" className="dropzone-container mb-2">
                                <div className="file-icon">
                                    <ImageSvg />
                                </div>
                                <div className="dropzone-title">
                                    Drag and drop your files or{" "}
                                    <span className="browse">browse</span> your files
                                </div>
                            </label>
                            <input
                                id="placeImage"
                                name="placeImage"
                                type="file"
                                accept="image/*"
                                onChange={(event) => handleFileChange(event, setFieldValue)}
                                className="form-control"
                            />

                            {validator.current.message(
                                __t("validations.place.placeImage"),
                                values.placeImage,
                                "required",
                                {
                                    messages: {
                                        required: __t("validations.place.placeImage"),
                                    },
                                }
                            )}

                            {values.placeImage && (
                                <div className="attachment-data mt-2">
                                    <div className="attachment-head">
                                        <a
                                            href={imgUpload === null ? values.placeImage : imgUpload}
                                            title={
                                                imgUpload === null &&
                                                    typeof values.placeImage === "string"
                                                    ? values.placeImage.substring(
                                                        values.placeImage.lastIndexOf("/") + 1
                                                    )
                                                    : imageName
                                                        ? imageName
                                                        : "File Name"
                                            }                                           
                                            className="file-box"
                                        >
                                            {/* <FileIconSvg />
                                            <h6>
                                                {
                                                    (() => {
                                                        if (imgUpload === null && typeof values.placeImage === "string") {
                                                            return values.placeImage.substring(values.placeImage.lastIndexOf("/") + 1);
                                                        } else if (imageName) {
                                                            return imageName;
                                                        } else {
                                                            return "File Name";
                                                        }
                                                    })()
                                                }
                                            </h6> */}
                                            <img src={imgUpload === null ? values.placeImage : imgUpload} alt="Theme Image"/>
                                        </a>
                                        <a
                                            onClick={() => handleClearImage(setFieldValue)}
                                            className="remove-box"
                                            title="remove"
                                        >
                                            <DeleteSvg />
                                        </a>
                                    </div>
                                    {uploadProgress > 99 ? null : (
                                        <Progress
                                            animated
                                            color="primary"
                                            className="progress_bar mt-1"
                                            style={{
                                                height: "0.5rem",
                                            }}
                                            value={uploadProgress}
                                        ></Progress>
                                    )}
                                </div>
                            )}
                        </div>
                        <div className="btn-box">
                            <button
                                className="btn btn-primary"
                                type="submit"
                                disabled={isSubmitting}
                            >
                                {operationType}
                            </button>
                            <button
                                className="btn btn-outline"
                                type="button"
                                onClick={() => {
                                    props.onModalDismiss(false);
                                }}
                            >
                                cancel
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        createPlace: state?.place?.createPlace,
        getPlaceData: state?.place?.getPlace?.data?.placelist,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getList: (data) => {
            dispatch(getPlaceDataRequest(data));
        },
        addPlace: (data) => dispatch(addPlaceDataRequest(data)),
        updatePlace: (data) => dispatch(editPlaceDataRequest(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPlace);
