import { EMAIL_DETAILS_REQUEST, EMAIL_DETAILS_SUCCESS, EMAIL_REQUEST, EMAIL_SUCCESS, EMAIL_TEMPLATE_REQUEST, EMAIL_TEMPLATE_SUCCESS, PLACEHOLDER_REQUEST, PLACEHOLDER_SUCCESS, ROLE_REQUEST, ROLE_SUCCESS, UPDATE_EMAIL_TEMPLATE_REQUEST, UPDATE_EMAIL_TEMPLATE_RESPONSE, UPDATE_EMAIL_TEMPLATE_STATUS_REQUEST, UPDATE_EMAIL_TEMPLATE_STATUS_RESPONSE } from "./type"

export const emailRequest = (data) => ({
    type: EMAIL_REQUEST,
}
)
export const emailSuccess = (data) => ({
    type: EMAIL_SUCCESS,
    payload: data

})

export const roleRequest = (data) => ({
    type: ROLE_REQUEST,
}
)
export const roleSuccess = (data) => ({
    type: ROLE_SUCCESS,
    payload: data

})
export const placeholderRequest = (data) => ({
    type: PLACEHOLDER_REQUEST,
}
)
export const placeholderSuccess = (data) => ({
    type: PLACEHOLDER_SUCCESS,
    payload: data

})

export const emailDetailsRequest = (data) => {

    return {
        type: EMAIL_DETAILS_REQUEST,
        payload: data
    }
}

export const emailDetailsSuccess = (data) => ({
    type: EMAIL_DETAILS_SUCCESS,
    payload: data
})
export const emailTemplateRequest = (data) => ({
    type: EMAIL_TEMPLATE_REQUEST,
    payload: data
}
)
export const emailTemplateSuccess = (data) => ({
    type: EMAIL_TEMPLATE_SUCCESS,
    payload: data
})

export const updateEmailTemplateRequest = (data) => {
    return {
        type: UPDATE_EMAIL_TEMPLATE_REQUEST,
        payload: { data },
    }
}
export const updateEmailTemplateResponse = (responseType) => {
    return {
        type: UPDATE_EMAIL_TEMPLATE_RESPONSE,
        payload: { responseType },
    }
}

export const updateEmailTemplateStatusRequest = (data) => {
    return {
        type: UPDATE_EMAIL_TEMPLATE_STATUS_REQUEST,
        payload: { data },
    }
}
export const updateEmailTemplateStatusResponse = (responseType) => {
    return {
        type: UPDATE_EMAIL_TEMPLATE_STATUS_RESPONSE,
        payload: { responseType },
    }
}