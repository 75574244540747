import {
    ADD_LANGUAGE_REQUEST,
    ADD_LANGUAGE_SUCCESS,
    DELETE_LANGUAGE_REQUEST,
    DELETE_LANGUAGE_SUCCESS,
    EDIT_LANGUAGE_REQUEST,
    EDIT_LANGUAGE_SUCCESS,
    GET_LANGUAGE_FAILURE,
    GET_LANGUAGE_REQUEST,
    GET_LANGUAGE_SUCCESS,
} from "./type";
const initialState = {
    getLanguage: null,
    createLanguage: null,
    updateLanguage: null,
    removeLanguage: null,
};

const languageReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_LANGUAGE_REQUEST:
            return {
                ...state,
            };
        case GET_LANGUAGE_SUCCESS:
            return {
                ...state,
                getLanguage: action.payload,
            };
        case GET_LANGUAGE_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case ADD_LANGUAGE_REQUEST:
            return {
                ...state,
                createLanguage: action.payload,
            };
        case ADD_LANGUAGE_SUCCESS:
            return {
                ...state,
                createLanguage: action.payload,
            };
        case DELETE_LANGUAGE_REQUEST:
            return {
                ...state,
                removeLanguage: action.payload,
            };
        case DELETE_LANGUAGE_SUCCESS:
            return {
                ...state,
                removeLanguage: action.payload,
            };
        case EDIT_LANGUAGE_REQUEST:
            return {
                ...state,
            };
        case EDIT_LANGUAGE_SUCCESS:
            return {
                ...state,
                Language: action.payload,
            };
        default:
            return { ...state };
    }
};

export default languageReducer;
