import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getDateTimeFromDateTimeString } from "../../../helper/DataTimeHelpers";
import CommonTable from "../../../components/Table/CommonTable";

function TransactionLog(props) {
  const { getData, totalRecords } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage, setDataPerPage] = useState(10);
  const [searchData, setSearchData] = useState();
  useEffect(() => {
    props.getList({
      limit: dataPerPage,
      page: currentPage,    
      search: searchData ? searchData : "",
    });
  }, [currentPage, dataPerPage, searchData]);
  useEffect(() => {
    setCurrentPage(1);
  }, [dataPerPage]);
  const columns = [
    {
      dataField: "Name",
      text: "Name",
    //   formatter: (cell, row) => <>{row.firstName + " " + row.lastName}</>,
      sort: true,
      headerClasses: "date-time-box",
    },
    {
      dataField: "activity",
      text: "activity",
      formatter: (cell, row) => <>{row.activity}</>,
      sort: true,
    },

    {
        dataField: "ipAddress",
        text: "IP Address",
        formatter: (cell, row) => <>{row.ipAddress}</>,
        sort: true,
    },
    {
      dataField: "createdDate",
      text: "Created Date",
      formatter: (cell, row) => (
        <>{getDateTimeFromDateTimeString(row.createdDate)}</>
      ),
      sort: true,
      headerClasses: "date-time-box",
    },
    // {
    //     dataField: "Action",
    //     // text: "Action",
    //     formatter: (cell, row) => (
    //         <>
    //             <Link
    //                 Link
    //                 className="btn btn-link text-success d-inline"
    //                 title="View"
    //                 to={`/usermanagement/view/${row.id}`}
    //                 state={{ UserData: row, ModuleName: "User Management" }}
    //             >
    //                 <ViewSvg />
    //             </Link>
    //         </>
    //     ),
    //     sort: false,
    //     classes: "text-center",
    //     headerClasses: "action-box text-center", // Add custom class to header
    // },
  ];

  return (
    <div>
      <CommonTable
        searchBar={false}
        Header={false}
        pagination={true}
        data={getData ? getData : []}
        columns={columns}
        setCurrentPage={setCurrentPage}
        dataPerPage={dataPerPage}
        setDataPerPage={setDataPerPage}
        currentPage={currentPage}
        setSearchData={setSearchData}
        totalRecords={totalRecords}
        updateData={props.getList}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  let DataList;
  let totalRecords = 0;

  if (state?.logs?.data && state?.logs?.data?.transactionLogEntries?.length > 0) {
    DataList = state.logs.data?.transactionLogEntries;
    totalRecords = state.logs.data.totalRecords;
  }

  return {
    getData: DataList,
    totalRecords: totalRecords,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getList: (data) => {
    //   dispatch(getUserTransactionLogRequest(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionLog);
