import {
  CREATE_STORY_REQUEST,
  CREATE_STORY_SUCCESS,
  GET_ACTIVE_REQUEST,
  GET_ACTIVE_SUCCESS,
  GET_REPORT_STORT_REQUEST,
  GET_REPORT_STORT_SUCCESS,
  GET_VIEW_STORY_REQUEST,
  GET_VIEW_STORY_SUCCESS,
} from "./type";

const initialState = {
  ReportStory: null,
  changeStatus: null,
  viewStory: null,
};
const reportStoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REPORT_STORT_REQUEST:
      return {
        ...state,
        ReportStory: action.payload,
      };
    case GET_REPORT_STORT_SUCCESS:
      return {
        ...state,
        ReportStory: action.payload,
      };
    case GET_ACTIVE_REQUEST:
      return {
        ...state,
        changeStatus: action.payload,
      };
    case GET_ACTIVE_SUCCESS:
      return {
        ...state,
        changeStatus: action.payload,
      };
    case GET_VIEW_STORY_REQUEST:
      return {
        ...state,
        viewStory: action.payload,
      };
    case GET_VIEW_STORY_SUCCESS:
      return {
        ...state,
        viewStory: action.payload,
      };

    case CREATE_STORY_REQUEST:
      return {
        ...state,
        viewStory: action.payload,
      };
    case CREATE_STORY_SUCCESS:
      return {
        ...state,
        viewStory: action.payload,
      };

    default:
      return { ...state };
  }
};
export default reportStoryReducer;
