import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import {
  ADD_API_MANGEMENT_DETAILS_REQUEST,
  GET_API_MANAGEMENT_DETAILS_REQUEST,
  UPDATE_API_MANGEMENT_DETAILS_REQUEST,
} from "./type";
import {
  addApiManagementDetailsSuccess,
  getApiManagementDetailsRequest,
  getApiManagementDetailsSuccess,
  updateApiManagementDetailsSuccess,
} from "./action";
import { handleApiCallException } from "../shared/action";
import { invokeApi } from "../../enverment/axiosHelper";
import { DANGER, SUCCESS } from "../constant/commonConstants";
import { ApiResponseMessage } from "../../helper/tosterHelpers";

function* getApiData(action) {
  let res;
  try {
    res = yield call(invokeApi, "get", "/getApiManagement");
    if (res.status) {
      yield put(getApiManagementDetailsSuccess(res.data));
    } else {
      yield put(getApiManagementDetailsSuccess());
    }
  } catch (error) {
    handleApiCallException(error.message);
  }
}

function* UpdateApiData(action) {
  let res;
  try {
    res = yield call(invokeApi, "put", "/updateApiManagement", action.payload);
    if (res.status) {
      yield put(updateApiManagementDetailsSuccess(res.data));
      yield put(getApiManagementDetailsRequest([]));
    } else {
      yield put(updateApiManagementDetailsSuccess());
    }
    ApiResponseMessage(res.message, res.status ? SUCCESS : DANGER);
  } catch (error) {
    handleApiCallException(error.message);
  }
}

function* AddApiData(action) {
  let res;
  try {
    res = yield call(invokeApi, "post", "/apiManagement", action.payload);
    if (res.status) {
      yield put(addApiManagementDetailsSuccess(res.data));
      yield put(getApiManagementDetailsRequest([]));
    } else {
      yield put(addApiManagementDetailsSuccess());
    }
    ApiResponseMessage(res.message, res.status ? SUCCESS : DANGER);
  } catch (error) {
    handleApiCallException(error.message);
  }
}

function* watchApiManagementDataRequest() {
  yield takeLatest(GET_API_MANAGEMENT_DETAILS_REQUEST, getApiData);
  yield takeLatest(UPDATE_API_MANGEMENT_DETAILS_REQUEST, UpdateApiData);
  yield takeLatest(ADD_API_MANGEMENT_DETAILS_REQUEST, AddApiData);
}

function* getApiManagementSaga() {
  yield all([fork(watchApiManagementDataRequest)]);
}
export default getApiManagementSaga;
