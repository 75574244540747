import { useRef } from "react";
import { addPlansInitialState } from "./CommonFunction";
import SimpleReactValidator from "simple-react-validator";
import { useState } from "react";
import { ApiResponseMessage } from "../../../helper/tosterHelpers";

import { DANGER } from "../../../redux/constant/commonConstants";
import { useEffect } from "react";
import { Field, Form, Formik } from "formik"; // Updated import statement
import { __t } from "../../../i18n/translator";
import { connect } from "react-redux";
import {
  addPlansRequest,
  editPlansRequest,
} from "../../../redux/subscription/plans/action";
import { IntComponent } from "../../../helper/NumberFormat";

function AddPlans(props) {
  const params = props?.data || {};
  const validator = useRef(new SimpleReactValidator());
  const [PlansData, setPlansData] = useState(addPlansInitialState(props?.data));
  const [FormRef, setFormRef] = useState(false);
  useEffect(() => {
    setPlansData(addPlansInitialState(props.data));
    setFormRef(true);
  }, [props.data]);
  const handleSubmit = async (values) => {
    if (validator.current.allValid()) {
      if (params.id) {
        if (PlansData !== values) {
          props.updatePlan({
            ...values,
            id: params.id,
          });
          props.setIsAddPlan(false);
          props.setIsEdit(null);
        } else {
          ApiResponseMessage(
            __t("validations.common.same_Data_warning"),
            DANGER
          );
        }
      } else {
        props.addPlan(values);
      }
      setFormSubmitted(true);
      props.setIsAddPlan(false);
    } else {
      validator.current.showMessages();
    }
  };
  const [formSubmitted, setFormSubmitted] = useState(false);
  useEffect(() => {
    if (formSubmitted) {
      props.setIsAddPlan(false);
      props.setIsEdit(null);
    }
  }, [formSubmitted]);
  const operationType = !params.id ? "Submit" : "Update";

  useEffect(() => {
    setFormRef(false);
  }, [FormRef]);
  return (
    <>
      {!FormRef && (
        <Formik initialValues={PlansData} onSubmit={handleSubmit}>
          {({ isSubmitting, values }) => (
            <Form className="subscription-form row">
              <div className="form-group mb-2 col-12">
                <label htmlFor="planName">
                  Plan Name <span className="text-danger required">*</span>
                </label>
                <Field
                  type="text"
                  id="planName"
                  className="form-control"
                  name="planName"
                  // onInput={(e) => {
                  //   e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, "");
                  // }}
                />
                {validator.current.message(
                  __t("validations.plans.planName"),
                  values.planName,
                  "required",
                  {
                    messages: {
                      required: __t("validations.plans.planName"),
                      // alpha_space: __t("validations.common.allow_only_alpha"),
                    },
                  }
                )}
              </div>
              <div className="form-group mb-2 col-12">
                <label htmlFor="price">
                  Price <span className="text-danger required">*</span>
                </label>
                <Field
                  type="text"
                  id="price"
                  className="form-control"
                  name="price"
                  onInput={IntComponent}
                />
                {validator.current.message(
                  __t("validations.plans.price"),
                  values.price,
                  "required",
                  {
                    messages: {
                      required: __t("validations.plans.price"),
                    },
                  }
                )}
              </div>

              <div className="form-group mb-2 col-4">
                <label htmlFor="totalStory">
                  Story <span className="text-danger required">*</span>
                </label>
                <Field
                  type="text"
                  id="totalStory"
                  className="form-control"
                  name="totalStory"
                  onInput={IntComponent}
                />
                {validator.current.message(
                  __t("validations.plans.totalStory"),
                  values.totalStory,
                  "required",
                  {
                    messages: {
                      required: __t("validations.plans.totalStory"),
                    },
                  }
                )}
              </div>
              <div className="form-group mb-2 col-4">
                <label htmlFor="totalPlace">
                  Place <span className="text-danger required">*</span>
                </label>
                <Field
                  type="text"
                  id="totalPlace"
                  className="form-control"
                  name="totalPlace"
                  onInput={IntComponent}
                />
                {validator.current.message(
                  __t("validations.plans.totalPlace"),
                  values.totalPlace,
                  "required",
                  {
                    messages: {
                      required: __t("validations.plans.totalPlace"),
                    },
                  }
                )}
              </div>
              <div className="form-group mb-2 col-4">
                <label htmlFor="totalEvent">
                  Event <span className="text-danger required">*</span>
                </label>
                <Field
                  type="text"
                  id="totalEvent"
                  className="form-control"
                  name="totalEvent"
                  onInput={IntComponent}
                />
                {validator.current.message(
                  __t("validations.plans.totalEvent"),
                  values.totalEvent,
                  "required",
                  {
                    messages: {
                      required: __t("validations.plans.totalEvent"),
                    },
                  }
                )}
              </div>
              <div className="form-group mb-2 col-4">
                <label htmlFor="totalCharacter">
                  Character
                  <span className="text-danger required">*</span>
                </label>
                <Field
                  type="text"
                  id="totalCharacter"
                  className="form-control"
                  name="totalCharacter"
                  onInput={IntComponent}
                />
                {validator.current.message(
                  __t("validations.plans.totalCharacter"),
                  values.totalCharacter,
                  "required",
                  {
                    messages: {
                      required: __t("validations.plans.totalCharacter"),
                    },
                  }
                )}
              </div>
              <div className="form-group col-4">
                <label htmlFor="totalScene">
                  Scene <span className="text-danger required">*</span>
                </label>
                <Field
                  type="text"
                  id="totalScene"
                  className="form-control"
                  name="totalScene"
                  onInput={IntComponent}
                />
                {validator.current.message(
                  __t("validations.plans.totalScene"),
                  values.totalScene,
                  "required",
                  {
                    messages: {
                      required: __t("validations.plans.totalScene"),
                    },
                  }
                )}
              </div>
              <div className="form-group col-4">
                <label htmlFor="taxPercent">
                  Tax Percent <span className="text-danger required">*</span>
                </label>
                <Field
                  type="text"
                  id="taxPercent"
                  className="form-control"
                  name="taxPercent"
                  onInput={IntComponent}
                />
                {validator.current.message(
                  __t("validations.plans.taxPercent"),
                  values.taxPercent,
                  "required",
                  {
                    messages: {
                      required: __t("validations.plans.taxPercent"),
                    },
                  }
                )}
              </div>
              <div className="btn-box mt-3">
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {operationType}
                </button>
                <button
                  className="btn btn-outline"
                  type="button"
                  onClick={() => {
                    props.setIsAddPlan(false);
                    props.setIsEdit(null);
                  }}
                >
                  cancel
                </button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    createPlan: state.plans.addPlans,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addPlan: (data) => dispatch(addPlansRequest(data)),
    updatePlan: (data) => {
      dispatch(editPlansRequest(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPlans);
