import {
    GET_SUBSCRIPTION_PLAN_REQUEST,
    GET_SUBSCRIPTION_PLAN_SUCCESS,

} from "./type";

const initialState = {
    subscription: null,
};
const SubscriptionReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SUBSCRIPTION_PLAN_REQUEST:
            return {
                ...state,
                subscription: action.payload,
            };
        case GET_SUBSCRIPTION_PLAN_SUCCESS:
            return {
                ...state,
                subscription: action.payload,
            };
        default: return { ...state }
    }
};
export default SubscriptionReducer;
