import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getDateTimeFromDateTimeString,
  getDateTimezone,
} from "../../helper/DataTimeHelpers";
import { getRateListRequest } from "../../redux/rateus/action";
import { Progress } from "reactstrap";
import { HalfStar, StarSvg } from "../../components/Common/CommonSvg";
import TableHeader from "../../components/Table/TableHeader";
import { getFormattedDescription } from "../../utils/readmoreUtils";
import CommonPagination from "../../components/Table/CommonPagination";

function RateList(props) {
  const { totalRecords } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage, setDataPerPage] = useState(10);
  useEffect(() => {
    setCurrentPage(1);
  }, [dataPerPage]);

  useEffect(() => {
    props?.getList({
      limit: dataPerPage,
      page: currentPage,
    });
  }, [currentPage, dataPerPage]);

  const OneStar =
    (props?.reviewRate?.countOneStar &&
      props.reviewRate.countOneStar / props?.reviewRate?.totalRate) * 100;
  const TwoStar =
    (props?.reviewRate?.countTwoStar &&
      props.reviewRate.countTwoStar / props?.reviewRate?.totalRate) * 100;
  const ThreeStar =
    (props?.reviewRate?.countThreeStar &&
      props.reviewRate.countThreeStar / props?.reviewRate?.totalRate) * 100;
  const number = props?.reviewRate?.averageRate && props.reviewRate.averageRate;
  const integerValue = number % 1;
  return (
    <>
      <TableHeader />

      <div className="rate-data">
        <ul className="rate-total-head">
          <li>
            <h5>Total Reviews</h5>
            <span>
              {props?.reviewRate?.totalRate > 0 && props.reviewRate.totalRate}
            </span>
            <p>Growth in reviews on this year</p>
          </li>
          <li>
            <h5>Average Rating</h5>

            <div className="rate-star-head">
              <span>{props?.reviewRate?.averageRate.toFixed(1)}</span>
              <span className="rating-star success">
                {props?.reviewRate &&
                  Array.from(
                    { length: props?.reviewRate?.averageRate },
                    (_, index) => <StarSvg key={index} />
                  )}
                {integerValue > 0.1 && <HalfStar />}
              </span>
            </div>
            <p>Average rating on this year</p>
          </li>
          <li>
            <ul className="rate-list">
              <li>
                <span>
                  <StarSvg />
                  <StarSvg />
                  <StarSvg />
                  {props?.reviewRate?.countThreeStar}
                </span>
                <Progress value={ThreeStar} color="info" />
                <span className="total-count">
                  {/* {!ThreeStar ? "" : ThreeStar.toFixed(1) + "%"} */}
                  {ThreeStar.toFixed(1) + "%"}
                </span>
              </li>
              <li>
                <span>
                  <StarSvg />
                  <StarSvg />
                  {props?.reviewRate?.countTwoStar}
                </span>
                <Progress value={TwoStar} color="warning" />
                <span className="total-count">{TwoStar.toFixed(1) + "%"}</span>
              </li>
              <li>
                <span>
                  <StarSvg />
                  {props?.reviewRate?.countOneStar}
                </span>
                <Progress value={OneStar} color="danger" />
                <span className="total-count">{OneStar.toFixed(1) + "%"}</span>
              </li>
            </ul>
          </li>
        </ul>
        {props?.getData && props.getData.length > 0 ? (
          <ul className="rate-user-list">
            {props.getData.map((data) => {
              const { rating, message } = data; // Extract the rating and message from the data object

              const starRating = Array.from(Array(rating), (_, index) => (
                <StarSvg key={index} />
              ));

              return (
                <li key={data.id}>
                  <div>
                    <div className="rate-user-data">
                      <span className="rate-user-box">
                        {data?.parentDetail?.firstName
                          ?.charAt(0)
                          .toUpperCase() +
                          data?.parentDetail?.lastName?.charAt(0).toUpperCase()}
                      </span>
                      <div className="rate-user-name">
                        <h5>
                          {data?.parentDetail?.firstName +
                            " " +
                            data?.parentDetail?.lastName}
                        </h5>
                        <p>{data?.parentDetail?.email}</p>
                      </div>
                    </div>
                    <div className="rate-user-time">
                      <span>
                        <p>{getDateTimezone(data?.created_date)}</p>
                        <p>
                          {getDateTimeFromDateTimeString(data?.created_date)}
                        </p>
                      </span>
                      <span
                        className={`rating-star ${
                          data.rating === 5
                            ? "primary"
                            : data.rating === 4
                            ? "success"
                            : data.rating === 3
                            ? "info"
                            : data.rating === 2
                            ? "warning"
                            : data.rating === 1
                            ? "danger"
                            : ""
                        }`}
                      >
                        {starRating}
                      </span>
                    </div>
                  </div>
                  <p className="message-box">
                    {getFormattedDescription(message)}
                  </p>
                </li>
              );
            })}
          </ul>
        ) : (
          <h4 className="text-center mt-5 mb-5">No rating data available</h4>
        )}
      </div>
      <div>
        {totalRecords > 0 && (
          <CommonPagination
            dataPerPage={dataPerPage}
            currentPage={currentPage}
            totalData={totalRecords}
            setCurrentPage={setCurrentPage}
            setDataPerPage={setDataPerPage}
          />
        )}
      </div>
    </>
  );
}
const mapStateToProps = (state) => {
  let DataList = null;
  let totalRecords;
  let reviewRate;
  if (
    state?.rateList?.getRateList?.rateUsList &&
    state.rateList.getRateList.rateUsList.length > 0
  ) {
    reviewRate = state.rateList.getRateList;
    DataList = state.rateList.getRateList.rateUsList;
    totalRecords = state.rateList.getRateList.totalRecords;
  }
  return {
    reviewRate: reviewRate,
    getData: DataList,
    totalRecords: totalRecords,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getList: (data) => {
      dispatch(getRateListRequest(data));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(RateList);
