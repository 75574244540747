import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import {
  ADD_PLACE_REQUEST,
  DELETE_PLACE_REQUEST,
  EDIT_PLACE_REQUEST,
  GET_PLACE_REQUEST,
} from "./type";
import {
  getPlaceDataSuccess,
  addPlaceDataSuccess,
  deletePlaceDataSuccess,
  getPlaceDataRequest,
  editPlaceDataSuccess,
} from "./action";
import { DANGER, SUCCESS } from "../../constant/commonConstants";
import { invokeApi } from "../../../enverment/axiosHelper";
import { ApiResponseMessage } from "../../../helper/tosterHelpers";
import { handleApiCallException } from "../../shared/action";
function* getPlaceDataSaga({ payload: data }) {
  try {
    const res = yield call(invokeApi, "post", "/masterPlaceList", data);
    if (res.status) {
      yield put(getPlaceDataSuccess(res.data));
    } else {
      yield put(getPlaceDataSuccess());
    }
    // ApiResponseMessage(res.message, res.status ? SUCCESS : DANGER);
  } catch (error) {
    yield put(handleApiCallException(error));
  }
}

function* addPlaceDataSaga({ payload: data }) {
  try {
    const response = yield call(
      invokeApi,
      "post",
      "/addMasterPlace",
      data.formData
    );
    if (response.status) {
      yield put(addPlaceDataSuccess(response.data));
      yield put(
        getPlaceDataRequest({
          page: data.currentPage,
          limit: data.dataPerPage,
        })
      );
    } else {
      yield put(addPlaceDataSuccess());
    }
    ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER);
  } catch (error) {
    yield put(handleApiCallException(error));
  }
}

function* removePlaceData({ payload: data }) {
  try {
    const response = yield call(invokeApi, "post", "/deleteMasterPlace", data);
    if (response.status) {
      yield put(deletePlaceDataSuccess(response.data));
      yield put(
        getPlaceDataRequest({
          page: data.currentPage,
          limit: data.dataPerPage,
        })
      );
    } else {
      yield put(deletePlaceDataSuccess());
    }
    ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER);
  } catch (error) {
    yield put(handleApiCallException(error));
  }
}
function* updatePlaceData({ payload: data }) {
  const formData = data.formData;
  const convertedData = {};
  for (const [key, value] of formData.entries()) {
    convertedData[key] = value;
  }
  try {
    const response = yield call(
      invokeApi,
      "put",
      `/updateMasterPlace/${convertedData.id}`,
      data.formData
    );

    if (response.status) {
      yield put(editPlaceDataSuccess(response.data));
      yield put(
        getPlaceDataRequest({
          page: data.currentPage,
          limit: data.dataPerPage,
        })
      );
    } else {
      yield put(editPlaceDataSuccess(response.data));
    }
    ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER);
  } catch (error) {
    yield put(handleApiCallException(error));
  }
}

function* watchPlaceDataRequest() {
  yield takeLatest(GET_PLACE_REQUEST, getPlaceDataSaga);
  yield takeEvery(ADD_PLACE_REQUEST, addPlaceDataSaga);
  yield takeEvery(EDIT_PLACE_REQUEST, updatePlaceData);
  yield takeEvery(DELETE_PLACE_REQUEST, removePlaceData);
}
function* PlaceSaga() {
  yield all([fork(watchPlaceDataRequest)]);
}

export default PlaceSaga;
