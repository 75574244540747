import { ADD_PLACE_REQUEST, ADD_PLACE_SUCCESS, DELETE_PLACE_REQUEST, DELETE_PLACE_SUCCESS, EDIT_PLACE_REQUEST, EDIT_PLACE_SUCCESS, GET_PLACE_FAILURE, GET_PLACE_REQUEST, GET_PLACE_SUCCESS } from "./type";


export const getPlaceDataRequest = (Data) => {
    return {
        type: GET_PLACE_REQUEST,
        payload: Data

    }
};
export const getPlaceDataSuccess = (Data) => {
    return {
        type: GET_PLACE_SUCCESS,
        payload: Data
    }
};

export const getPlaceDataFailure = (error) => ({
    type: GET_PLACE_FAILURE,
    payload: error,
});
export const addPlaceDataRequest = (data) => ({
    type: ADD_PLACE_REQUEST,
    payload: data
}
);
export const addPlaceDataSuccess = (data) => ({
    type: ADD_PLACE_SUCCESS,
    payload: data

});
export const deletePlaceDataRequest = (placeId) => (
    {
        type: DELETE_PLACE_REQUEST,
        payload: placeId
    }
);
export const deletePlaceDataSuccess = (placeId) => ({
    type: DELETE_PLACE_SUCCESS,
    payload: placeId


});
export const editPlaceDataRequest = (data) => (
    {
        type: EDIT_PLACE_REQUEST,
        payload: data
    }
);
export const editPlaceDataSuccess = (updateData) => ({
    type: EDIT_PLACE_SUCCESS,
    payload: updateData
});