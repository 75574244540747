import {
    ADD_PLACE_REQUEST,
    ADD_PLACE_SUCCESS,
    DELETE_PLACE_REQUEST,
    DELETE_PLACE_SUCCESS,
    EDIT_PLACE_REQUEST,
    EDIT_PLACE_SUCCESS,
    GET_PLACE_REQUEST,
    GET_PLACE_SUCCESS,
} from "./type";

const initialState = {
    getPlace: [],
    createPlace: null,
    updatePlace: null,
    removePlace: null,
};

const placeReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PLACE_REQUEST:
            return {
                ...state,
                getPlace: action.payload,
            };
        case GET_PLACE_SUCCESS:
            return {
                ...state,
                getPlace: action.payload,
            };
        case ADD_PLACE_REQUEST:
            return {
                ...state,
                createPlace: action.payload,
            };
        case ADD_PLACE_SUCCESS:
            return {
                ...state,
                createPlace: action.payload,
            };
        case DELETE_PLACE_REQUEST:
            return {
                ...state,
                removePlace: action.payload,
            };
        case DELETE_PLACE_SUCCESS:
            return {
                ...state,
                removePlace: action.payload,
            };
        case EDIT_PLACE_REQUEST:
            return {
                ...state,
                place: action.payload,
            };
        case EDIT_PLACE_SUCCESS:
            return {
                ...state,
                place: action.payload,
            };
        default:
            return { ...state };
    }
};

export default placeReducer;
