import {
    ADD_EVENT_REQUEST,
    ADD_EVENT_SUCCESS,
    DELETE_EVENT_REQUEST,
    DELETE_EVENT_SUCCESS,
    EDIT_EVENT_REQUEST,
    EDIT_EVENT_SUCCESS,
    GET_EVENT_FAILURE,
    GET_EVENT_REQUEST,
    GET_EVENT_SUCCESS,
} from "./type";

const initialState = {
    getEvent: null,
    createEvent: null,
    updateEvent: null,
    removeEvent: null,
    error: null,
    loading: false,
    Event: null
};

const eventReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_EVENT_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case GET_EVENT_SUCCESS:
            return {
                ...state,
                getEvent: action.payload.data,
                loading: false,
            };
        case GET_EVENT_FAILURE:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case ADD_EVENT_REQUEST:
            return {
                ...state,
                createEvent: action.payload,
                error: null,
                loading: true
            };
        case ADD_EVENT_SUCCESS:
            return {
                ...state,
                createEvent: action.payload,
                loading: false,
            };
        case DELETE_EVENT_REQUEST:
            return {
                ...state,
                removeEvent: action.payload,
                error: null,
                loading: true
            };
        case DELETE_EVENT_SUCCESS:
            return {
                ...state,
                removeEvent: action.payload,
                loading: false,
            };
        case EDIT_EVENT_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        case EDIT_EVENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                Event: action.payload,
            };
        default:
            return { ...state };
    }
};

export default eventReducer;
