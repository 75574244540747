import {
  GET_PAYMENT_HISTORY_DETAILS_REQUEST,
  GET_PAYMENT_HISTORY_DETAILS_SUCCESS,
} from "./type";

const initialState = {
  data: null,
  remove: null,
  loading: false,
  child: null,
};

const paymentHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PAYMENT_HISTORY_DETAILS_REQUEST:
      return {
        ...state,
        data: action.payload,
      };

    case GET_PAYMENT_HISTORY_DETAILS_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };

    default:
      return { ...state };
  }
};

export default paymentHistoryReducer;
