import React from "react";
import Logo from "../../assets/images/payment-sucessful.png";

function ThankYou() {
  return (
    <>
      <div div className="container-fluid thankyou-main">
        <div className="subscription-main-plans">
          <div class="confetti">
            <div class="confetti-piece"></div>
            <div class="confetti-piece"></div>
            <div class="confetti-piece"></div>
            <div class="confetti-piece"></div>
            <div class="confetti-piece"></div>
            <div class="confetti-piece"></div>
            <div class="confetti-piece"></div>
            <div class="confetti-piece"></div>
            <div class="confetti-piece"></div>
            <div class="confetti-piece"></div>
            <div class="confetti-piece"></div>
            <div class="confetti-piece"></div>
            <div class="confetti-piece"></div>
          </div>
          <div className="thankyou-logo" title="Kidlit Katalyst">
            <img alt="Kidlit Katalyst" src={Logo} />
          </div>
          <div className="thankyou-title">
            <h1>Thank You!</h1>
            <h3>Payment Done Successfully</h3>
            <p>
            Your payment is confirmed, transaction successful. We're deeply grateful for choosing us.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default ThankYou;
