import {
    ADD_MASTER_REQUEST,
    ADD_MASTER_SUCCESS,
    DELETE_MASTER_REQUEST,
    DELETE_MASTER_SUCCESS,
    EDIT_MASTER_REQUEST,
    EDIT_MASTER_SUCCESS,
    GET_MASTER_FAILURE,
    GET_MASTER_REQUEST,
    GET_MASTER_SUCCESS,
} from "./type";

const initialState = {
    data: null,
    error: null,
    loading: false,
    Master: null,
    createMaster: null,
};

const masterReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_MASTER_REQUEST:
            return {
                ...state,
            };
        case GET_MASTER_SUCCESS:
            return {
                ...state,
                data: action.payload,
            };
        case GET_MASTER_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case ADD_MASTER_REQUEST:
            return {
                ...state,
                createMaster: action.payload,
            };
        case ADD_MASTER_SUCCESS:
            return {
                ...state,
                createMaster: action.payload,
            };
        case DELETE_MASTER_REQUEST:
            return {
                ...state,
                removeMaster: action.payload,
            };
        case DELETE_MASTER_SUCCESS:
            return {
                ...state,
                removeMaster: action.payload,
            };
        case EDIT_MASTER_REQUEST:
            return {
                ...state,
            };
        case EDIT_MASTER_SUCCESS:
            return {
                ...state,
                Master: action.payload,
            };
        default:
            return { ...state };
    }
};

export default masterReducer;
