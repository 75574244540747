import React from "react";
import Logo from "../../assets/images/payment failed.png";

const FailPayment = () => {
  return (
    <div div className="container-fluid thankyou-main">
      <div className="subscription-main-plans">
        <div className="thankyou-logo mb-3" title="Kidlit Katalyst">
          <img alt="Kidlit Katalyst" src={Logo} />
        </div>
        <div className="thankyou-title failed_pay">
          <h1>oops! Payment Failed</h1>
          <p>
            We regret to inform you that your payment has not been successful. Please check your payment details and try again.
          </p>
        </div>
      </div>
    </div>
  );
};

export default FailPayment;
