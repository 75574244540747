import { combineReducers } from "redux";
import loginReducer from "./auth/login/reducer";
import alertReducer from "./alert/reducer";
import forgetReducer from "./auth/forgot/reducer";
import masterReducer from "./master/character/reducer";
import placeReducer from "./master/place/reducer";
import eventReducer from "./master/event/reducer";
import sceneReducer from "./master/scene/reducer";
import spiceUpReducer from "./master/spiceup/reducer";
import languageReducer from "./master/language/reducer";
import voiceReducer from "./master/voice/reducer";
import themeReducer from "./master/theme/reducer";
import userReducer from "./user/usermanagement/reducer";
import emailReducer from "./email/reducer";
import storyReducer from "./story/reducer";
import { CLEAR_STORE } from "./clearstore/type";
import sharedReducer from "./shared/reducer";
import couponsReducer from "./redeemcoupons/reducer";
import supportReducer from "./support/reducer";
import reportStoryReducer from "./reportstory/reducer";
import itemsReducer from "./subscription/items/reducer";
import plansReducer from "./subscription/plans/reducer";
import rateReducer from "./rateus/reducer";
import userLogsReducer from "./user/userStats/reducer";
import SubscriptionReducer from "./subscription/subscriptionplans/reducer";
import landingPageReducer from "./landingpage/reducer";
import userLogReducer from "./logs/logs/reducer";
import apiManagementReducer from "./apimanagement/reducer";
import paymentHistoryReducer from "./paymentHistory/reducer";
import dashBoardReducer from "./dashBoard/reducer";
import WebSubscriptionReducer from "./subscription/webSubsription/reducer";

const appReducer = combineReducers({
  login: loginReducer,
  dashboard: dashBoardReducer,
  alert: alertReducer,
  reportStory: reportStoryReducer,
  forgot: forgetReducer,
  master: masterReducer,
  place: placeReducer,
  event: eventReducer,
  scene: sceneReducer,
  sharedReducer: sharedReducer,
  spiceup: spiceUpReducer,
  language: languageReducer,
  voice: voiceReducer,
  theme: themeReducer,
  user: userReducer,
  email: emailReducer,
  story: storyReducer,
  userLogs: userLogsReducer,
  couponsReducer: couponsReducer,
  support: supportReducer,
  itemPlans: itemsReducer,
  plans: plansReducer,
  landingPage: landingPageReducer,
  rateList: rateReducer,
  Subscriptionkey: SubscriptionReducer,
  logs: userLogReducer,
  apiManagement: apiManagementReducer,
  paymentHistory: paymentHistoryReducer,
  WebSubscription: WebSubscriptionReducer
});

const rootReducer = (state, action) => {
  if (action.type === CLEAR_STORE) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
