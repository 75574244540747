import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
} from "reactstrap";
import {
  emailDetailsRequest,
  emailRequest,
  emailTemplateRequest,
  placeholderRequest,
  roleRequest,
  updateEmailTemplateRequest,
  updateEmailTemplateStatusRequest,
} from "../../redux/email/action";
import { CopySvg } from "../../components/Common/CommonSvg";
import { Field, Formik } from "formik";
import ReactSwitch from "./ReactSwitch";
import { ApiResponseMessage } from "../../helper/tosterHelpers";
import JoditEditor from "jodit-react";
import { removeTags } from "../../utils/generalUtils";
import { config, initialTemplateState } from "./CommonFunction";
import { __t } from "../../i18n/translator";
import SimpleReactValidator from "simple-react-validator";
import { DANGER, SUCCESS } from "../../redux/constant/commonConstants";
function EmailTemplate(props) {
  const [status, setStatus] = useState("");
  const [roleId, setRoleId] = useState(0);
  const [templateId, setTemplateId] = useState(1);
  const [activeTab, setActiveTab] = useState(0);
  const editor = useRef(null);
  const validator = useRef(new SimpleReactValidator());
  const [initialTemplateValues, setInitialTemplateValues] = useState(
    initialTemplateState()
  );
  const [sideBarPanelTab, setsideBarPanelTab] = useState(0);
  const sideBarToggle = (tab) => {
    setsideBarPanelTab(tab);
  };
  useEffect(() => {
    props?.getDataList();
    props?.getPlaceholders();
    props.getRole();
  }, []);
  const toggle = (tab) => {
    setActiveTab(tab);
  };

  // const handleCopyClick = async (values) => {
  //     try {
  //         await navigator.clipboard.writeText(values);
  //         ApiResponseMessage("Text copied successfully.");
  //     } catch (error) {
  //         ApiResponseMessage("Error copying text: " + error.message);
  //     }
  // };

  const handleCopyClick = (textToCopy) => {
    const textarea = document.createElement("textarea");
    textarea.value = textToCopy;
    document.body.appendChild(textarea);
    textarea.select();
    try {
      ApiResponseMessage("Text copied successfully.", SUCCESS);
      document.execCommand("copy");
    } catch (err) {
      ApiResponseMessage("Error copying text: " + err.message, DANGER);
    } finally {
      // Remove the textarea from the DOM
      document.body.removeChild(textarea);
    }
  };

  useEffect(() => {
    if (props.TemplateList?.length > 0) {
      if (props.TemplateList[0].id) {
        setTemplateId(props?.TemplateList[0]?.id);
        setRoleId(roleId);
      }
    }
  }, [props.TemplateList]);

  useEffect(() => {
    setStatus(props.EmailDetailsData?.emailTemplateDetails?.isActive);
    const data = {
      isActive: props.EmailDetailsData?.emailTemplateDetails?.isActive,
      subject: props.EmailDetailsData?.emailTemplateDetails?.subject,
      templateDescription:
        props.EmailDetailsData?.emailTemplateDetails?.emailBody,
    };
    setInitialTemplateValues(data);
  }, [props.EmailDetailsData?.emailTemplateDetails]);

  const getTemplateDetailsList = () => {
    let formatValue = {
      templateId: templateId,
      roleId: roleId,
    };
    props.getEmailDetails(formatValue);
  };

  useEffect(() => {
    getTemplateDetailsList();
  }, [templateId, roleId]);
  const formSubmit = async (values) => {
    if (validator.current.allValid()) {
      if (initialTemplateValues !== values) {
        let Data = {
          subject: values.subject,
          emailBody: values.templateDescription,
          isActive: status,
          roleId: roleId,
          templateId: templateId,
        };
        props.updateEmailTemplate(Data);
      } else {
        ApiResponseMessage(__t("validations.common.same_Data_warning"), DANGER);
      }
    } else {
      validator.current.showMessages();
    }
  };
  return (
    <>
      <Row className="m-0 template-box">
        <Col sm="3" className="default_notifications">
          <Card>
            <CardHeader>
              <h4 className="card-title">Default Notifications</h4>
            </CardHeader>
            <CardBody>
              <ul className="small-scroll default_notify_title">
                {props.TemplateList?.map((d, index) => {
                  return (
                    <li key={index}>
                      <a
                        color="primary"
                        className={`btn ${
                          sideBarPanelTab === index ? "active" : ""
                        }`}
                        onClick={() => {
                          sideBarToggle(index);
                          setTemplateId(d.id);
                        }}
                      >
                        {d.name}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </CardBody>
          </Card>
        </Col>
        <Formik
          enableReinitialize={true}
          initialValues={initialTemplateValues}
          onSubmit={formSubmit}
        >
          {({ values, setFieldValue, handleSubmit }) => (
            <>
              <Col md="6" className="edit_template">
                <Form>
                  <Nav tabs>
                    {props.RoleList?.map((x, index) => (
                      <NavItem key={index}>
                        <NavLink
                          className={activeTab === index ? "show" : ""}
                          to="#"
                          onClick={() => {
                            toggle(index);
                          }}
                        >
                          {"To " + x.roleName}
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>
                  <Card>
                    <CardBody className="pt-0">
                      <FormGroup className="col-12 mt-3 mb-3">
                        <ReactSwitch
                          status={status}
                          checkSwitch={(e) => {
                            setStatus(e);
                            props.templateStatus({
                              isActive: e,
                              roleId: roleId,
                              templateId: templateId,
                            });
                          }}
                        />
                      </FormGroup>
                      <div className="no-floating-label">
                        <FormGroup className="col-12 mt-2">
                          <Label
                            className="form-label"
                            for="exampleEmailSubject"
                          >
                            Email Subject
                          </Label>
                          <Field
                            className="form-control"
                            type="text"
                            name="subject"
                            id="exampleEmailSubject"
                            placeholder="Email Subject"
                            value={values.subject || ""}
                          />

                          {validator.current.message(
                            __t(
                              "validations.template.template_email_subject_required"
                            ),
                            values.subject,
                            "required",
                            {
                              messages: {
                                required: __t(
                                  "validations.template.template_email_subject_required"
                                ),
                              },
                            }
                          )}
                        </FormGroup>
                      </div>

                      <FormGroup className="mb-1">
                        <JoditEditor
                          ref={editor}
                          config={config}
                          rows="2"
                          name="templateDescription "
                          value={values?.templateDescription}
                          onBlur={(newContent) => {
                            setFieldValue("templateDescription", newContent);
                          }}
                        />

                        {validator.current.message(
                          __t("validations.template.template_content_required"),
                          removeTags(values.templateDescription),
                          "required",
                          {
                            messages: {
                              required: __t(
                                "validations.template.template_content_required"
                              ),
                            },
                          }
                        )}
                      </FormGroup>
                      <span className="d-block">
                        Allowed HTML tags &lt;div&gt;, &lt;label&gt;,
                        &lt;button&gt;, &lt;span&gt;, &lt;p&gt;, &lt;ul&gt;,
                        &lt;li&gt;, &lt;tr&gt;, &lt;td&gt;, &lt;a&gt;,
                        &lt;br&gt;, &lt;b&gt;, &lt;h1&gt;, &lt;h2&gt;,
                        &lt;hr&gt;
                      </span>
                      <Button
                        type="submit"
                        color="primary"
                        block
                        className="w-auto waves-effect waves-light float-end"
                        onClick={handleSubmit}
                      >
                        {"Submit"}
                      </Button>
                    </CardBody>
                  </Card>
                </Form>
              </Col>
            </>
          )}
        </Formik>
        <Col sm="3" className="default_notifications">
          <Card>
            <div className="card-header">
              <h4 className="card-title">Insert Email Placeholders</h4>
            </div>
            <CardBody className="insert_placeholder">
              <ul className="small-scroll">
                {props.CustomEmailFieldList?.map((d, index) => {
                  return (
                    <li key={index}>
                      <span>{d.customName}</span>
                      <Button
                        className="my-tooltip"
                        data-tooltip-content={"Copy"}
                        color="primary"
                        title="Copy"
                        onClick={() => {
                          handleCopyClick(d.customName);
                        }}
                      >
                        <CopySvg />
                      </Button>
                    </li>
                  );
                })}
              </ul>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}
const mapStateToProps = (state) => {
  let emailData = state?.email?.emailData?.list;
  let placeholder = state?.email?.placeholderData?.list;
  return {
    TemplateList: emailData,
    CustomEmailFieldList: placeholder,
    RoleList: state?.email?.roleData?.list,
    EmailDetailsData: state.email?.emailDetailsData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDataList: () => dispatch(emailRequest()),
    getPlaceholders: () => dispatch(placeholderRequest()),
    getRole: () => dispatch(roleRequest()),
    getEmailDetails: (data) => {
      dispatch(emailDetailsRequest(data));
    },
    templateDetailsListById: (data) => {
      dispatch(emailTemplateRequest(data));
    },
    updateEmailTemplate: (creds) => {
      dispatch(updateEmailTemplateRequest(creds));
    },
    templateStatus: (creds) => {
      dispatch(updateEmailTemplateStatusRequest(creds));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EmailTemplate);
