import React from 'react'
import { LeftArrowSvg } from '../Common/CommonSvg'
import { Link } from 'react-router-dom'

export default function CommonBackButton() {
    return (
        <>
            <Link className="back-btn btn" title="Back" to="/home">
                <span>
                    <LeftArrowSvg />
                </span>
                Back
            </Link>
        </>
    )
}
