import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { invokeApi } from "../../../enverment/axiosHelper";
import { ApiResponseMessage } from "../../../helper/tosterHelpers";
import {
  forgetPasswordFailure,
  forgetPasswordSuccess,
  otpFailure,
  passwordSuccess,
} from "./action";
import {
  FORGET_PASSWORD_REQUEST,
  NEW_PASSWORD_REQUEST,
  OTP_REQUEST,
} from "./type";
import { DANGER, SUCCESS } from "../../constant/commonConstants";
import { setForgotUserData } from "../../../utils/authUtils";

function* Forgot(action) {
  let res;
  try {
    res = yield call(invokeApi, "post", "/forgotPassword", action.payload);
    if (res.status) {
      setForgotUserData(res);
      yield put(forgetPasswordSuccess(res));
      ApiResponseMessage(res.message, res.status ? SUCCESS : DANGER);
    } else {
      yield put(forgetPasswordSuccess());
      ApiResponseMessage(res.message, DANGER);
    }
  } catch (error) {
    yield put(forgetPasswordFailure(error.message));
  }
}

function* otpVerify(action) {
  let res;
  try {
    res = yield call(invokeApi, "post", "/verifyOtp", action.payload);
    if (res.status) {
      yield put(passwordSuccess(res));
      ApiResponseMessage(res.message, res.status ? SUCCESS : DANGER);
    } else {
      ApiResponseMessage(res.message, DANGER);
      yield put(otpFailure(true));
    }
  } catch (error) {
    ApiResponseMessage(error.message, DANGER);
  }
}
function* activation(action) {
  let res;
  try {
    res = yield call(invokeApi, "post", "/resetPassword", action.payload);
    if (res.status) {
      yield put(passwordSuccess(res));
      ApiResponseMessage(res.message, res.status ? SUCCESS : DANGER);
    } else {
      ApiResponseMessage(res.message, DANGER);
    }
  } catch {}
}

function* watchforgotSaga() {
  yield takeLatest(FORGET_PASSWORD_REQUEST, Forgot);
  yield takeLatest(NEW_PASSWORD_REQUEST, activation);
  yield takeLatest(OTP_REQUEST, otpVerify);
}
function* ForgotSaga() {
  yield all([fork(watchforgotSaga)]);
}
export default ForgotSaga;
