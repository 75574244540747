import { GET_SUPPORT_REQUEST, GET_SUPPORT_SUCCESS } from "./type";

const initialState = {
    getSupport: null,
};
const supportReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SUPPORT_REQUEST:
            return {
                ...state,
                getSupport: action.payload,
            };
        case GET_SUPPORT_SUCCESS:
            return {
                ...state,
                getSupport: action.payload,
            };
        default:
            return { ...state };
    }
};
export default supportReducer