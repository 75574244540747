export const REDEEM_COUPONS_REQUEST = "REDEEM_COUPONS_REQUEST";
export const REDEEM_COUPONS_SUCCESS = "REDEEM_COUPONS_SUCCESS";
export const ADD_COUPONS_REQUEST = "ADD_COUPONS_REQUEST";
export const ADD_COUPONS_SUCCESS = "ADD_COUPONS_SUCCESS";
export const UPDATE_COUPONS_REQUEST = "UPDATE_COUPONS_REQUEST";
export const UPDATE_COUPONS_SUCCESS = "UPDATE_COUPONS_SUCCESS";
export const REMOVE_COUPONS_REQUEST = "REMOVE_COUPONS_REQUEST";
export const REMOVE_COUPONS_SUCCESS = "REMOVE_COUPONS_SUCCESS";
export const DELETE_COUPONS_REQUEST = "DELETE_COUPONS_REQUEST";
export const DELETE_COUPONS_SUCCESS = "DELETE_COUPONS_SUCCESS";
