import {
  GET_GROUP_LOG_LIST_REQUEST,
  GET_GROUP_LOG_LIST_SUCCESS,
  GET_USER_LOG_REQUEST,
  GET_USER_LOG_SUCCESS,
} from "./type";

const initialState = {
  data: null,
  remove: null,
  loading: false,
  child: null,
  groupLogList: null,
};

const userLogReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_LOG_REQUEST:
      return {
        ...state,
        data: action.payload,
      };
    case GET_USER_LOG_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };

    case GET_GROUP_LOG_LIST_REQUEST:
      return {
        ...state,
        groupLogList: action.payload,
      };
    case GET_GROUP_LOG_LIST_SUCCESS:
      return {
        ...state,
        groupLogList: action.payload,
      };

    default:
      return { ...state };
  }
};

export default userLogReducer;
