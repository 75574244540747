import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Card } from "reactstrap";
import { EditSvg } from "../../../components/Common/CommonSvg";
import CustomisedModal from "../../../components/Modal/CustomisedModal";
import TableHeader from "../../../components/Table/TableHeader";
import CustomPagination from "../../../components/Table/CommonPagination";
import AddPlace from "./AddPlace";
import {
  deletePlaceDataRequest,
  getPlaceDataRequest,
} from "../../../redux/master/place/action";
import {
  commonConfirmBox,
  commonMessangerBox,
} from "../../../helper/messageHelper";
function Place(props) {
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage, setDataPerPage] = useState(12);
  const [getList, setGetList] = useState([]);
  useEffect(() => {
    props.getList({
      limit: dataPerPage,
      page: currentPage,
    });
  }, [currentPage]);
  useEffect(() => {
    setCurrentPage(1);
    props.getList({
      limit: dataPerPage,
      page: 1,
    });
  }, [dataPerPage]);
  useEffect(() => {
    if (props.getData) {
      setGetList(props.getData);
    }
  }, [props.getData]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const handleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleEditClick = (row) => {
    if (row?.isActive) {
      setIsModalOpen(!isModalOpen);
      setIsEdit(row);
    } else {
      commonMessangerBox("Before updating place you have to activate status");
    }
  };

  const handleDeleteClick = async (row) => {
    const response = await commonConfirmBox(
      row.isActive
        ? "Do you want to inactive this place?"
        : "Do you want to active this place?"
    );
    if (response) {
      props.removeList({
        id: row.id,
        isActive: !row.isActive,
        currentPage,
        dataPerPage,
      });
    }
  };
  return (
    <>
      <TableHeader href={handleModal} buttonName={"Add place"} />
      {getList.length > 0 ? (
        <div className="master-card-list mb-3">
          {getList.length > 0 &&
            getList.map((d) => {
              return (
                <Card key={d.id}>
                  <img
                    src={d.placeImage}
                    className="object-fit-cover"
                    alt="theme"
                  />
                  <div className="card-data">
                    <div className="switch">
                      <input type="checkbox" checked={d.isActive} readOnly />
                      <label
                        className="switch-check"
                        onClick={() => {
                          handleDeleteClick(d);
                        }}
                      >
                        <div className="switch-btn"></div>
                        <div className="active-switch">Active</div>
                        <div className="deactive-switch">Inactive </div>
                      </label>
                    </div>
                    <div className="overlay">
                      <button
                        className="btn btn-link edit-btn"
                        title="Edit"
                        onClick={() => handleEditClick(d)}
                      >
                        <EditSvg />
                      </button>
                    </div>
                  </div>
                  <h5>{d.placeName}</h5>
                </Card>
              );
            })}
        </div>
      ) : (
        <h4 className="text-center mt-5 mb-5">No Data Found</h4>
      )}
      <div>
        <CustomPagination
          dataPerPage={dataPerPage}
          currentPage={currentPage}
          totalData={props.totalRecords}
          setCurrentPage={setCurrentPage}
          setDataPerPage={setDataPerPage}
        />
      </div>
      {isModalOpen && (
        <CustomisedModal
          isModalOpen={isModalOpen}
          modalName={!isEdit ? "Add Place" : "Edit Place"}
          onModalDismiss={() => {
            handleModal();
            setIsEdit(null);
          }}
        >
          <AddPlace
            onModalDismiss={() => {
              handleModal();
              setIsEdit(null);
            }}
            dataPerPage={dataPerPage}
            currentPage={currentPage}
            data={isEdit}
          />
        </CustomisedModal>
      )}
    </>
  );
}
const mapStateToProps = (state) => {
  let DataList;
  let totalRecords = 0;
  if (
    state.place.getPlace !== null &&
    state?.place?.getPlace?.placelist &&
    state.place.getPlace.placelist.length > 0
  ) {
    DataList = state.place.getPlace.placelist;
    totalRecords = state.place.getPlace.totalRecords;
  }

  return {
    getData: DataList,
    totalRecords: totalRecords,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getList: (data) => {
      dispatch(getPlaceDataRequest(data));
    },
    removeList: (data) => {
      dispatch(deletePlaceDataRequest(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Place);
