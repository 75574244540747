import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Field, Form, Formik } from "formik";
import SimpleReactValidator from "simple-react-validator";
import { __t } from "../../i18n/translator";
import {
  addRedeemCouponsRequest,
  updateRedeemCouponsRequest,
} from "../../redux/redeemcoupons/action";
import { addCouponInitialState } from "./CommonFunction";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ApiResponseMessage } from "../../helper/tosterHelpers";
import { DANGER } from "../../redux/constant/commonConstants";
import { IntComponent } from "../../helper/NumberFormat";

function AddCoupon(props) {
  const params = props?.data || {};
  const validator = useRef(new SimpleReactValidator());
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [couponData] = useState(addCouponInitialState(props?.data));
  const handleSubmit = async (values) => {
    // if (
    //   validator.current.allValid() &&
    //   !(values?.minPurchase === values?.discountAmount) &&
    //   values?.minPurchase >= values?.discountAmount
    // ) {
    if (validator.current.allValid()) {
      if (params.id) {
        if (couponData !== values) {
          props.updateCoupon({
            ...values,
            id: props.data.id,
          });
        } else {
          ApiResponseMessage(
            __t("validations.common.same_Data_warning"),
            DANGER
          );
        }
        props.onModalDismiss(false);
      } else {
        props.addCoupon(values);
      }
      setFormSubmitted(true);
    } else {
      validator.current.showMessages();
    }
  };

  const handleInputChange = (e) => {
    e.target.value = e.target?.value?.replace(/[^0-9]/g, "")?.slice(0, 4);
  };

  useEffect(() => {
    if (formSubmitted && props.createCoupon != null) {
      props.onModalDismiss(false);
    }
  }, [formSubmitted, props.createCoupon]);

  const operationType = !params.id ? "Submit" : "Update";
  return (
    <>
      <Formik initialValues={couponData} onSubmit={handleSubmit}>
        {({ isSubmitting, values, setFieldValue }) => (
          <Form className="row">
            <div className="form-group mb-2 col-12">
              <label htmlFor="couponCode">
                Coupon Code <span className="text-danger required">*</span>
              </label>
              <Field
                type="text"
                id="couponCode"
                className="form-control text-uppercase"
                placeholder="Coupon Code"
                name="couponCode"
                maxLength={8}
              />
              {validator.current.message(
                __t("validations.coupons.couponCode"),
                values.couponCode,
                "required",
                {
                  messages: {
                    required: __t("validations.coupons.couponCode"),
                  },
                }
              )}
            </div>
            <div className="form-group mb-2 col-12">
              <label htmlFor="discountAmount">
                Discount Amount <span className="text-danger required">*</span>
              </label>
              <Field
                type="number"
                id="discountAmount"
                className="form-control"
                placeholder="Discount Amount"
                name="discountAmount"
                onInput={handleInputChange}
              />
              {validator.current.message(
                __t("validations.coupons.discountAmount"),
                values.discountAmount,
                "required",
                {
                  messages: {
                    required: __t("validations.coupons.discountAmount"),
                  },
                }
              )}
            </div>
            <div className="form-group mb-2 col-6">
              <label htmlFor="validityStartDate">
                Start Date <span className="text-danger required">*</span>
              </label>
              <Field name="validityStartDate">
                {({ field }) => (
                  <ReactDatePicker
                    {...field}
                    minDate={new Date()}
                    selected={values.validityStartDate}
                    className="form-control"
                    placeholderText="Please Select Date"
                    onChange={(date) => {
                      setFieldValue("validityEndDate", "");
                      setFieldValue("validityStartDate", date);
                    }}
                    dateFormat="dd/MM/yyyy"
                  />
                )}
              </Field>
              {validator.current.message(
                __t("validations.coupons.validityStartDate"),
                values.validityStartDate,
                "required",
                {
                  messages: {
                    required: __t("validations.coupons.validityStartDate"),
                  },
                }
              )}
            </div>
            <div className="form-group mb-2 col-6">
              <label htmlFor="validityEndDate">
                End Date <span className="text-danger required">*</span>
              </label>
              {}
              <Field name="validityEndDate">
                {({ field }) => (
                  <ReactDatePicker
                    {...field}
                    placeholderText="Please Select Date"
                    minDate={values.validityStartDate}
                    selected={values.validityEndDate}
                    className="form-control"
                    onChange={(date) => {
                      setFieldValue("validityEndDate", date);
                    }}
                    dateFormat="dd/MM/yyyy"
                  />
                )}
              </Field>
              {validator.current.message(
                __t("validations.coupons.validityEndDate"),
                values.validityEndDate,
                "required",
                {
                  messages: {
                    required: __t("validations.coupons.validityEndDate"),
                  },
                }
              )}
            </div>

            <div className="form-group mb-2 col-6">
              <label htmlFor="minPurchase">
                Min Purchase <span className="text-danger required">*</span>
              </label>
              <Field
                type="text"
                id="minPurchase"
                className="form-control"
                placeholder="Min Purchase"
                name="minPurchase"
                onInput={IntComponent}
              />
              {validator.current.message(
                __t("validations.coupons.minPurchase"),
                values.minPurchase,
                "required",
                {
                  messages: {
                    required: __t("validations.coupons.minPurchase"),
                  },
                }
              )}
            </div>

            {values?.minPurchase !== "" &&
              values?.discountAmount !== "" &&
              ((values?.minPurchase === values?.discountAmount && (
                <small className="text-danger">
                  The & minimum purchase cannot be the same
                </small>
              )) ||
                (values?.minPurchase < values?.discountAmount && (
                  <small className="text-danger">
                    The minimum purchase amount should be greater than discount
                    amount
                  </small>
                )))}

            <div className="btn-box">
              <button
                className="btn btn-primary"
                type="submit"
                disabled={
                  values?.minPurchase != "" &&
                  values?.discountAmount != "" &&
                  (values?.minPurchase === values?.discountAmount ||
                    values?.discountAmount >= values?.minPurchase)
                }
              >
                {operationType}
              </button>
              <button
                className="btn btn-outline"
                type="button"
                onClick={() => {
                  props.onModalDismiss(false);
                }}
              >
                Cancel
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    createCoupon: state.couponsReducer.addCoupons,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addCoupon: (data) => {
      dispatch(addRedeemCouponsRequest(data));
    },
    updateCoupon: (data) => {
      dispatch(updateRedeemCouponsRequest(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCoupon);
