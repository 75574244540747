import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getDateTimeFromDateTimeString } from "../../../helper/DataTimeHelpers";
import CommonTable from "../../../components/Table/CommonTable";
import {
  getGroupLogListRequest,
  getUserLogRequest,
} from "../../../redux/logs/logs/action";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import CommonPagination from "../../../components/Table/CommonPagination";

function ActivityLogList(props) {
  const { getData, totalRecords, dataPerPage, setDataPerPage, currentPage, setCurrentPage, groupListData, groupListDatatotalRecords, setSearchData, searchData } =
    props;

  // const [searchData, setSearchData] = useState();
  const [parentID, setParentID] = useState("");

  const [accordCurrentPage, setAccordCurrentPage] = useState(1);
  const [accordDataPerPage, setAccordDataPerPage] = useState(10);

  const [open, setOpen] = useState("1");
  const toggle = (id, ParId) => {
    if (open === id) {
      setOpen();
      setParentID(ParId);
    } else {
      setOpen(id);
      setParentID(ParId);
    }
  };

  useEffect(() => {
    props.getList({
      limit: accordDataPerPage,
      page: accordCurrentPage,
      search: searchData ? searchData : "",
      id: parentID,
    });
  }, [accordCurrentPage, accordDataPerPage, searchData, parentID]);

  useEffect(() => {
    props.getGroupLogList({
      limit: dataPerPage,
      page: currentPage,
      search: searchData ? searchData : "",
    });

    //reset it
    setAccordCurrentPage(1);
    setAccordDataPerPage(10);
    setOpen(null);
  }, [currentPage, dataPerPage, searchData]);

  useEffect(() => {
    setCurrentPage(1);
  }, [dataPerPage, searchData]);

  const columns = [
    {
      dataField: "Name",
      text: "Name",
      sort: true,
      headerClasses: "date-time-box",
    },
    {
      dataField: "Email",
      text: "Email Address",
      sort: true,
      headerClasses: "date-time-box",
    },
    
    {
      dataField: "activity",
      text: "Activity",
      sort: true,
      headerClasses: "date-time-box",
    },

    {
      dataField: "createdDate",
      text: "Created Date",
      formatter: (cell, row) => (
        <>{getDateTimeFromDateTimeString(row.createdDate)}</>
      ),
      sort: true,
      headerClasses: "date-time-box",
    },
  ];

  return (
    <div>
      {getData?.map((x, index) => {
        return (
          <Accordion
            open={open}
            toggle={() => {
              toggle(index, x?.parentId);
            }}
          >
            <AccordionItem>
              <AccordionHeader
                targetId={index}
                style={{
                  borderBottom: "1px solid #ddd",
                  padding: "0px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>
                  <span
                    style={{
                      fontSize: "1.2em",
                      fontWeight: "bold",
                      marginRight: "10px",
                    }}
                  >
                    {x.Name}
                  </span>
                  <span
                    style={{
                      fontSize: "0.9em",
                      color: "#777",
                      fontStyle: "italic",
                    }}
                  >
                    {getDateTimeFromDateTimeString(x.createdDate)}
                  </span>
                </div>
              </AccordionHeader>
              <AccordionBody accordionId={index}>
                <CommonTable
                  searchBar={false}
                  Header={false}
                  pagination={true}
                  data={groupListData || []}
                  columns={columns}
                  dataPerPage={accordDataPerPage}
                  totalData={groupListDatatotalRecords || []}
                  currentPage={accordCurrentPage}
                  setCurrentPage={setAccordCurrentPage}
                  setDataPerPage={setAccordDataPerPage}
                  // setSearchData={setSearchData}
                  totalRecords={groupListDatatotalRecords || []}
                  // updateData={props.getList}
                />
              </AccordionBody>
            </AccordionItem>
          </Accordion>
        );
      })}

      {getData?.length > 0 ? (
        <CommonPagination
          dataPerPage={dataPerPage}
          totalData={totalRecords}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setDataPerPage={setDataPerPage}
        />
      ) : (
        <h4 className="text-center mt-5 mb-5">No Data Found</h4>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  let DataList;
  let totalRecords = 0;

  let groupListData;
  let groupListDatatotalRecords = 0;

  if (state?.logs?.data && state?.logs?.data?.logEntries?.length > 0) {
    groupListData = state.logs.data?.logEntries;
    groupListDatatotalRecords = state.logs.data.totalRecords;
  }

  if (
    state?.logs?.groupLogList &&
    state?.logs?.groupLogList?.logEntries?.length > 0
  ) {
    DataList = state.logs.groupLogList?.logEntries;
    totalRecords = state.logs.groupLogList.totalRecords;
  }

  return {
    getData: DataList,
    totalRecords: totalRecords,
    groupListData: groupListData,
    groupListDatatotalRecords: groupListDatatotalRecords,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getList: (data) => {
      dispatch(getUserLogRequest(data));
    },

    getGroupLogList: (data) => {
      dispatch(getGroupLogListRequest(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityLogList);
