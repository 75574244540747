// saga.js

import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import {
  ACTIVE_CHILD_REQUEST,
  GET_CHILD_REQUEST,
  GET_USER_REQUEST,
  SELECT_FAMILY_REQUEST,
} from "./type";
import {
  getChildRequest,
  getChildSuccess,
  getUserSuccess,
  removeUserSuccess,
  selectFamilySuccess,
} from "./action";
import { invokeApi } from "../../../enverment/axiosHelper";
import { ApiResponseMessage } from "../../../helper/tosterHelpers";
import { DANGER, SUCCESS } from "../../constant/commonConstants";
import { handleApiCallException } from "../../shared/action";

function* getUserData(action) {
  let res;
  try {
    res = yield call(invokeApi, "post", "/parentList", action.payload);
    if (res.status) {
      yield put(getUserSuccess(res.data));
    }
  } catch (error) {
    handleApiCallException(error.message);
  }
}
function* getChildData(action) {
  let res;
  try {
    res = yield call(invokeApi, "get", `/childDetails/${action.payload}`);
    if (res.status) {
      yield put(getChildSuccess(res.data));
    }
  } catch (error) {
    handleApiCallException(error.message);
  }
}

function* removeUserData(action) {
  try {
    const response = yield call(
      invokeApi,
      "post",
      `/deleteAdminChild`,
      action.payload
    );
    if (response.status) {
      yield put(removeUserSuccess(response.data));
      ApiResponseMessage(response.message, SUCCESS);
      yield put(getChildRequest(action.payload.refreshChilListId));
    } else {
      yield put(getChildRequest([]));
      ApiResponseMessage(response.message, DANGER);
    }
  } catch (error) {
    ApiResponseMessage(error.message, "error");
  }
}

function* selectFamily(action) {
  try {
    const response = yield call(
      invokeApi,
      "post",
      `/selectFamily`,
      action.payload
    );
    if (response.status) {
      yield put(selectFamilySuccess(response));
      // yield put(getUserRequest());

      ApiResponseMessage(response.message, SUCCESS);
    } else {
      yield put(selectFamilySuccess([]));
      ApiResponseMessage(response.message, DANGER);
    }
  } catch (error) {
    handleApiCallException(error.message);
  }
}

function* watchgetUserRequest() {
  yield takeLatest(GET_USER_REQUEST, getUserData);
  yield takeEvery(GET_CHILD_REQUEST, getChildData);
  yield takeEvery(ACTIVE_CHILD_REQUEST, removeUserData);
  yield takeEvery(SELECT_FAMILY_REQUEST, selectFamily);
}

function* getUserSaga() {
  yield all([fork(watchgetUserRequest)]);
}
export default getUserSaga;
