import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import {
  ADD_VOICE_REQUEST,
  DELETE_VOICE_REQUEST,
  EDIT_VOICE_REQUEST,
  GET_VOICE_REQUEST,
} from "./type";
import {
  getVoiceSuccess,
  addVoiceSuccess,
  deleteVoiceSuccess,
  getVoiceRequest,
  editVoiceSuccess,
} from "./action";
import { DANGER, SUCCESS } from "../../constant/commonConstants";
import { invokeApi } from "../../../enverment/axiosHelper";
import { ApiResponseMessage } from "../../../helper/tosterHelpers";
import { handleApiCallException } from "../../shared/action";
function* getVoiceSaga(action) {
  let res = "";
  try {
    res = yield call(invokeApi, "get", "/masterVoiceList", action.payload);
    if (res.status) {
      yield put(getVoiceSuccess(res.data));
    } else {
      yield put(getVoiceSuccess());
    }
    // ApiResponseMessage(res.message, res.status ? SUCCESS : DANGER);
  } catch (error) {
    yield put(handleApiCallException(error));
  }
}

function* addVoiceSaga(action) {
  try {
    const response = yield call(
      invokeApi,
      "post",
      "/addMasterVoice",
      action.payload
    );
    if (response.status) {
      yield put(addVoiceSuccess(response.data));
      yield put(getVoiceRequest({}));
    } else {
      yield put(addVoiceSuccess());
    }
    ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER);
  } catch (error) {
    yield put(handleApiCallException(error));
  }
}

function* removeVoice(action) {
  try {
    const response = yield call(invokeApi, "post", "/deleteMasterVoice", {
      id: action.payload.id,
      isActive: action.payload.isActive,
    });
    if (response.status) {
      yield put(
        deleteVoiceSuccess({
          id: action.payload.id,
          isActive: action.payload.isActive,
        })
      );
      yield put(
        getVoiceRequest({
          page: action.payload.currentPage,
          limit: 10,
        })
      );
    } else {
      yield put(deleteVoiceSuccess());
    }

    ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER);
  } catch (error) {
    yield put(handleApiCallException(error));
  }
}
function* updateVoice(action) {
  const formData = action.payload;
  const convertedData = {};
  for (const [key, value] of formData.entries()) {
    convertedData[key] = value;
  }
  try {
    const response = yield call(
      invokeApi,
      "put",
      `/updateMasterVoice/${convertedData.id}`,
      action.payload
    );
    if (response.status) {
      yield put(editVoiceSuccess(response.data));
      yield put(getVoiceRequest({}));
    } else {
      yield put(editVoiceSuccess());
    }
    ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER);
  } catch (error) {
    yield put(handleApiCallException(error));
  }
}
function* watchVoiceRequest() {
  yield takeLatest(GET_VOICE_REQUEST, getVoiceSaga);
  yield takeEvery(ADD_VOICE_REQUEST, addVoiceSaga);
  yield takeEvery(DELETE_VOICE_REQUEST, removeVoice);
  yield takeEvery(EDIT_VOICE_REQUEST, updateVoice);
}
function* VoiceSaga() {
  yield all([fork(watchVoiceRequest)]);
}

export default VoiceSaga;
