import { GET_WEB_SUBSCRIPTION_PLAN_REQUEST, GET_WEB_SUBSCRIPTION_PLAN_SUCCESS } from "./type";

export const getWebSubscriptionRequest = (data) => {
    return {
        type: GET_WEB_SUBSCRIPTION_PLAN_REQUEST,
        payload: data
    };
};
export const getWebSubscriptionSuccess = (subPlans) => {
    return {
        type: GET_WEB_SUBSCRIPTION_PLAN_SUCCESS,
        payload: subPlans,
    };
};
