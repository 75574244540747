import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Field, Form, Formik } from "formik";
import SimpleReactValidator from "simple-react-validator";
import { __t } from "../../../i18n/translator";
import { addSceneInitialState } from "./CommonFunction";
import {
    addSceneDataRequest,
    editSceneDataRequest,
} from "../../../redux/master/scene/action";
import { ApiResponseMessage } from "../../../helper/tosterHelpers";
import { DANGER } from "../../../redux/constant/commonConstants";
function AddScene(props) {
    const params = props?.data || {};
    const validator = useRef(new SimpleReactValidator());
    const [SceneData] = useState(addSceneInitialState(props?.data));
    const [formSubmitted, setFormSubmitted] = useState(false);
    const handleSubmit = async (values) => {
        if (validator.current.allValid()) {
            if (params.id) {
                if (SceneData !== values) {
                    props.updateScene(values);
                } else {
                    ApiResponseMessage(
                        __t("validations.common.same_Data_warning"),
                        DANGER
                    );
                }
            } else {
                props.addScene(values);
            }
            props.onModalDismiss(false);
            setFormSubmitted(true);
        } else {
            validator.current.showMessages();
        }
    };

    useEffect(() => {
        if (formSubmitted && props.createScene != null) {
            props.onModalDismiss(false);
        }
    }, [props.createScene != null]);

    const operationType = !params.id ? "Submit" : "Update";
    return (
        <>
            <Formik initialValues={SceneData} onSubmit={handleSubmit}>
                {({ isSubmitting, values }) => (
                    <Form>
                        <div className="form-group mb-2">
                            <label htmlFor="sceneName">
                                Scene Name <span className="text-danger required">*</span>
                            </label>
                            <Field
                                type="text"
                                id="sceneName"
                                className="form-control"
                                placeholder="Scene name"
                                name="sceneName"
                            />
                            {validator.current.message(
                                __t("validations.scene.sceneName"),
                                values.sceneName,
                                "required",
                                {
                                    messages: {
                                        required: __t("validations.scene.sceneName"),
                                    },
                                }
                            )}
                        </div>
                        <div className="btn-box">
                            <button
                                className="btn btn-primary"
                                type="submit"
                                disabled={isSubmitting}
                            >
                                {operationType}
                            </button>
                            <button
                                className="btn btn-outline"
                                type="button"
                                onClick={() => {
                                    props.onModalDismiss(false);
                                }}
                            >
                                cancel
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        createScene: state?.scene?.createScene,
        // getSceneData: state?.Scene?.getScene?.data?.Scenelist,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addScene: (data) => dispatch(addSceneDataRequest(data)),
        updateScene: (data) => dispatch(editSceneDataRequest(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddScene);
