// saga.js

import { invokeApi } from "../../enverment/axiosHelper";
import { ApiResponseMessage } from "../../helper/tosterHelpers";
import { handleApiCallException } from "../shared/action";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import {
    getStoryRequest,
    getStorySuccess,
    getViewStorySuccess,
    removeStorySuccess,
} from "./action";
import { DANGER, SUCCESS } from "../constant/commonConstants";
import {
    REMOVE_STORY_REQUEST,
    STORY_REQUEST,
    VIEW_STORY_REQUEST,
} from "./type";

function* fetchStory(action) {
    try {
        const response = yield call(
            invokeApi,
            "post",
            `/adminStoryList`,
            action.payload
        );
        if (response.status) {
            yield put(getStorySuccess(response.data));
        } else {
            ApiResponseMessage(response.message, DANGER);
        }
    } catch (error) {
        yield put(handleApiCallException(error.message));
    }
}

function* viewStory(action) {
    try {
        const response = yield call(
            invokeApi,
            "get",
            `/getstory/${action.payload}`
        );
        if (response.status) {
            yield put(getViewStorySuccess(response.data.storylist));
        } else {
            ApiResponseMessage(response.message, DANGER);
        }
    } catch (error) {
        yield put(handleApiCallException(error.message));
    }
}

function* removeStory(action) {
    try {
        const response = yield call(invokeApi, "post", "/deleteStory", {
            id: action.payload.id,
            isActive: action.payload.isActive,
        });

        if (response.status) {
            yield put(
                removeStorySuccess({
                    id: action.payload.id,
                    isActive: action.payload.isActive,
                })
            );
            ApiResponseMessage(response.message, SUCCESS);
            yield put(
                getStoryRequest({
                    page: action.payload.currentPage,
                    limit: action.payload.dataPerPage,
                })
            );
        } else {
            ApiResponseMessage(response.message, DANGER);
        }
    } catch (error) {
        ApiResponseMessage(error.message, "error");
    }
}
function* watchStoryRequest() {
    yield takeLatest(STORY_REQUEST, fetchStory);
    yield takeLatest(VIEW_STORY_REQUEST, viewStory);
    yield takeLatest(REMOVE_STORY_REQUEST, removeStory);
}

function* storySaga() {
    yield all([fork(watchStoryRequest)]);
}

export default storySaga;
