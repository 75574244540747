import {
  GET_ACTIVE_REQUEST,
  GET_ACTIVE_SUCCESS,
  GET_REPORT_STORT_REQUEST,
  GET_REPORT_STORT_SUCCESS,
  GET_VIEW_STORY_REQUEST,
  GET_VIEW_STORY_SUCCESS,
  CREATE_STORY_REQUEST,
  CREATE_STORY_SUCCESS,
} from "./type";

export const getReportStoryRequest = (data) => {
  return {
    type: GET_REPORT_STORT_REQUEST,
    payload: data,
  };
};

export const getReportStorySuccess = (data) => {
  return {
    type: GET_REPORT_STORT_SUCCESS,
    payload: data,
  };
};
export const getActiveRequest = (data) => {
  return {
    type: GET_ACTIVE_REQUEST,
    payload: data,
  };
};

export const getActiveSuccess = (data) => {
  return {
    type: GET_ACTIVE_SUCCESS,
    payload: data,
  };
};
export const getViewStoryRequest = (data) => {
  return {
    type: GET_VIEW_STORY_REQUEST,
    payload: data,
  };
};

export const getViewStorySuccess = (data) => {
  return {
    type: GET_VIEW_STORY_SUCCESS,
    payload: data,
  };
};

export const creditStoryRequest = (data) => {
  return {
    type: CREATE_STORY_REQUEST,
    payload: data,
  };
};

export const creditStorySuccess = (data) => {
  return {
    type: CREATE_STORY_SUCCESS,
    payload: data,
  };
};
