import {
    ADD_MASTER_REQUEST,
    ADD_MASTER_SUCCESS,
    DELETE_MASTER_REQUEST,
    DELETE_MASTER_SUCCESS,
    EDIT_MASTER_REQUEST,
    EDIT_MASTER_SUCCESS,
    GET_MASTER_FAILURE,
    GET_MASTER_REQUEST,
    GET_MASTER_SUCCESS,
} from "./type";
export const getMasterDataRequest = (Data) => ({
    type: GET_MASTER_REQUEST,
    payload: Data,
});
export const getMasterDataSuccess = (Data) => ({
    type: GET_MASTER_SUCCESS,
    payload: Data,
});

export const getMasterDataFailure = (error) => ({
    type: GET_MASTER_FAILURE,
    payload: error,
});
export const addMasterDataRequest = (data) => ({
    type: ADD_MASTER_REQUEST,
    payload: data,
});
export const addMasterDataSuccess = (data) => ({
    type: ADD_MASTER_SUCCESS,
    payload: data,
});
export const deleteMasterDataRequest = (placeId) => ({
    type: DELETE_MASTER_REQUEST,
    payload: placeId,
});
export const deleteMasterDataSuccess = (placeId) => ({
    type: DELETE_MASTER_SUCCESS,
    payload: placeId,
});
export const editMasterDataRequest = (data) => ({
    type: EDIT_MASTER_REQUEST,
    payload: data,
});
export const editMasterDataSuccess = (updateData) => ({
    type: EDIT_MASTER_SUCCESS,
    payload: updateData,
});
