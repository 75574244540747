import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Field, Form, Formik } from "formik";
import SimpleReactValidator from "simple-react-validator";
import { ApiResponseMessage } from "../../helper/tosterHelpers";
import { DANGER } from "../../redux/constant/commonConstants";
import { __t } from "../../i18n/translator";

import {
  addApiManagementDetailsRequest,
  updateApiManagementDetailsRequest,
} from "../../redux/apimanagement/action";
import { InitialState } from "./CommonFunction";

function AddEditKeys(props) {
  const params = props?.data || {};
  const validator = useRef(new SimpleReactValidator());
  const [ApiManagementData] = useState(InitialState(props?.data));
  const handleSubmit = async (values) => {
    if (validator.current.allValid()) {
      //form Data
      let data = {};
      if (params.id) {
        if (ApiManagementData !== values) {
          data = {
            key: values.keyName,
            value: values.valueName,
          };
          props.updateData(data);
        } else {
          ApiResponseMessage(
            __t("validations.common.same_Data_warning"),
            DANGER
          );
        }
      } else {
        data = {
          key: values.keyName,
          value: values.valueName,
        };
        props.addData(data);
      }
      setFormSubmitted(true);
      props.onModalDismiss(false);
    } else {
      validator.current.showMessages();
    }
  };
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    if (formSubmitted && props.createEvent != null) {
      props.onModalDismiss(false);
    }
  }, [props.createEvent != null]);

  const operationType = !params.id ? "Submit" : "Update";
  return (
    <>
      <Formik initialValues={ApiManagementData} onSubmit={handleSubmit}>
        {({ isSubmitting, values, setFieldValue }) => (
          <Form>
            <div className="form-group mb-2">
              <label htmlFor="keyName">
                Key Name<span className="text-danger required">*</span>
              </label>

              <Field
                type="text"
                id="keyName"
                className="form-control"
                placeholder="Enter Key Name"
                name="keyName"
                disabled={params.id ? true : false}
              />
              {validator.current.message(
                __t("validations.api.keyName"),
                values.keyName,
                "required",
                {
                  messages: {
                    required: __t("validations.api.keyName"),
                  },
                }
              )}
            </div>

            <div className="form-group mb-2">
              <label htmlFor="valueName">
                Value Name<span className="text-danger required">*</span>
              </label>
              <Field
                type="text"
                id="valueName"
                className="form-control"
                placeholder="Enter Value Name"
                name="valueName"
              />
              {validator.current.message(
                __t("validations.api.valueName"),
                values.valueName,
                "required",
                {
                  messages: {
                    required: __t("validations.api.valueName"),
                  },
                }
              )}
            </div>

            <div className="btn-box">
              <button
                className="btn btn-primary"
                type="submit"
                disabled={isSubmitting}
              >
                {operationType}
              </button>
              <button
                className="btn btn-outline"
                type="button"
                onClick={() => {
                  props.onModalDismiss(false);
                }}
              >
                cancel
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    createEvent: state?.event?.createEvent,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addData: (data) => dispatch(addApiManagementDetailsRequest(data)),
    updateData: (data) => dispatch(updateApiManagementDetailsRequest(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditKeys);
