import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  ADD_THEME_REQUEST,
  DELETE_THEME_REQUEST,
  EDIT_THEME_REQUEST,
  GET_THEME_REQUEST,
} from "./type";
import {
  getThemeSuccess,
  addThemeSuccess,
  deleteThemeSuccess,
  getThemeRequest,
  editThemeSuccess,
} from "./action";
import { DANGER, SUCCESS } from "../../constant/commonConstants";
import { invokeApi } from "../../../enverment/axiosHelper";
import { ApiResponseMessage } from "../../../helper/tosterHelpers";
import { handleApiCallException } from "../../shared/action";
function* getThemeSaga(action) {
  let res = "";
  try {
    res = yield call(invokeApi, "post", "/masterThemeList", action.payload);

    if (res.status) {
      yield put(getThemeSuccess(res.data));
    } else {
      yield put(getThemeSuccess());
    }
    // ApiResponseMessage(res.message, res.status ? SUCCESS : DANGER);
  } catch (error) {
    yield put(handleApiCallException(error));
  }
}

function* addThemeSaga(action) {
  try {
    const response = yield call(
      invokeApi,
      "post",
      "/addMasterTheme",
      action.payload
    );
    if (response.status) {
      yield put(addThemeSuccess(response.data));
      yield put(getThemeRequest({})); // Fetch updated data
    } else {
      yield put(addThemeSuccess());
    }
    ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER);
  } catch (error) {
    yield put(handleApiCallException(error));
  }
}

function* removeTheme(action) {
  try {
    const response = yield call(
      invokeApi,
      "post",
      "/deleteMasterTheme",
      action.payload
    );
    if (response.status) {
      yield put(deleteThemeSuccess(response.data));
      yield put(getThemeRequest({})); // Fetch updated data
    } else {
      yield put(deleteThemeSuccess());
    }
    ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER);
  } catch (error) {
    yield put(handleApiCallException(error));
  }
}

function* updateTheme(action) {
  const formData = action.payload;
  const convertedData = {};
  for (const [key, value] of formData.entries()) {
    convertedData[key] = value;
  }
  try {
    const response = yield call(
      invokeApi,
      "put",
      `/updateMasterTheme/${convertedData.id}`,
      action.payload
    );
    if (response.status) {
      yield put(editThemeSuccess(response.data));
      yield put(getThemeRequest()); // Fetch updated data
    } else {
      yield put(editThemeSuccess());
    }
    ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER);
  } catch (error) {
    yield put(handleApiCallException(error));
  }
}
function* watchThemeRequest() {
  yield takeEvery(GET_THEME_REQUEST, getThemeSaga);
  yield takeEvery(ADD_THEME_REQUEST, addThemeSaga);
  yield takeEvery(DELETE_THEME_REQUEST, removeTheme);
  yield takeEvery(EDIT_THEME_REQUEST, updateTheme);
}
function* ThemeSaga() {
  yield all([fork(watchThemeRequest)]);
}

export default ThemeSaga;
