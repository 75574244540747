export const addVoiceInitialState = (values, gender) => {
  let value = values === undefined ? "" : values;
  return {
    id: value?.id,
    ssmlGender: gender,
    languageCode: value?.languageCode,
    voiceImage: value?.voiceImage,
    languageName: value?.languageName,
  };
};
export const addLanguageInitialState = (values) => {
  let value = values === undefined ? "" : values;
  return {
    id: value?.id,
    languageName: value?.languageName,
    languageImage: value?.languageImage,
  };
};
