import React from 'react'
import { Card } from 'react-bootstrap'
import ComeingsoonShapeImg1 from '../../assets/images/coming-shape.png';
import ComeingsoonShapeImg2 from '../../assets/images/coming-shape2.png';
import ComeingsoonShapeImg3 from '../../assets/images/coming-shape3.png';
import { ComingSoonSvg } from "../Common/CommonSvg"
import { Link } from 'react-router-dom';

export default function ComingSoon() {
    return (
        <>
            {/* <Card className='coming-soon-page'>
                <div className='inner-content'>
                    <h2><span>Coming Soon...</span></h2>
                    <p>Our team are currently working hard building this page!</p>
                    <Link to="/home">
                        <button className="btn btn-outline-primary" type="submit" title='Back To Dashboard'>
                            Back to dashboard
                        </button>
                    </Link>

                </div>
                <div className='img-box'>
                    <img src={ComeingsoonImg} />
                </div>
            </Card> */}
            {/* <Card className='coming-soon-page'>
                <div className='img-box'>
                    <img src={ComeingsoonImg} />
                </div>
                <div className='inner-content'>
                    <h2>This Page is <span>Coming Soon</span></h2>
                    <p>Our team are currently working hard building this page!</p>
                    <button className="btn btn-outline-primary" type="submit" title='Back To Dashboard'>
                        Back to dashboard
                    </button>
                </div>                
            </Card> */}
            <Card className='coming-soon-page'>
                <div className='inner-content'>
                    <h2>Coming Soon...</h2>
                    <p>Our team are currently working hard building this page!</p>
                    <Link to={"/home"} className="btn btn-outline-primary" type="submit" title='Back To Dashboard'>                        
                        Back to dashboard                        
                    </Link>

                </div>
                <div className='img-box'>
                    <img src={ComeingsoonShapeImg1} alt='coming_soon' />
                    <img src={ComeingsoonShapeImg2} alt='coming_soon' />
                    <img src={ComeingsoonShapeImg3} alt='coming_soon' />
                </div>
                <div className="cloud-img">
                    <ComingSoonSvg />
                </div>
            </Card>
        </>
    )
}
