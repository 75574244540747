import { REMOVE_STORY_REQUEST, REMOVE_STORY_SUCCESS, STORY_REQUEST, STORY_SUCCESS, VIEW_STORY_REQUEST, VIEW_STORY_SUCCESS } from "./type";

export const getStoryRequest = (story) => {
    return {
        type: STORY_REQUEST,
        payload: story,
    };
};

export const getStorySuccess = (story) => {
    return {
        type: STORY_SUCCESS,
        payload: story,
    };
};
export const getViewStoryRequest = (storyId) => {

    return {
        type: VIEW_STORY_REQUEST,
        payload: storyId,
    };
};
export const getViewStorySuccess = (story) => {
    return {
        type: VIEW_STORY_SUCCESS,
        payload: story,
    };
};
export const removeStoryRequest = (storyId) => {

    return {
        type: REMOVE_STORY_REQUEST,
        payload: storyId,
    };
};
export const removeStorySuccess = (story) => {
    return {
        type: REMOVE_STORY_SUCCESS,
        payload: story,
    };
};