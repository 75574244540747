import { useEffect, useState } from "react";
import { connect } from "react-redux";
import CommonTable from "../../components/Table/CommonTable";
import { EditSvg } from "../../components/Common/CommonSvg";
import CustomisedModal from "../../components/Modal/CustomisedModal";
import { getApiManagementDetailsRequest } from "../../redux/apimanagement/action";
import AddEditKeys from "./AddEditKeys";

function ApiManagement(props) {
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage, setDataPerPage] = useState(10);
  const [searchData, setSearchData] = useState(null);
  const [getList, setGetList] = useState([]);

  useEffect(() => {
    setCurrentPage(1);
  }, [dataPerPage, searchData]);

  useEffect(() => {
    props.getList({
      limit: dataPerPage,
      page: currentPage,
      search: searchData !== null ? searchData : null,
    });
    searchData !== null && setCurrentPage(1);
  }, [currentPage, dataPerPage, searchData]);

  useEffect(() => {
    if (props.getData) {
      setGetList(props.getData);
    }
  }, [props.getData]);

  const columns = [
    {
      dataField: "key",
      text: "key Name",
      formatter: (cell, row) => <>{row.key}</>,
      sort: true,
    },
    {
      dataField: "value",
      text: "value Name",
      formatter: (cell, row) => <>{row.value}</>,
      sort: true,
    },

    {
      dataField: "Action",
      formatter: (cell, row) => (
        <>
          <a
            className="btn btn-link edit-btn d-inline"
            title="Edit"
            onClick={() => handleEditClick(row)}
          >
            <EditSvg />
          </a>
        </>
      ),
      sort: false,
      classes: "text-center",
      headerClasses: "action-box text-center",
    },
  ];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const handleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const handleEditClick = (row) => {
    setIsModalOpen(!isModalOpen);
    row = { ...row, id: "true" };
    setIsEdit(row);
  };

  return (
    <>
      <CommonTable
        data={getList ? getList : []}
        columns={columns}
        dataPerPage={dataPerPage}
        currentPage={currentPage}
        setDataPerPage={setDataPerPage}
        setCurrentPage={setCurrentPage}
        pagination={true}
        totalRecords={props?.totalRecords}
        Header={true}
        setSearchData={setSearchData}
        updateData={props.getList}
        searchBar={false}
        href={handleModal}
        buttonName={"Add"}
      />

      {isModalOpen && (
        <CustomisedModal
          isModalOpen={isModalOpen}
          modalName={!isEdit ? "Add" : "Edit"}
          onModalDismiss={() => {
            handleModal();
            setIsEdit(false);
          }}
        >
          <AddEditKeys
            onModalDismiss={() => {
              handleModal();
              setIsEdit(null);
            }}
            data={isEdit}
          />
        </CustomisedModal>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  let DataList = [];
  if (state.apiManagement?.data && state?.apiManagement?.data?.data) {
    DataList = state?.apiManagement?.data?.data;
  }

  return {
    getData: DataList,
    totalRecords: state?.apiManagement?.data?.data?.length,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getList: (data) => {
      dispatch(getApiManagementDetailsRequest(data));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ApiManagement);
