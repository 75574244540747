import { Field } from "formik";
import React, { useState } from "react";
import { __t } from "../../i18n/translator";
import { HideSvg, ShowSvg } from "../../components/Common/CommonSvg";

export default function PasswordView(props) {
  const { values, validator } = props;

  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);

  const handlePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleConfirmPassword = () => {
    setConfirmPassword(!confirmPassword);
  };

  return (
    <>
      <div className="login-head text-center">
        <h3>Create New Password</h3>
        <p>For security purposes, please create a password and confirm it. </p>
      </div>
      <div className="password-box">
        <div className="form-group">
          <label htmlFor="password">New Password</label>
          <Field
            id="password"
            name="password"
            className="form-control"
            placeholder="Enter new password"
            type={showPassword ? "text" : "password"}
          />

          <div className="d-flex" onClick={handlePassword}>
            {showPassword ? (
              <div type={"show"} className="show-hide-password hide-icon">
                <ShowSvg />
              </div>
            ) : (
              <div type={"hide"} className="show-hide-password">
                <HideSvg />
              </div>
            )}
          </div>

          {validator.current.message(
            __t("validations.auth.password"),
            values.password,
            "required|min:8",
            {
              messages: {
                required: __t("validations.auth.password"),
              },
            }
          )}
        </div>
        <div className="form-group mb-0">
          <label htmlFor="confirmPassword">Confirm Password</label>
          <Field
            type={confirmPassword ? "text" : "password"}
            id="confirmPassword"
            name="confirmPassword"
            className="form-control"
            placeholder="Enter confirm password"
          />

          <div className="d-flex" onClick={handleConfirmPassword}>
            {confirmPassword ? (
              <div type={"show"} className="show-hide-password hide-icon">
                <ShowSvg />
              </div>
            ) : (
              <div type={"hide"} className="show-hide-password">
                <HideSvg />
              </div>
            )}
          </div>

          {validator.current.message(
            __t("validations.auth.confirm_password"),
            values.confirmPassword,
            `required|min:8`,
            {
              messages: {
                required: __t("validations.auth.confirm_password"),
              },
            }
          )}
        </div>
      </div>
    </>
  );
}
