import { SHOW_MESSAGE } from './actionType';

const initialState = {
  alert: null,
};

const alertReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_MESSAGE:
      return {
        ...state,
        alert: action.payload,
      };
    default:
      return state;
  }
};
export default alertReducer