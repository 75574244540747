export const addCouponInitialState = (values) => {
  const defaultValues = {
    couponCode: "",
    discountAmount: "",
    validityEndDate: new Date(),
    validityStartDate: new Date(),
    usageLimit: "",
    minPurchase: "",
  };

  if (values) {
    return {
      ...defaultValues,
      couponCode: values.couponCode || defaultValues.couponCode,
      discountAmount: values.discountAmount || defaultValues.discountAmount,
      validityEndDate: values.validityEndDate
        ? new Date(values.validityEndDate)
        : defaultValues.validityEndDate,
      validityStartDate: values.validityStartDate
        ? new Date(values.validityStartDate)
        : defaultValues.validityStartDate,
      usageLimit: values.usageLimit || defaultValues.usageLimit,
      minPurchase: values.minPurchase || defaultValues.minPurchase,
    };
  }

  return defaultValues;
};
