import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { handleApiCallException } from "../shared/action";
import { invokeApi } from "../../enverment/axiosHelper";
import {
  CREATE_STORY_REQUEST,
  GET_ACTIVE_REQUEST,
  GET_ACTIVE_SUCCESS,
  GET_REPORT_STORT_REQUEST,
  GET_VIEW_STORY_REQUEST,
} from "./type";
import {
  creditStorySuccess,
  getActiveSuccess,
  getReportStoryRequest,
  getReportStorySuccess,
  getViewStoryRequest,
  getViewStorySuccess,
} from "./action";
import { ApiResponseMessage } from "../../helper/tosterHelpers";
import { DANGER, SUCCESS } from "../constant/commonConstants";

function* getReportList({ payload: data }) {
  try {
    const response = yield call(invokeApi, "post", "/reportStoryList", data);
    if (response.status) {
      yield put(getReportStorySuccess(response.data));
    } else {
      yield put(getReportStorySuccess([]));
      handleApiCallException(response.message);
    }
  } catch (error) {
    handleApiCallException(error);
  }
}
function* getReportStory({ payload: data }) {
  try {
    const response = yield call(invokeApi, "get", `/getstory/${data}`);
    if (response.status) {
      yield put(getViewStorySuccess(response.data));
    } else {
      yield put(getViewStorySuccess([]));
      handleApiCallException(response.message);
    }
  } catch (error) {
    handleApiCallException(error);
  }
}
function* statusChangeRequest({ payload: data }) {
  try {
    const response = yield call(invokeApi, "post", `/reportStory`, {
      id: data.id,
    });
    if (response.status) {
      yield put(getActiveSuccess(response.data));
      ApiResponseMessage(response.message, SUCCESS);
      yield put(
        getReportStoryRequest({
          limit: data.dataPerPage,
          page: data.currentPage,
        })
      );
    } else {
      yield put(getViewStorySuccess([]));
      ApiResponseMessage(response.message, DANGER);
      handleApiCallException(response.message);
    }
  } catch (error) {
    handleApiCallException(error);
  }
}

function* createStory({ payload: data }) {
  try {
    const response = yield call(invokeApi, "post", `/creditStory`, data);
    if (response.status) {
      yield put(creditStorySuccess(response.data));
      yield put(getReportStoryRequest());

      ApiResponseMessage(response.message, SUCCESS);
    } else {
      yield put(creditStorySuccess([]));
      ApiResponseMessage(response.message, DANGER);
    }
  } catch (error) {
    handleApiCallException(error);
  }
}

export function* ReportListRequest() {
  yield takeLatest(GET_REPORT_STORT_REQUEST, getReportList);
  yield takeLatest(GET_VIEW_STORY_REQUEST, getReportStory);
  yield takeEvery(GET_ACTIVE_REQUEST, statusChangeRequest);
  yield takeEvery(CREATE_STORY_REQUEST, createStory);
}
function* ReportStorySaga() {
  yield all([fork(ReportListRequest)]);
}
export default ReportStorySaga;
