import React, { useState } from "react";
import { VoiceSvg } from "../../components/Common/CommonSvg";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Plans from "./Plans/Plans";
import Items from "./Items/Items";
import TableHeader from "../../components/Table/TableHeader";

export default function ItemsAndPlan() {
  const [tabActive, setTabActive] = useState("Plans");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const [isAddPlan, setIsAddPlan] = useState(false);
  const [isAddItem, setIsAddItem] = useState(false);

  return (
    <>
      <TableHeader />
      <Nav className="nav-underline Plans_tab">
        <NavItem>
          <NavLink
            className={tabActive === "Plans" ? "active" : " "}
            onClick={() => setTabActive("Plans")}
          >
            <span>{/* <VoiceSvg /> */}</span>
            <span>Plans</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={tabActive === "Item" ? "active" : " "}
            onClick={() => setTabActive("Item")}
          >
            <span>{/* <ItemSvg /> */}</span>
            <span>Items</span>
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent className="card Plans_tabcontent" activeTab={tabActive}>
        <TabPane tabId="Plans">
          <Plans isAddPlan={isAddPlan} setIsAddPlan={setIsAddPlan} />
        </TabPane>
        <TabPane tabId="Item">
          <Items isAddItem={isAddItem} setIsAddItem={setIsAddItem} />
        </TabPane>
      </TabContent>
    </>
  );
}
