import React, { useState, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
import LogoSmall from "../../assets/images/logo-sm.png";
import { Nav, NavItem } from "reactstrap";
import {
  ApiSvg,
  CouponSvg,
  DropdownSvg,
  EmailTemplateSvg,
  HomeSvg,
  MasterSvg,
  NoteSvg,
  PaymentSvg,
  RateUsSvg,
  ReportedNoteSvg,
  SupportSvg,
  UserSvg,
} from "./CommonSvg";
import { updateCurrentPageName } from "../../utils/authUtils";

export default function Sidebar() {
  const location = useLocation();
  const [dropdownOpen, setDropdownOpen] = useState({
    Master: false,
  });
  const toggle = () => {
    setDropdownOpen({ ...dropdownOpen, Master: !dropdownOpen.Master });
  };

  useEffect(() => {
    setDropdownOpen((prevState) => ({
      ...prevState,
      Master:
        location.pathname === "/theme" ||
        location.pathname === "/voice" ||
        location.pathname === "/spiceup" ||
        location.pathname === "/scene" ||
        location.pathname === "/character" ||
        location.pathname === "/place" ||
        location.pathname === "/event",
    }));
  }, [location.pathname]);
  const [UserDropdownOpen, setUserDropdownOpen] = useState({
    User: false,
  });
  const [LogDropdownOpen, setLogDropdownOpen] = useState({
    Log: false,
  });

  const [Subscription, setSubscription] = useState({
    plans: false,
  });

  const LogOpentoggle = () => {
    setLogDropdownOpen({ ...LogDropdownOpen, Log: !LogDropdownOpen.Log });
  };

  const UserOpentoggle = () => {
    setUserDropdownOpen({ ...UserDropdownOpen, User: !UserDropdownOpen.User });
  };
  const SubPlansToggle = () => {
    setSubscription({ ...Subscription, plans: !Subscription.plans });
  };
  useEffect(() => {
    setUserDropdownOpen((prevState) => ({
      ...prevState,
      User:
        location.pathname === "/usermanagement" ||
        location.pathname === "/userstats" ||
        location.pathname.startsWith("/usermanagement/view/"),
    }));
  }, [location.pathname]);
  useEffect(() => {
    setSubscription((prevState) => ({
      ...prevState,
      plans:
        location.pathname === "/plans" ||
        location.pathname === "/subscription" ||
        location.pathname === "/items",
    }));
  }, [location.pathname]);

  return (
    <>
      <Link
        to="/home"
        onClick={() => updateCurrentPageName("Dashboard")}
        title="KidLitKatalyst"
        className="sidebar-logo"
      >
        <img alt="Logo" className="logo_main" src={Logo} />
        <img alt="Logo" className="logo_main" src={LogoSmall} />
      </Link>
      <Nav className="mt-3 small-scroll" vertical>
        <NavItem>
          <NavLink
            to={"/home"}
            onClick={() => updateCurrentPageName("Dashboard")}
          >
            <HomeSvg />
            <span>Dashboard</span>
          </NavLink>
        </NavItem>
        <NavItem className={`dropdown ${UserDropdownOpen.User && "show"}`}>
          <button
            onClick={UserOpentoggle}
            className={"nav-link btn-link w-100"}
          >
            <UserSvg />
            <span>User Management</span>
            <DropdownSvg />
          </button>
          {UserDropdownOpen.User && (
            <Nav vertical className="has-child h-auto dropdown-menu show">
              <NavLink
                className="dropdown-item"
                onClick={() => updateCurrentPageName("User Info")}
                to="/usermanagement"
              >
                <span>User Info</span>
              </NavLink>
              <NavLink
                className="dropdown-item"
                onClick={() => updateCurrentPageName("User Stats")}
                to="/userstats"
              >
                <span>User Stats</span>
              </NavLink>
            </Nav>
          )}
        </NavItem>
        <NavItem className={`dropdown ${dropdownOpen.Master && "show"}`}>
          <button onClick={toggle} className={"nav-link btn-link w-100"}>
            <MasterSvg />
            <span>Master</span>
            <DropdownSvg />
          </button>
          {dropdownOpen.Master && (
            <Nav vertical className="has-child h-auto dropdown-menu show">
              <NavLink
                className="dropdown-item"
                onClick={() => updateCurrentPageName("Character")}
                to="/character"
              >
                <span>Character</span>
              </NavLink>
              <NavLink
                className="dropdown-item"
                to="/place"
                onClick={() => updateCurrentPageName("Place")}
              >
                <span>Place</span>
              </NavLink>
              <NavLink
                className="dropdown-item"
                onClick={() => updateCurrentPageName("Event")}
                to="/event"
              >
                <span>Event</span>
              </NavLink>
              <NavLink
                className="dropdown-item"
                to={"/scene"}
                onClick={() => updateCurrentPageName("Scene")}
              >
                <span>Scene</span>
              </NavLink>
              <NavLink
                className="dropdown-item"
                to={"/spiceup"}
                onClick={() => updateCurrentPageName("Spice Up")}
              >
                <span>Spice Up</span>
              </NavLink>

              <NavLink
                className="dropdown-item"
                onClick={() => updateCurrentPageName("Voice & Language")}
                to="/voice"
              >
                <span>Voice & Language</span>
              </NavLink>
              <NavLink
                className="dropdown-item"
                onClick={() => updateCurrentPageName("Theme")}
                to="/theme"
              >
                <span>Theme</span>
              </NavLink>
            </Nav>
          )}
        </NavItem>
        <NavItem>
          <NavLink
            to={"/payment"}
            onClick={() => updateCurrentPageName("Payment History")}
          >
            <PaymentSvg />
            <span>Payment History</span>
          </NavLink>
        </NavItem>
        {/* <NavItem>
                    <NavLink
                        to={"/subscription"}
                        onClick={() => updateCurrentPageName("Subscription")}
                    >
                        <NoteSvg />
                        <span></span>
                    </NavLink>
                </NavItem> */}
        {/* <NavItem className={`dropdown ${Subscription.plans && "show"}`}>
                    <button
                        onClick={SubPlansToggle}
                        className={"nav-link btn-link w-100"}
                    >
                        <UserSvg />
                        <span>Subscription</span>
                        <DropdownSvg />
                    </button>
                    {Subscription.plans && (
                        <Nav vertical className="has-child h-auto dropdown-menu show">
                            <NavLink
                                className="dropdown-item"
                                onClick={() => updateCurrentPageName("Subscription")}
                                to="/subscription"
                            >
                                <span>Subscription</span>
                            </NavLink>
                            <NavLink
                                className="dropdown-item"
                                onClick={() => updateCurrentPageName("Plans")}
                                to="/plans"
                            >
                                <span>Plans</span>
                            </NavLink>
                            <NavLink
                                className="dropdown-item"
                                onClick={() => updateCurrentPageName("Items")}
                                to="/items"
                            >
                                <span>Items</span>
                            </NavLink>
                        </Nav>
                    )}
                </NavItem> */}
        <NavItem>
          <NavLink
            to={"/items"}
            onClick={() => updateCurrentPageName("Subscription")}
          >
            <UserSvg />
            <span>Subscription</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            to={"/reportstory"}
            onClick={() => updateCurrentPageName("Reported Story")}
          >
            <ReportedNoteSvg />
            <span>Reported Story</span>
          </NavLink>
        </NavItem>
        {/* <NavItem>
                    <NavLink
                        to={"/story"}
                        onClick={() => updateCurrentPageName("Story status")}
                    >
                        <NoteSvg />
                        <span>Story status</span>
                    </NavLink>
                </NavItem> */}
        <NavItem>
          <NavLink
            to={"/email"}
            onClick={() => updateCurrentPageName("Email Template")}
          >
            <EmailTemplateSvg />
            <span>Email Template</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavItem
            className={`dropdown ${LogDropdownOpen.Log && "show"}`}
          ></NavItem>
          <NavLink
            to={"/userlogs"}
            onClick={() => updateCurrentPageName("Logs")}
          >
            <NoteSvg />
            <span>Logs</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            to={"/api"}
            onClick={() => updateCurrentPageName("API Management")}
          >
            <ApiSvg />
            <span>API Management</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            to={"/coupons"}
            onClick={() => updateCurrentPageName("Redeem Coupons")}
          >
            <CouponSvg />
            <span>Redeem Coupons</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            to={"/rateus"}
            onClick={() => updateCurrentPageName("Rate Us")}
          >
            <RateUsSvg />
            <span>Rate Us</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            to={"/support"}
            onClick={() => updateCurrentPageName("Support")}
          >
            <SupportSvg />
            <span>Support</span>
          </NavLink>
        </NavItem>
      </Nav>
    </>
  );
}
