import { useRef } from "react";
import { addItemPlansInitialState } from "./CommonFunction";
import SimpleReactValidator from "simple-react-validator";
import { useState } from "react";
import { ApiResponseMessage } from "../../../helper/tosterHelpers";

import { DANGER } from "../../../redux/constant/commonConstants";
import { useEffect } from "react";
import { Field, Form, Formik } from "formik"; // Updated import statement
import { __t } from "../../../i18n/translator";
import { connect } from "react-redux";
import {
  addItemsPlanRequest,
  editItemsPlanRequest,
} from "../../../redux/subscription/items/actions";
import { IntComponent } from "../../../helper/NumberFormat";

function AddItemPlans(props) {
  const params = props?.data || {};
  const [TableRef, setTableRef] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const validator = useRef(new SimpleReactValidator());
  let ItemPlansData = addItemPlansInitialState(props?.data);

  useEffect(() => {
    ItemPlansData = addItemPlansInitialState(props?.data);
    setTableRef(true);
  }, [props.data]);

  const handleSubmit = async (values) => {
    if (validator.current.allValid() && values?.itemNumber != "") {
      let modifiedValues = {
        itemName: values.itemName,
        totalItem: values.itemNumber,
        price: values.price,
      };

      if (params.id) {
        if (ItemPlansData !== values) {
          props.updatePlan({ ...modifiedValues, id: params.id });
          props.setIsAddItem(false);
          props.setIsEdit(null);
        } else {
          ApiResponseMessage(
            __t("validations.common.same_Data_warning"),
            DANGER
          );
        }
      } else {
        props.addPlan(modifiedValues);
      }
      setFormSubmitted(true);
      props.setIsAddItem(false);
    } else {
      validator.current.showMessages();
    }
  };

  useEffect(() => {
    if (formSubmitted) {
      props.setIsAddItem(false);
      props.setIsEdit(null);
    }
  }, [formSubmitted]);
  useEffect(() => {
    setTableRef(false);
  }, [TableRef]);
  const operationType = !params.id ? "Submit" : "Update";

  return (
    !TableRef && (
      <Formik initialValues={ItemPlansData} onSubmit={handleSubmit}>
        {({ isSubmitting, values, setFieldValue }) => (
          <Form className="subscription-form row">
            <div className="form-group mb-2 col-12">
              <label htmlFor="itemName">
                Item Name <span className="text-danger required">*</span>
              </label>
              <Field
                type="text"
                id="itemName"
                className="form-control"
                name="itemName"
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, "");
                }}
              />
              {validator.current.message(
                __t("validations.common.allow_only_alpha"),
                values.itemName,
                "required",
                {
                  messages: {
                    required: __t("validations.Items.noOfItem"),
                    // alpha_space: __t("validations.common.allow_only_alpha"),
                  },
                }
              )}
            </div>
            <div className="form-group mb-2 col-6">
              <label htmlFor="itemNumber">
                No of Items <span className="text-danger required">*</span>
              </label>
              <Field
                type="text"
                id="itemNumber"
                className="form-control"
                name="itemNumber"
                onInput={IntComponent}
              />
              {validator.current.message(
                __t("validations.Items.noOfItem"),
                values.itemNumber,
                "required",
                {
                  messages: {
                    required: __t("validations.Items.noOfItem"),
                  },
                }
              )}
            </div>
            <div className="form-group mb-2 col-6">
              <label htmlFor="price">
                Price <span className="text-danger required">*</span>
              </label>
              <Field
                type="text"
                id="price"
                className="form-control"
                name="price"
                onInput={IntComponent}
              />
              {validator.current.message(
                __t("validations.Items.price"),
                values.price,
                "required",
                {
                  messages: {
                    required: __t("validations.Items.price"),
                  },
                }
              )}
            </div>

            <div className="btn-box">
              <button
                className="btn btn-primary"
                type="submit"
                disabled={isSubmitting}
              >
                {operationType}
              </button>
              <button
                className="btn btn-outline"
                type="button"
                onClick={() => {
                  props.setIsAddItem(false);
                  props.setIsEdit(null);
                }}
              >
                cancel
              </button>
            </div>
          </Form>
        )}
      </Formik>
    )
  );
}

const mapStateToProps = (state) => {
  return {
    createPlan: state.plans.addPlans,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addPlan: (data) => {
      dispatch(addItemsPlanRequest(data));
    },
    updatePlan: (data) => {
      dispatch(editItemsPlanRequest(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddItemPlans);
