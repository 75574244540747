import { GET_DASHBOARD_DATA_REQUEST, GET_DASHBOARD_DATA_SUCCESS } from "./type";

const initialState = {
  List: null,
};

const dashBoardReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DASHBOARD_DATA_REQUEST:
      return {
        ...state,
        List: null,
      };
    case GET_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        List: action.payload,
      };
    default:
      return { ...state };
  }
};
export default dashBoardReducer;
