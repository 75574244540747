import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  deleteMasterDataRequest,
  getMasterDataRequest,
} from "../../../redux/master/character/action";
import CustomisedModal from "../../../components/Modal/CustomisedModal";
import {
  commonConfirmBox,
  commonMessangerBox,
} from "../../../helper/messageHelper";
import { Card } from "reactstrap";
import AddMasterCharacter from "./AddMasterCharacter";
import { EditSvg } from "../../../components/Common/CommonSvg";
import TableHeader from "../../../components/Table/TableHeader";
import CustomPagination from "../../../components/Table/CommonPagination";
function MasterCharacter(props) {
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage, setDataPerPage] = useState(16);
  const [getList, setGetList] = useState([]);
  useEffect(() => {
    setCurrentPage(1);
  }, [dataPerPage]);
  useEffect(() => {
    props.getList({
      limit: dataPerPage,
      page: currentPage,
    });
  }, [currentPage, dataPerPage]);
  useEffect(() => {
    if (props.getData) {
      setGetList(props.getData);
    }
  }, [props.getData]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const handleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleEditClick = (row) => {
    if (row?.isActive) {
      setIsModalOpen(!isModalOpen);
      setIsEdit(row);
    } else {
      commonMessangerBox(
        "Before updating character you have to activate status"
      );
    }
  };

  const handleDeleteClick = async (row) => {
    const response = await commonConfirmBox(
      row.isActive
        ? "Do you want to inactive this character?"
        : "Do you want to active this character?"
    );
    if (response) {
      props.changeStatus({
        id: row.id,
        isActive: !row.isActive,
        currentPage,
        dataPerPage,
      });
    }
  };
  return (
    <>
      <TableHeader href={handleModal} buttonName={"Create Character"} />
      {getList.length > 0 ? (
        <div className="master-card-list character-card-list mb-3">
          {getList.length > 0 &&
            getList.map((d, index) => {
              return (
                <Card key={index}>
                  <img
                    src={d?.characterAvtar}
                    className="object-fit-cover"
                    alt="theme"
                  />
                  {
                    // d?.characterAvtar?.includes("avatar1") ||
                    // d?.characterAvtar?.includes("avatar2")
                    d.id === "1" || d.id === "2" ? (
                      <div className="custom-card-data"></div>
                    ) : (
                      <div className="card-data">
                        <div className="switch">
                          <input
                            type="checkbox"
                            onChange={() => handleDeleteClick(d)}
                            checked={d?.isActive ? true : false}
                          />

                          <label
                            className="switch-check"
                            onClick={() => {
                              handleDeleteClick(d);
                            }}
                          >
                            <div className="switch-btn"></div>
                            <div className="active-switch">Active</div>
                            <div className="deactive-switch">Inactive</div>
                          </label>
                        </div>
                        <div className="overlay">
                          <a
                            className="btn btn-link edit-btn"
                            title="Edit"
                            onClick={() => handleEditClick(d)}
                          >
                            <EditSvg />
                          </a>
                        </div>
                      </div>
                    )
                  }
                </Card>
              );
            })}
        </div>
      ) : (
        <h4 className="text-center mt-5 mb-5">No Data Found</h4>
      )}

      <div>
        <CustomPagination
          dataPerPage={dataPerPage}
          currentPage={currentPage}
          totalData={props.totalRecords}
          setCurrentPage={setCurrentPage}
          setDataPerPage={setDataPerPage}
        />
      </div>
      {isModalOpen && (
        <CustomisedModal
          isModalOpen={isModalOpen}
          modalName={!isEdit ? "Add Master Character" : "Edit Master Character"}
          onModalDismiss={() => {
            handleModal();
            setIsEdit(null);
          }}
        >
          <AddMasterCharacter
            onModalDismiss={() => {
              handleModal();
              setIsEdit(null);
            }}
            dataPerPage={dataPerPage}
            currentPage={currentPage}
            data={isEdit}
          />
        </CustomisedModal>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  let DataList;
  let totalRecords = 0;
  if (
    state?.master?.data?.avatarList &&
    state?.master?.data?.avatarList?.length > 0
  ) {
    DataList = state.master.data.avatarList;
    totalRecords = state.master.data.totalRecords;
  }
  return {
    getData: DataList,
    totalRecords: totalRecords,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getList: (data) => {
      dispatch(getMasterDataRequest(data));
    },
    changeStatus: (data) => {
      dispatch(deleteMasterDataRequest(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MasterCharacter);
