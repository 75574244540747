import {
    ADD_VOICE_REQUEST,
    ADD_VOICE_SUCCESS,
    DELETE_VOICE_REQUEST,
    DELETE_VOICE_SUCCESS,
    EDIT_VOICE_REQUEST,
    EDIT_VOICE_SUCCESS,
    GET_VOICE_FAILURE,
    GET_VOICE_REQUEST,
    GET_VOICE_SUCCESS,
} from "./type";
const initialState = {
    getVoice: null,
    createVoice: null,
    updateVoice: null,
    removeVoice: null,
};

const voiceReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_VOICE_REQUEST:
            return {
                ...state,
            };
        case GET_VOICE_SUCCESS:
            return {
                ...state,
                getVoice: action.payload,
            };
        case GET_VOICE_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case ADD_VOICE_REQUEST:
            return {
                ...state,
                createVoice: action.payload,
            };
        case ADD_VOICE_SUCCESS:
            return {
                ...state,
                createVoice: action.payload,
            };
        case DELETE_VOICE_REQUEST:
            return {
                ...state,
                removeVoice: action.payload,
            };
        case DELETE_VOICE_SUCCESS:
            return {
                ...state,
                removeVoice: action.payload,
            };
        case EDIT_VOICE_REQUEST:
            return {
                ...state,
            };
        case EDIT_VOICE_SUCCESS:
            return {
                ...state,
                Voice: action.payload,
            };
        default:
            return { ...state };
    }
};

export default voiceReducer;
