import { all } from "redux-saga/effects";
import LoginSaga from "./auth/login/saga";
import { AlertSaga } from "./alert/saga";
import ForgotSaga from "./auth/forgot/saga";
import MasterSaga from "./master/character/saga";
import PlaceSaga from "./master/place/saga";
import EventSaga from "./master/event/saga";
import SceneSaga from "./master/scene/saga";
import SpiceUpSaga from "./master/spiceup/saga";
import LanguageSaga from "./master/language/saga";
import VoiceSaga from "./master/voice/saga";
import ThemeSaga from "./master/theme/saga";
import EmailSaga from "./email/saga";
import storySaga from "./story/saga";
import CouponSaga from "./redeemcoupons/saga";
import SupportSaga from "./support/saga";
import getUserSaga from "./user/usermanagement/saga";
import ReportStorySaga from "./reportstory/saga";
import subscriptionSaga from "./subscription/subscriptionplans/saga";
import plansSaga from "./subscription/plans/saga";
import itemsPlanSaga from "./subscription/items/saga";
import RateListSaga from "./rateus/saga";
import UserlogsSaga from "./user/userStats/saga";
import LandingPageSaga from "./landingpage/saga";
import ReportListSaga from "./reportstory/saga";
import getUserLogSaga from "./logs/logs/saga";
import getApiManagementSaga from "./apimanagement/saga";
import PaymentHistorySaga from "./paymentHistory/saga";
import DashboardSaga from "./dashBoard/saga";
import webSubscriptionSaga from "./subscription/webSubsription/saga";

function* rootSaga() {
  yield all([
    LoginSaga(),
    MasterSaga(),
    AlertSaga(),
    ForgotSaga(),
    PlaceSaga(),
    EventSaga(),
    SceneSaga(),
    SpiceUpSaga(),
    LanguageSaga(),
    VoiceSaga(),
    ThemeSaga(),
    getUserSaga(),
    EmailSaga(),
    storySaga(),
    UserlogsSaga(),
    CouponSaga(),
    SupportSaga(),
    ReportStorySaga(),
    subscriptionSaga(),
    plansSaga(),
    itemsPlanSaga(),
    RateListSaga(),
    LandingPageSaga(),
    getUserLogSaga(),
    getApiManagementSaga(),
    PaymentHistorySaga(),
    DashboardSaga(),
    webSubscriptionSaga()
  ]);
}
export default rootSaga;
