import React, { useState, useEffect } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import Playstore from "../../../assets/front-images/google-play.png";
import Applestore from "../../../assets/front-images/apple-store.png";
import CalltoactionMockup from "../../../assets/front-images/call-to-action-mockup.png";
import PlaneShape from "../../../assets/front-images/plan-shape.png";
import Featuresmockup from "../../../assets/front-images/features-page-mockup.png";
import Roundshape from "../../../assets/front-images/round-dots-white.png";
import Dropshape from "../../../assets/front-images/drop-shape.png";
import Dropshape2 from "../../../assets/front-images/drop-shape.png";
import {
    AboutussmallSvg,
    Benefits2Svg,
    BenefitsSvg,
    BirdSvg,
    RoundlineSvg,
    StarSvg,
} from "../../../components/Common/CommonFrontSvg";
import Header from "../common/Header";
import Footer from "../common/Footer";
import { sendemailRequest } from "../../../redux/landingpage/action";
import SimpleReactValidator from "simple-react-validator";
import { useRef } from "react";
import { __t } from "../../../i18n/translator";
import { Breadcrumb, BreadcrumbItem, Form } from "reactstrap";
import { Field, Formik } from "formik";
import { connect } from "react-redux";

function Features(props) {
    const validator = useRef(new SimpleReactValidator());
    const handleSubmit = (values) => {
        if (validator.current.allValid()) {
            props.SendEmailRequest(values);
        } else {
            validator.current.showMessages();
        }
    };
    return (
        <>
            <section className="features-baneer p-0">
                <div className="container">
                    <div className="inner-page-banner">
                        <h2>Features</h2>
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <a href="/">Home</a>
                            </BreadcrumbItem>
                            <BreadcrumbItem active>Features</BreadcrumbItem>
                        </Breadcrumb>
                        <div className="shape-box">
                            <StarSvg />
                            <StarSvg />
                        </div>
                    </div>
                </div>
            </section>

            <section className="main-features">
                <div className="container overflow-hidden">
                    <ScrollAnimation duration={0.3}
                        animateIn="animate__fadeInDown"
                        className="common-title text-center"
                    >
                        <span className="badge-title">Features</span>
                        <h2 className="title-box">Exciting Storytelling Style</h2>
                        <p>
                            Our revolutionary app harnesses the power of artificial
                            intelligence to deliver a one-of-a-kind storytelling experience
                            for children. With our AI-generated stories, your child will
                            embark on thrilling adventures, explore imaginative worlds, and
                            ignite their love for adventures like never before. Here are the
                            exceptional features that set KidLit Katalyst apart
                        </p>
                    </ScrollAnimation>
                    <div className="row justify-content-center">
                        <div className="col-4 main-features-col">
                            <ScrollAnimation duration={0.3}
                                animateIn="animate__fadeInLeft"
                                className="main-features-data"
                            >
                                <h4>AI-Generated Stories</h4>
                                <p>
                                    Prepare to be amazed as our advanced AI algorithms create
                                    unique and captivating stories tailored to your child's
                                    interests, reading level, and preferences. Say goodbye to
                                    one-size-fits-all narratives – with KidLit Katalyst, every
                                    story is a personalized journey.
                                </p>
                            </ScrollAnimation>
                            <ScrollAnimation duration={0.3}
                                animateIn="animate__fadeInLeft"
                                className="main-features-data"
                            >
                                <h4>Interactive Audio Adventure</h4>
                                <p>
                                    We believe in immersive storytelling. Our app brings stories
                                    to life through interactive elements that engage young
                                    adventures. They can interact with characters, make decisions
                                    that impact the plot, and be part of the magic unfolding on
                                    the pages.
                                </p>
                            </ScrollAnimation>
                        </div>
                        <div className="col-4 main-features-col">
                            <div className="mockup-img-box">
                                <ScrollAnimation duration={0.3} animateIn="zoomIn">
                                    <img src={Featuresmockup} alt="Mockup" />
                                </ScrollAnimation>
                                <div className="shape-box">
                                    <ScrollAnimation duration={0.3} animateIn="fadeIn">
                                        <AboutussmallSvg />
                                    </ScrollAnimation>
                                    <ScrollAnimation duration={0.3} animateIn="fadeIn">
                                        <RoundlineSvg />
                                    </ScrollAnimation>
                                </div>
                            </div>
                            <ScrollAnimation duration={0.3}
                                animateIn="animate__fadeIn"
                                className="main-features-data"
                            >
                                <h4>Expansive Genre Collection</h4>
                                <p>
                                    Whether your child loves fairy tales, space adventures,
                                    mysteries, or historical quests, our diverse library of
                                    AI-generated stories covers a wide range of genres. We
                                    guarantee they'll find tales that match their wildest
                                    imaginations.
                                </p>
                            </ScrollAnimation>
                        </div>
                        <div className="col-4 main-features-col">
                            <ScrollAnimation duration={0.3}
                                animateIn="animate__fadeInRight"
                                className="main-features-data"
                            >
                                <h4>Parental Controls and Progress Tracking</h4>
                                <p>
                                    We prioritize safety and provide parents with peace of mind.
                                    Our app comes with comprehensive parental controls, allowing
                                    you to manage your child's experience.
                                </p>
                            </ScrollAnimation>
                            <ScrollAnimation duration={0.3}
                                animateIn="animate__fadeInRight"
                                className="main-features-data"
                            >
                                <h4>Educational and Inspiring Content</h4>
                                <p>
                                    While your child is lost in exciting adventures, KidLit
                                    Katalyst's stories also pack educational value. They'll learn
                                    about teamwork, empathy, problem-solving, and more, all while
                                    having a blast.
                                </p>
                            </ScrollAnimation>
                        </div>
                    </div>
                </div>
            </section>

            <section className="main-benefits p-0">
                <div className="container overflow-hidden">
                    <ScrollAnimation duration={0.3}
                        animateIn="animate__fadeInDown"
                        className="common-title text-center"
                    >
                        <span className="badge-title">Benefits</span>
                        <h2 className="title-box">The Wonders of Imagination</h2>
                        <p>
                            Audio story narration for kids offers numerous benefits that can
                            enhance their development and overall well-being.
                        </p>
                    </ScrollAnimation>
                    <div className="row align-items-center">
                        <div className="col-6">
                            <ScrollAnimation duration={0.3} animateIn="fadeIn">
                                <BenefitsSvg />
                            </ScrollAnimation>
                        </div>
                        <div className="col-6">
                            <ScrollAnimation duration={0.3} animateIn="animate__fadeInRight">
                                <ul>
                                    <li>
                                        <h5>Improved Listening Skills</h5>
                                        <p>
                                            Listening to audio stories helps children develop their
                                            listening skills, attention span, and concentration as
                                            they follow the narrative.
                                        </p>
                                    </li>
                                    <li>
                                        <h5>Cognitive Development</h5>
                                        <p>
                                            Audio storytelling stimulates cognitive functions,
                                            including memory, reasoning, and problem-solving
                                            abilities.
                                        </p>
                                    </li>
                                    <li>
                                        <h5>Enhanced Imagination</h5>
                                        <p>
                                            By listening to the story without visual aids, children
                                            are encouraged to create mental images and develop their
                                            imaginative abilities.
                                        </p>
                                    </li>
                                    <li>
                                        <h5>Stress Reduction</h5>
                                        <p>
                                            Listening to soothing stories can be calming, reducing
                                            stress and anxiety, especially during bedtime routines.
                                        </p>
                                    </li>
                                </ul>
                            </ScrollAnimation>
                        </div>
                    </div>
                </div>
            </section>

            <section className="main-benefits-second">
                <div className="container">
                    <div className="main-benefits-second-inner overflow-hidden">
                        <ul>
                            <li>
                                <ScrollAnimation duration={0.3} animateIn="animate__fadeInLeft">
                                    <span>01.</span>
                                    <h5>Emotional Intelligence</h5>
                                    <p>
                                        Engaging with characters' emotions and experiences in
                                        stories helps kids understand different feelings and
                                        emotions, promoting empathy and emotional intelligence.
                                    </p>
                                </ScrollAnimation>
                            </li>
                            <li>
                                <ScrollAnimation duration={0.3} animateIn="animate__fadeInLeft">
                                    <span>02.</span>
                                    <h5>Accessibility and Convenience</h5>
                                    <p>
                                        Audio stories can be accessed easily through various
                                        devices, making them convenient for travel, relaxation, or
                                        on-the-go learning.
                                    </p>
                                </ScrollAnimation>
                            </li>
                            <li>
                                <ScrollAnimation duration={0.3} animateIn="animate__fadeInRight">
                                    <span>03.</span>
                                    <h5>Multicultural Exposure</h5>
                                    <p>
                                        Audio stories often feature diverse characters and settings,
                                        exposing children to different cultures and promoting
                                        inclusivity.
                                    </p>
                                </ScrollAnimation>
                            </li>
                            <li>
                                <ScrollAnimation duration={0.3} animateIn="animate__fadeInRight">
                                    <span>04.</span>
                                    <h5>Language Development</h5>
                                    <p>
                                        Exposure to diverse vocabulary and sentence structures in
                                        stories aids in language acquisition and fosters better
                                        communication skills.
                                    </p>
                                </ScrollAnimation>
                            </li>
                            <li>
                                <ScrollAnimation duration={0.3} animateIn="animate__fadeInLeft">
                                    <span>05.</span>
                                    <h5>Multicultural Exposure</h5>
                                    <p>
                                        Audio stories often feature diverse characters and settings,
                                        exposing children to different cultures and promoting
                                        inclusivity.
                                    </p>
                                </ScrollAnimation>
                            </li>
                            <li>
                                <ScrollAnimation duration={0.3} animateIn="animate__fadeInLeft">
                                    <span>06.</span>
                                    <h5>Screen-Free Entertainment</h5>
                                    <p>
                                        Audio storytelling offers screen-free entertainment,
                                        reducing screen time and its potential negative effects.
                                    </p>
                                </ScrollAnimation>
                            </li>
                            <li>
                                <ScrollAnimation duration={0.3} animateIn="animate__fadeInRight">
                                    <span>07.</span>
                                    <h5>Family Bonding</h5>
                                    <p>
                                        Listening to audio stories together as a family can create
                                        special bonding moments and encourage discussions about the
                                        stories and themes.
                                    </p>
                                </ScrollAnimation>
                            </li>
                            <li>
                                <ScrollAnimation duration={0.3} animateIn="animate__fadeInRight">
                                    <span>08.</span>
                                    <h5>Promotes Creativity</h5>
                                    <p>
                                        Kids can recreate and expand upon the stories they hear,
                                        leading to imaginative play and creative expression.
                                    </p>
                                </ScrollAnimation>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="main-benefits-third p-0">
                <div className="container">
                    <div className="main-benefits-third-inner">
                        <ScrollAnimation duration={0.3}
                            animateIn="animate__fadeIn"
                            className="content-box"
                        >
                            <h3>
                                Join KidLit Katalyst today and witness the magic of personalized
                                stories that will captivate, educate, and inspire your child's
                                literary journey. Let us ignite their love for boundless
                                imagination in the world of storytelling!
                            </h3>
                            <Formik initialValues={{ email: "" }} onSubmit={handleSubmit}>
                                {({ handleSubmit, values }) => (
                                    <Form onSubmit={handleSubmit}>
                                        <div className="form-group">
                                            <Field
                                                type="email"
                                                id="email"
                                                className="form-control"
                                                placeholder="Enter your email address"
                                                name="email"
                                            />

                                            <button
                                                type="submit"
                                                className="btn btn-primary"
                                                title="Subscribe"
                                            >
                                                Subscribe
                                            </button>
                                        </div>
                                        {validator.current.message(
                                            __t("validations.auth.email"),
                                            values.email,
                                            "required",
                                            {
                                                messages: {
                                                    required: __t("validations.auth.email"),
                                                },
                                            }
                                        )}
                                    </Form>
                                )}
                            </Formik>
                        </ScrollAnimation>
                        <div className="main-benefits-inner-shapes">
                            <img src={Roundshape} alt="shape" />
                            <img src={Dropshape} alt="shape" />
                            <img src={Dropshape2} alt="shape" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="main-benefits-last">
                <div className="container">
                    <div className="row">
                        <div className="col-6 overflow-hidden">
                            <ul>
                                <ScrollAnimation duration={0.3} animateIn="animate__fadeInLeft">
                                    <li>
                                        <h5>Improves Comprehension</h5>
                                        <p>
                                            Following the narrative through listening can improve
                                            comprehension skills, especially for children who might
                                            struggle with reading.
                                        </p>
                                    </li>
                                    <li>
                                        <h5>Encourages Independence</h5>
                                        <p>
                                            Children can listen to audio stories on their own,
                                            fostering a sense of independence and self-reliance.
                                        </p>
                                    </li>
                                    <li>
                                        <h5>Supports Visual Impairments</h5>
                                        <p>
                                            Audio storytelling is an excellent option for visually
                                            impaired children, providing them with access to rich
                                            narratives and literary experiences.
                                        </p>
                                    </li>
                                </ScrollAnimation>
                            </ul>
                        </div>
                        <div className="col-6">
                            <ScrollAnimation duration={0.3} animateIn="fadeIn">
                                <Benefits2Svg />
                            </ScrollAnimation>
                        </div>
                    </div>
                </div>
            </section>

            <section className="call-to-action">
                <div className="container">
                    <div className="call-to-action-inner">
                        <ScrollAnimation duration={0.3}
                            animateIn="animate__fadeIn"
                            className="content-box"
                        >
                            <h3>Discover Your Child's Favorite Story!</h3>
                            <p>
                            Explore a world of storytelling with us and unlock the magic of your child's favorite tale. KidLit Katalyst helps you uncover the stories that captivate your little one's heart and imagination. Let's embark on a journey to find their perfect story!
                            </p>
                            <span>
                                <a href="javascript:void(0);" title="Play Store">
                                    <img src={Playstore} alt="Play store" />
                                </a>
                                <a href="javascript:void(0);" title="Apple store">
                                    <img src={Applestore} alt="Apple store" />
                                </a>
                            </span>
                        </ScrollAnimation>
                        <img src={CalltoactionMockup} alt="Mobile Mockup" />
                    </div>
                </div>
                <div className="call-to-action-shapes">
                    {/* <img src={BirdShape} alt='shape' /> */}
                    <BirdSvg />
                    <img src={PlaneShape} alt="shape" />
                </div>
            </section>
        </>
    );
}
const mapDispatchToProps = (dispatch) => {
    return {
        SendEmailRequest: (data) => dispatch(sendemailRequest(data)),
    };
};

export default connect(null, mapDispatchToProps)(Features);
