import {
  FORGET_PASSWORD_REQUEST,
  FORGET_PASSWORD_SUCCESS,
  NEW_PASSWORD_REQUEST,
  NEW_PASSWORD_SUCCESS,
  OTP_FAILURE,
  OTP_REQUEST,
  OTP_SUCCESS,
} from "./type";

const initialState = {
  user: null,
  otp: null,
  activation: null,
  resend: null,
  loading: false,
};

const forgetReducer = (state = initialState, action) => {
  switch (action.type) {
    case FORGET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        activation: null,
        user: action.payload,
        loading: false,
      };
    case NEW_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        activation: action.payload,
      };
    case OTP_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case OTP_SUCCESS:
      return {
        ...state,
        otp: action.payload,
        loading: false,
      };
    case OTP_FAILURE:
      return {
        ...state,
        otpfail: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default forgetReducer;
