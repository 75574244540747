import {
    ADD_SPICE_UP_REQUEST,
    ADD_SPICE_UP_SUCCESS,
    DELETE_SPICE_UP_REQUEST,
    DELETE_SPICE_UP_SUCCESS,
    EDIT_SPICE_UP_REQUEST,
    EDIT_SPICE_UP_SUCCESS,

    GET_SPICE_UP_REQUEST,
    GET_SPICE_UP_SUCCESS,
} from "./type";

export const getSpiceUpRequest = (Data) => ({
    type: GET_SPICE_UP_REQUEST,
    payload: Data,
});
export const getSpiceUpSuccess = (Data) => ({
    type: GET_SPICE_UP_SUCCESS,
    payload: Data,
});
export const addSpiceUpRequest = (data) => ({
    type: ADD_SPICE_UP_REQUEST,
    payload: data,
});
export const addSpiceUpSuccess = (data) => ({
    type: ADD_SPICE_UP_SUCCESS,
    payload: data,
});
export const deleteSpiceUpRequest = (placeId) => ({
    type: DELETE_SPICE_UP_REQUEST,
    payload: placeId,
});
export const deleteSpiceUpSuccess = (placeId) => ({
    type: DELETE_SPICE_UP_SUCCESS,
    payload: placeId,
});
export const editSpiceUpRequest = (data) => ({
    type: EDIT_SPICE_UP_REQUEST,
    payload: data,
});
export const editSpiceUpSuccess = (updateData) => ({
    type: EDIT_SPICE_UP_SUCCESS,
    payload: updateData,
});
