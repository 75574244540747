import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import {
  ADD_SCENE_REQUEST,
  DELETE_SCENE_REQUEST,
  EDIT_SCENE_REQUEST,
  GET_SCENE_REQUEST,
} from "./type";
import {
  getSceneDataFailure,
  getSceneDataSuccess,
  addSceneDataSuccess,
  deleteSceneDataSuccess,
  getSceneDataRequest,
  editSceneDataSuccess,
} from "./action";
import { DANGER, SUCCESS } from "../../constant/commonConstants";
import { invokeApi } from "../../../enverment/axiosHelper";
import { ApiResponseMessage } from "../../../helper/tosterHelpers";
import { handleApiCallException } from "../../shared/action";
function* getSceneDataSaga(action) {
  let res = "";
  try {
    res = yield call(invokeApi, "post", "/masterSceneList", action.payload);
    if (res.status) {
      yield put(getSceneDataSuccess(res.data));
    } else {
      yield put(getSceneDataSuccess());
    }
    // ApiResponseMessage(res.message, res.status ? SUCCESS : DANGER);
  } catch (error) {
    yield put(getSceneDataFailure(error.message));
  }
}

function* addSceneDataSaga(action) {
  try {
    const response = yield call(
      invokeApi,
      "post",
      "/addMasterScene",
      action.payload
    );
    if (response.status) {
      yield put(addSceneDataSuccess(response.data));
      yield put(getSceneDataRequest({}));
    } else {
      yield put(addSceneDataSuccess());
    }
    ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER);
  } catch (error) {
    yield put(handleApiCallException(error));
  }
}

function* removeSceneData(action) {
  try {
    const response = yield call(invokeApi, "post", "/deleteMasterScene", {
      id: action.payload.id,
      isActive: action.payload.isActive,
    });
    if (response.status) {
      yield put(
        deleteSceneDataSuccess({
          id: action.payload.id,
          isActive: action.payload.isActive,
        })
      );
      yield put(
        getSceneDataRequest({
          page: action.payload.currentPage,
          limit: 10,
        })
      );
    } else {
      yield put(deleteSceneDataSuccess());
    }
    ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER);
  } catch (error) {
    yield put(handleApiCallException(error));
  }
}
function* updateSceneData(action) {
  const { id } = action.payload;
  try {
    const response = yield call(
      invokeApi,
      "put",
      `/updateMasterScene/${id}`,
      action.payload
    );

    if (response.status) {
      yield put(editSceneDataSuccess(response.data));
      yield put(getSceneDataRequest({}));
    } else {
      yield put(editSceneDataSuccess());
    }
    ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER);
  } catch (error) {
    yield put(handleApiCallException(error));
  }
}
function* watchSceneDataRequest() {
  yield takeLatest(GET_SCENE_REQUEST, getSceneDataSaga);
  yield takeEvery(ADD_SCENE_REQUEST, addSceneDataSaga);
  yield takeEvery(DELETE_SCENE_REQUEST, removeSceneData);
  yield takeEvery(EDIT_SCENE_REQUEST, updateSceneData);
}
function* SceneSaga() {
  yield all([fork(watchSceneDataRequest)]);
}

export default SceneSaga;
