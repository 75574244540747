import { ADD_SCENE_REQUEST, ADD_SCENE_SUCCESS, DELETE_SCENE_REQUEST, DELETE_SCENE_SUCCESS, EDIT_SCENE_REQUEST, EDIT_SCENE_SUCCESS, GET_SCENE_FAILURE, GET_SCENE_REQUEST, GET_SCENE_SUCCESS } from "./type";


export const getSceneDataRequest = (Data) => (
    {
        type: GET_SCENE_REQUEST,
        payload: Data

    }
);
export const getSceneDataSuccess = (Data) => ({
    type: GET_SCENE_SUCCESS,
    payload: Data

});

export const getSceneDataFailure = (error) => ({
    type: GET_SCENE_FAILURE,
    payload: error,
});
export const addSceneDataRequest = (data) => ({
    type: ADD_SCENE_REQUEST,
    payload: data
}
);
export const addSceneDataSuccess = (data) => ({
    type: ADD_SCENE_SUCCESS,
    payload: data

});
export const deleteSceneDataRequest = (placeId) => (
    {
        type: DELETE_SCENE_REQUEST,
        payload: placeId
    }
);
export const deleteSceneDataSuccess = (placeId) => ({
    type: DELETE_SCENE_SUCCESS,
    payload: placeId


});
export const editSceneDataRequest = (data) => (
    {
        type: EDIT_SCENE_REQUEST,
        payload: data
    }
);
export const editSceneDataSuccess = (updateData) => ({
    type: EDIT_SCENE_SUCCESS,
    payload: updateData
});