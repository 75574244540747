import {
  GET_PAYMENT_HISTORY_DETAILS_REQUEST,
  GET_PAYMENT_HISTORY_DETAILS_SUCCESS,
  GET_PAYMENT_HISTORY_DETAILS_FAILURE,
} from "./type";

export const getPaymentHistoryDetailsRequest = (creads) => ({
  type: GET_PAYMENT_HISTORY_DETAILS_REQUEST,
  payload: creads,
});
export const getPaymentHistoryDetailsSuccess = (data) => ({
  type: GET_PAYMENT_HISTORY_DETAILS_SUCCESS,
  payload: data,
});
export const getPaymentHistoryDetailsFailure = (data) => ({
  type: GET_PAYMENT_HISTORY_DETAILS_FAILURE,
  payload: data,
});
