import React, { useState, useEffect } from "react";
import PlanSelection from "./PlanSelection";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
function Subscription({ SubscriptionkeyData }) {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const { userId } = useParams();
  useEffect(() => {
    if (SubscriptionkeyData !== undefined) {
      const newDomain = SubscriptionkeyData;
      window.location.href = newDomain;
      window.open(newDomain);
    }
  }, [SubscriptionkeyData !== undefined]);
  return (
    <>
      <PlanSelection setSelectedPlan={setSelectedPlan} userId={userId} />
    </>
  );
}

const mapStateToProps = ({ Subscriptionkey }) => {
  return {
    SubscriptionkeyData: Subscriptionkey.subscription?.url,
  };
};

export default connect(mapStateToProps)(Subscription);
