import React from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import TableHeader from "../../components/Table/TableHeader";

export const STRIPE = "Stripe";
export const INAPP = "InApp";

export default function PacketHistoryCard({
  tabActive,
  setTabActive,
  setSearchData,
  setCurrentPage,
  setDataPerPage,
  children,
}) {
  const initializeState = () => {
    setSearchData(null);
    setCurrentPage(1);
    setDataPerPage(10);
  };

  return (
    <>
      <TableHeader />
      <Nav className="nav-underline Plans_tab">
        <NavItem>
          <NavLink
            className={tabActive === STRIPE ? "active" : " "}
            onClick={() => {
              initializeState();
              setTabActive(STRIPE);
            }}
          >
            <span>{STRIPE}</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={tabActive === INAPP ? "active" : " "}
            onClick={() => {
              initializeState();
              setTabActive(INAPP);
            }}
          >
            <span>{INAPP}</span>
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent className="card Plans_tabcontent" activeTab={tabActive}>
        <TabPane tabId={STRIPE}>{children}</TabPane>
        <TabPane tabId={INAPP}>{children}</TabPane>
      </TabContent>
    </>
  );
}
