import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { GET_SUBSCRIPTION_PLAN_REQUEST } from "./type";
import { invokeApi } from "../../../enverment/axiosHelper";
import { getSubscriptionSuccess } from "./action";
import { handleApiCallException } from "../../shared/action";
import { DANGER } from "../../constant/commonConstants";
import { ApiResponseMessage } from "../../../helper/tosterHelpers";
function* fetchPlans({ payload: data }) {
    try {
        const response = yield call(invokeApi, "post", `/createOrder`, data);
        if (response.status) {

            yield put(getSubscriptionSuccess(response.data));

        } else {
            ApiResponseMessage(response.message, DANGER);
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}

function* watchSubscriptionSagaRequest() {
    yield takeLatest(GET_SUBSCRIPTION_PLAN_REQUEST, fetchPlans);
}

function* subscriptionSaga() {
    yield all([fork(watchSubscriptionSagaRequest)]);
}

export default subscriptionSaga;
