import { USER_LOGS_REQUEST, USER_LOGS_SUCCESS } from "./type";

export const getUserLogsRequest = (data) => {
    return {
        type: USER_LOGS_REQUEST,
        payload: data,
    };
};

export const getUserLogsSuccess = (data) => {
    return {
        type: USER_LOGS_SUCCESS,
        payload: data,
    };
};