import { __t } from "../i18n/translator";
import { DANGER } from "../redux/constant/commonConstants";
import { ApiResponseMessage } from "./tosterHelpers";

export const handleFileUpload = (
    file,
    FileType,
    setFieldValue,
    setImgUpload,
    setImageName,
    setUploadProgress
) => {
    if (!file || !file.type.startsWith("image/")) {
        ApiResponseMessage(__t("validations.common.invalid_image"), DANGER);
        return false;
    } else {
        setFieldValue(FileType, file);
        let images = [];
        const selectedFiles = [file];
        selectedFiles.forEach((d) => {
            images.push(URL.createObjectURL(d));
        });
        setImgUpload(images);
        const imageName = file.name;
        setImageName(imageName);
        const formData = new FormData();
        formData.append("file", file);

        let count = 20;

        const startTime = new Date().getTime();

        const interval = setInterval(() => {
            const currentTime = new Date().getTime();
            const elapsedTime = currentTime - startTime;

            if (count === 100) {
                clearInterval(interval);
            } else if (elapsedTime >= 100) {
                setUploadProgress(count + 1);
                count += 1;
            }
        }, 4);
    }
};
