import { __t } from "../i18n/translator";
import { DANGER } from "../redux/constant/commonConstants";
import { ApiResponseMessage } from "./tosterHelpers";

export const handleMultipleFileUpload = (
  files,
  FileType,
  setFieldValue,
  setImgUpload,
  setAllImages,
  allImages
) => {
  const invalidFiles = files.filter((file) => !file.type.startsWith("image/"));
  if (invalidFiles.length > 0) {
    ApiResponseMessage(__t("validations.common.invalid_image"), DANGER);
    return false;
  }

  if (files.length > 4 || allImages.length >= 4) {
    ApiResponseMessage(__t("validations.common.max_image_limit"), DANGER);
    return false;
  }

  setAllImages((prevfiles) => [...prevfiles, ...files]);
  setFieldValue(FileType, allImages);

  let images = [];
  const loadImage = (file, callback) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const blobURL = URL.createObjectURL(file);
      const img = new Image();
      img.src = blobURL;
      img.onload = () => {
        callback({
          name: file.name,
          imgUrl: blobURL,
          device:
            (img.width === 2665 && "landscape") ||
            (img.width === 2254 && img.height === 2485 && "tablet") ||
            (img.width === 1738 && "mobile") ||
            (img.width === 2260 && "common") ||
            "other",
        });
      };
    };
    reader.readAsDataURL(file);
  };

  files.forEach((file) => {
    loadImage(file, (imageDetails) => {
      images.push(imageDetails);
      setImgUpload((prevImages) => [...prevImages, imageDetails]);
    });
  });
};

export const getImageWidth = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const img = new Image();
      img.onload = () => {
        resolve(
          (img.width === 2665 && "landscape") ||
            (img.width === 2254 && img.height === 2485 && "tablet") ||
            (img.width === 1738 && "mobile") ||
            (img.width === 2254 && img.height === 2865 && "common") ||
            "other"
        );
      };
      img.onerror = reject;
      img.src = event.target.result;
    };

    reader?.readAsDataURL(file);
  });
};
