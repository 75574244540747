import {
    ADD_ITEMS_PLAN_REQUEST,
    ADD_ITEMS_PLAN_SUCCESS,
    EDIT_ITEMS_PLAN_REQUEST,
    EDIT_ITEMS_PLAN_SUCCESS,
    GET_ITEMS_PLAN_REQUEST,
    GET_ITEMS_PLAN_SUCCESS,
    REMOVE_ITEMS_PLAN_REQUEST,
    REMOVE_ITEMS_PLAN_SUCCESS,
} from "./type";

const initialState = {
    items: null,
    addItems: null,
    editItems: null,
    deleteItems: null,
};
const itemsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ITEMS_PLAN_REQUEST:
            return {
                ...state,
                items: action.payload,
            };
        case GET_ITEMS_PLAN_SUCCESS:
            return {
                ...state,
                items: action.payload,
            };
        case ADD_ITEMS_PLAN_REQUEST:
            return {
                ...state,
                addItems: action.payload,
            };
        case ADD_ITEMS_PLAN_SUCCESS:
            return {
                ...state,
                addItems: action.payload,
            };
        case EDIT_ITEMS_PLAN_REQUEST:
            return {
                ...state,
                editItems: action.payload,
            };
        case EDIT_ITEMS_PLAN_SUCCESS:
            return {
                ...state,
                editItems: action.payload,
            };
        case REMOVE_ITEMS_PLAN_REQUEST:
            return {
                ...state,
                deleteItems: action.payload,
            };
        case REMOVE_ITEMS_PLAN_SUCCESS:
            return {
                ...state,
                deleteItems: action.payload,
            };
        default:
            return { ...state };
    }
};
export default itemsReducer;
