import moment from "moment";
import "moment-timezone"
export const getDateTimeFromDateTimeString = (value) => {
  return moment(value).format("DD-MM-YYYY");
};

export const getDateTimezone = (value) => {
  const ahmedabadDateTime = moment(value)
    .tz("Asia/Kolkata")
    .format("HH:mm A");
  const hour = moment(value).tz('Asia/Kolkata').format('H');
  const hourIn12HourFormat = (parseInt(hour, 10) % 12) || 12;

  return ahmedabadDateTime.replace(hour, hourIn12HourFormat);
};
