import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { USER_LOGS_REQUEST } from "./type";
import { handleApiCallException } from "../../shared/action";
import { invokeApi } from "../../../enverment/axiosHelper";
import { getUserLogsSuccess } from "./action";

function* getUserLogs({ payload: data }) {

    try {
        const response = yield call(invokeApi, "post", "/loginActivity", data)
        if (response.status) {
            yield put(getUserLogsSuccess(response.data))
        } else {
            yield put(getUserLogsSuccess([]))
            handleApiCallException(response.message)
        }
    } catch (error) {
        handleApiCallException(error)
    }
}
export function* UserlogsRequest() {
    yield takeLatest(USER_LOGS_REQUEST, getUserLogs);
}
function* UserlogsSaga() {
    yield all([fork(UserlogsRequest)]);
}
export default UserlogsSaga;
