import { ADD_THEME_REQUEST, ADD_THEME_SUCCESS, DELETE_THEME_REQUEST, DELETE_THEME_SUCCESS, EDIT_THEME_REQUEST, EDIT_THEME_SUCCESS, GET_THEME_FAILURE, GET_THEME_REQUEST, GET_THEME_SUCCESS } from "./type";


export const getThemeRequest = (Data) => (
    {
        type: GET_THEME_REQUEST,
        payload: Data

    }
);
export const getThemeSuccess = (Data) => (
    {
        type: GET_THEME_SUCCESS,
        payload: Data

    });

export const getThemeFailure = (error) => ({
    type: GET_THEME_FAILURE,
    payload: error,
});
export const addThemeRequest = (data) => ({
    type: ADD_THEME_REQUEST,
    payload: data
}
);
export const addThemeSuccess = (data) => ({
    type: ADD_THEME_SUCCESS,
    payload: { data }

});
export const deleteThemeRequest = (placeId) => (
    {
        type: DELETE_THEME_REQUEST,
        payload: placeId
    }
);
export const deleteThemeSuccess = (placeId) => ({
    type: DELETE_THEME_SUCCESS,
    payload: placeId


});
export const editThemeRequest = (data) => (
    {
        type: EDIT_THEME_REQUEST,
        payload: data
    }
);
export const editThemeSuccess = (updateData) => ({
    type: EDIT_THEME_SUCCESS,
    payload: updateData
});