import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Card } from "reactstrap";
import CustomisedModal from "../../../components/Modal/CustomisedModal";
import AddTheme from "./AddTheme";
import {
  deleteThemeRequest,
  getThemeRequest,
} from "../../../redux/master/theme/action";
import {
  commonConfirmBox,
  commonMessangerBox,
} from "../../../helper/messageHelper";
import { EditSvg } from "../../../components/Common/CommonSvg";
import TableHeader from "../../../components/Table/TableHeader";

function Theme(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    props.getList();
  }, []);

  const handleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleEditClick = (row) => {
    if (row?.isActive) {
      setIsModalOpen(!isModalOpen);
      setIsEdit(row);
    } else {
      commonMessangerBox("Before updating theme you have to activate status");
    }
  };

  const handleDeleteClick = async (row) => {
    const response = await commonConfirmBox(
      row.isActive
        ? "Do you want to inactive this theme?"
        : "Do you want to active this theme?"
    );
    if (response) {
      props.removeList({
        id: row.id,
        isActive: !row.isActive,
      });
      props.getList();
    }
  };

  return (
    <>
      <TableHeader href={handleModal} buttonName={"Create Theme"} />
      <div className="master-card-list theme-card-list">
        {props.getData?.map((d, index) => {
          console.log(d.theme.MobileTheme, "d.theme.MobileTheme")
          return (
            <Card key={index}>
              <img src={d.theme.MobileTheme} alt="theme" />
              <div className="card-data">
                <div className="switch">
                  <input
                    type="checkbox"
                    onChange={() => handleDeleteClick(d)}
                    checked={d?.isActive ? true : false}
                  />
                  <label
                    className="switch-check"
                    onClick={() => {
                      handleDeleteClick(d);
                    }}
                  >
                    <div className="switch-btn"></div>
                    <div className="active-switch">Active</div>
                    <div className="deactive-switch">Inactive</div>
                  </label>
                </div>
                <h5>
                  <span>Theme Type :</span> {d.themeType}
                </h5>
                <div className="overlay">
                  {/* <a
                    className="btn btn-link edit-btn"
                    title="Edit"
                    onClick={() => handleEditClick(d)}
                  >
                    <EditSvg />
                  </a> */}
                </div>
              </div>
            </Card>
          );
        })}
      </div>

      {isModalOpen && (
        <CustomisedModal
          CustomisedModal
          isModalOpen={isModalOpen}
          modalName={!isEdit ? "Add Theme" : "Edit Theme"}
          onModalDismiss={() => {
            handleModal();
            setIsEdit(false);
          }}
        >
          <AddTheme
            onModalDismiss={() => {
              handleModal();
              setIsEdit(false);
            }}
            data={!isEdit ? null : isEdit}
          />
        </CustomisedModal>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  let DataList = [];
  let totalRecords = 0;
  if (state?.theme?.getTheme?.themeList?.length > 0) {
    DataList = state.theme.getTheme.themeList;
    totalRecords = state?.theme?.getTheme?.totalRecords;
  }

  return {
    getData: DataList,
    totalRecords: totalRecords,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getList: (data) => {
      dispatch(getThemeRequest(data));
    },
    removeList: (data) => {
      dispatch(deleteThemeRequest(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Theme);
