import { USER_LOGS_REQUEST, USER_LOGS_SUCCESS } from "./type";

const initialState = {
    getLogs: null,
};
const userLogsReducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_LOGS_REQUEST:
            return {
                ...state,
                getLogs: action.payload,
            };
        case USER_LOGS_SUCCESS:
            return {
                ...state,
                getLogs: action.payload,
            };
        default:
            return { ...state };
    }
};
export default userLogsReducer