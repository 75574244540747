import {
  ACTIVE_CHILD_REQUEST,
  ACTIVE_CHILD_SUCCESS,
  GET_CHILD_REQUEST,
  GET_CHILD_SUCCESS,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  SELECT_FAMILY_SUCCESS,
} from "./type";

const initialState = {
  data: null,
  remove: null,
  loading: false,
  child: null,
  selectFamily: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_REQUEST:
      return {
        ...state,
        data: action.payload,
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };
    case GET_CHILD_REQUEST:
      return {
        ...state,
        child: action.payload,
      };
    case GET_CHILD_SUCCESS:
      return {
        ...state,
        child: action.payload,
      };
    case ACTIVE_CHILD_REQUEST:
      return {
        ...state,
        remove: action.payload,
      };
    case ACTIVE_CHILD_SUCCESS:
      return {
        ...state,
        remove: action.payload,
      };
    case SELECT_FAMILY_SUCCESS:
      return {
        ...state,
        selectFamily: action.payload,
      };

    default:
      return { ...state };
  }
};

export default userReducer;
