import { GET_SUBSCRIPTION_PLAN_REQUEST, GET_SUBSCRIPTION_PLAN_SUCCESS } from "./type";

export const getSubscriptionRequest = (data) => {
    return {
        type: GET_SUBSCRIPTION_PLAN_REQUEST,
        payload: data
    };
};
export const getSubscriptionSuccess = (subPlans) => {
    return {
        type: GET_SUBSCRIPTION_PLAN_SUCCESS,
        payload: subPlans,
    };
};
