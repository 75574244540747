import {
  ADD_API_MANGEMENT_DETAILS_REQUEST,
  ADD_API_MANGEMENT_DETAILS_SUCCESS,
  GET_API_MANAGEMENT_DETAILS_REQUEST,
  GET_API_MANAGEMENT_DETAILS_SUCCESS,
  UPDATE_API_MANGEMENT_DETAILS_REQUEST,
  UPDATE_API_MANGEMENT_DETAILS_SUCCESS,
} from "./type";

export const getApiManagementDetailsRequest = (creads) => ({
  type: GET_API_MANAGEMENT_DETAILS_REQUEST,
  payload: creads,
});
export const getApiManagementDetailsSuccess = (data) => ({
  type: GET_API_MANAGEMENT_DETAILS_SUCCESS,
  payload: data,
});

export const addApiManagementDetailsRequest = (creads) => ({
  type: ADD_API_MANGEMENT_DETAILS_REQUEST,
  payload: creads,
});
export const addApiManagementDetailsSuccess = (data) => ({
  type: ADD_API_MANGEMENT_DETAILS_SUCCESS,
  payload: data,
});

export const updateApiManagementDetailsRequest = (creads) => ({
  type: UPDATE_API_MANGEMENT_DETAILS_REQUEST,
  payload: creads,
});
export const updateApiManagementDetailsSuccess = (data) => ({
  type: UPDATE_API_MANGEMENT_DETAILS_SUCCESS,
  payload: data,
});
