import React, { useEffect, useState } from "react";
import { getUserLogsRequest } from "../../../redux/user/userStats/action";
import { connect } from "react-redux";
import CommonTable from "../../../components/Table/CommonTable";
import { getDateTimeFromDateTimeString, getDateTimezone } from "../../../helper/DataTimeHelpers";
import TableHeader from "../../../components/Table/TableHeader";

function UserStats(props) {
    const [currentPage, setCurrentPage] = useState(1);
    const [dataPerPage, setDataPerPage] = useState(10);
    useEffect(() => {
        setCurrentPage(1);
    }, [dataPerPage]);
    useEffect(() => {
        props?.getUserLogs({
            limit: dataPerPage,
            page: currentPage,
        });
    }, [currentPage, dataPerPage]);
    const columns = [
        {
            dataField: "name",
            text: "Name",
            formatter: (cell, row) => <>{row.name}</>,
            sort: true,
        }, {
            dataField: "ipAddress",
            text: "IP Address",
            formatter: (cell, row) => <>{row.ipAddress}</>,
            sort: true,
        },
        {
            dataField: "loginType",
            text: "Login Type",
            formatter: (cell, row) => <>{row.loginType}</>,
            sort: true,
            headerClasses: "date-time-box",
        },
        {
            dataField: "lastLogin",
            text: " Date & Time",
            formatter: (cell, row) => <>{getDateTimeFromDateTimeString(row.lastLogin)} : {getDateTimezone(row.lastLogin)}</>,
            sort: true,
            headerClasses: "date-time-box",
        },
        {
            dataField: "status",
            text: "Status",
            formatter: (cell, row) => <>
                {/* badge-danger for faild and  badge-success to successful*/}
                <span className={row.status === "successful" ? "badge badge-success text-capitalize" : "badge badge-danger text-capitalize"}>
                    {row.status}
                </span>
            </>,
            sort: true,
            headerClasses: "status-box",
        },
    ];
    return (
        <>
            <TableHeader />
            <CommonTable
                data={props.logsData.activities ? props.logsData.activities : []}
                columns={columns}
                setCurrentPage={setCurrentPage}
                dataPerPage={dataPerPage}
                setDataPerPage={setDataPerPage}
                currentPage={currentPage}
                pagination={true}
                totalRecords={props.totalRecords}
                updateData={props.getList}
            />
        </>
    );
}
const mapStateToProps = (state) => {
    let logsData = [];
    let totalRecords = [];
    if (state?.userLogs?.getLogs?.loginStatistics?.activities?.length > 0) {
        return {
            logsData: state.userLogs.getLogs.loginStatistics,
            totalRecords: state.userLogs.getLogs.totalRecords,
        };
    }
    return {
        logsData: logsData,
        totalRecords: totalRecords,
    };
};
const mapDispatchToProps = (dispatch) => {
    return { getUserLogs: (data) => dispatch(getUserLogsRequest(data)) };
};
export default connect(mapStateToProps, mapDispatchToProps)(UserStats);
