import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getPlansRequest,
  removePlansRequest,
} from "../../../redux/subscription/plans/action";
import { Card, Col, Row } from "reactstrap";
import AddPlans from "./AddPlans";
import {
  commonConfirmBox,
  commonMessangerBox,
} from "../../../helper/messageHelper";
import { AddSvg, EditSvg } from "../../../components/Common/CommonSvg";

function Plans(props) {
  const { getList, getData, setIsAddPlan, isAddPlan } = props;
  const [GetList, setGetList] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  useEffect(() => {
    getList();
  }, []);
  useEffect(() => {
    if (getData) {
      setGetList(getData);
    }
  }, [getData]);

  const handleEditClick = (row) => {
    if (row?.is_active) {
      setIsAddPlan(true);
      setIsEdit(row);
    } else {
      commonMessangerBox("Before updating plan you have to activate status");
    }
  };

  const handleDeleteClick = async (row) => {
    const response = await commonConfirmBox(
      row.is_active
        ? "Do you want to inactive this plan?"
        : "Do you want to active this plan?"
    );
    if (response) {
      props.removePlan({
        id: row.id,
        isActive: !row.is_active,
      });
    }
  };
  const handleAddPlan = () => {
    setIsAddPlan(true);
  };
  return (
    <>
      <div className="subscription-plans-box">
        <div>
          <Card>
            {isAddPlan ? (
              <div className="plan-form">
                <AddPlans
                  data={isEdit}
                  setIsAddPlan={setIsAddPlan}
                  setIsEdit={setIsEdit}
                />
              </div>
            ) : (
              <div onClick={handleAddPlan} className="add-subscription-box">
                <AddSvg />
                <h3>{!isEdit ? "Create Plans" : "Edit Plans"}</h3>
              </div>
            )}
          </Card>
        </div>
        {GetList &&
          GetList.map((data, index) => {
            return (
              <div>
                <Card key={index}>
                  <div>
                    <h3>{data.plan_name}</h3>
                    <span>₹{data.price}</span>
                    <ul>
                      <li>Add {data.total_character} Character</li>
                      <li>Add {data.total_event} Event</li>
                      <li>Add {data.total_place} Place</li>
                      <li>Add {data.total_scene} Scene</li>
                      <li>Add {data.total_story} Story</li>
                    </ul>
                  </div>
                  <div>
                    <div className="switch">
                      <input
                        type="checkbox"
                        onChange={() => handleDeleteClick(data)}
                        checked={data?.is_active ? true : false}
                      />
                      <label
                        className="switch-check"
                        onClick={() => {
                          handleDeleteClick(data);
                        }}
                      >
                        <div className="switch-btn"></div>
                        <div className="active-switch">Active</div>
                        <div className="deactive-switch">Inactive</div>
                      </label>
                    </div>
                    <a
                      className="btn btn-link edit-btn"
                      title="Edit"
                      onClick={() => handleEditClick(data)}
                    >
                      <EditSvg />
                    </a>
                  </div>
                </Card>
              </div>
            );
          })}
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  let getDataList = [];
  if (state.plans?.plans?.list.length > 0 && state?.plans?.plans?.list) {
    getDataList = state.plans.plans.list;
  }
  return {
    getData: getDataList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getList: () => dispatch(getPlansRequest()),
    removePlan: (data) => dispatch(removePlansRequest(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Plans);
