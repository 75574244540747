import { createSelector } from "reselect";
import { initialState } from "./reducer";

const reducerState = (state) => state.sharedReducer || initialState;

const makeSharedSelectField = (field) =>
  createSelector(reducerState, (sharedState) => sharedState[field]);
const createLoadingSelector = () => (state) => {
  // returns true only when all actions is not loading
  const isAnyRequestInProcess = Object.values(
    state?.sharedReducer?.Requests
  ).includes(true);
  return isAnyRequestInProcess;
};

export { makeSharedSelectField, createLoadingSelector };
