import React, { useEffect } from "react";
import { store } from "../../redux/store";
import { clearStore } from "../../redux/clearstore/action";
import { Outlet, useLocation } from "react-router-dom";
import { createLoadingSelector } from "../../redux/shared/selector";
import { useSelector } from "react-redux";

export default function MainRoute() {
    const location = useLocation()
    const loadingSelector = useSelector(createLoadingSelector());
    useEffect(() => {
        if (location.pathname.split('/')) {
            if (loadingSelector) {
                store.dispatch(clearStore())
            }
        }
    }, [location.pathname]);
    return (
        <>
            <Outlet />
        </>
    );
}
