import {
  FORGET_PASSWORD_FAILURE,
  FORGET_PASSWORD_REQUEST,
  FORGET_PASSWORD_SUCCESS,
  NEW_PASSWORD_REQUEST,
  NEW_PASSWORD_SUCCESS,
  OTP_FAILURE,
  OTP_REQUEST,
  OTP_SUCCESS,
} from "./type";

export const forgetPasswordRequest = (user) => ({
  type: FORGET_PASSWORD_REQUEST,
  payload: user,
});

export const forgetPasswordSuccess = (user) => ({
  type: FORGET_PASSWORD_SUCCESS,
  payload: user,
});

export const forgetPasswordFailure = (error) => ({
  type: FORGET_PASSWORD_FAILURE,
  payload: error,
});

export const otpRequest = (activation) => ({
  type: OTP_REQUEST,
  payload: activation,
});
export const otpSuccess = (activation) => ({
  type: OTP_SUCCESS,
  payload: activation,
});
export const otpFailure = (fail) => ({
  type: OTP_FAILURE,
  payload: fail,
});

export const passwordRequest = (activation) => ({
  type: NEW_PASSWORD_REQUEST,
  payload: activation,
});
export const passwordSuccess = (activation) => ({
  type: NEW_PASSWORD_SUCCESS,
  payload: activation,
});
