import { GET_DASHBOARD_DATA_REQUEST, GET_DASHBOARD_DATA_SUCCESS } from "./type";

export const getDashboardListRequest = (data) => {
  return {
    type: GET_DASHBOARD_DATA_REQUEST,
    payload: data,
  };
};
export const getDashboardListSuccess = (data) => {
  return {
    type: GET_DASHBOARD_DATA_SUCCESS,
    payload: data,
  };
};
