import {
  ACTIVE_CHILD_REQUEST,
  ACTIVE_CHILD_SUCCESS,
  GET_CHILD_REQUEST,
  GET_CHILD_SUCCESS,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  SELECT_FAMILY_REQUEST,
  SELECT_FAMILY_SUCCESS,
} from "./type";

export const getUserRequest = (pageInfo) => ({
  type: GET_USER_REQUEST,
  payload: pageInfo,
});
export const getChildSuccess = (id) => ({
  type: GET_CHILD_SUCCESS,
  payload: id,
});
export const getChildRequest = (id) => ({
  type: GET_CHILD_REQUEST,
  payload: id,
});
export const getUserSuccess = (user) => ({
  type: GET_USER_SUCCESS,
  payload: user,
});

export const removeUserRequest = (pageInfo) => ({
  type: ACTIVE_CHILD_REQUEST,
  payload: pageInfo,
});
export const removeUserSuccess = (user) => ({
  type: ACTIVE_CHILD_SUCCESS,
  payload: user,
});

export const selectFamilyRequest = (data) => ({
  type: SELECT_FAMILY_REQUEST,
  payload: data,
});
export const selectFamilySuccess = (data) => ({
  type: SELECT_FAMILY_SUCCESS,
  payload: data,
});
