// saga.js

import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { invokeApi } from '../../../enverment/axiosHelper';
import { LOGIN_REQUEST } from './type';
import { loginFailure, loginSuccess } from './action';
import { ApiResponseMessage } from '../../../helper/tosterHelpers';
import { DANGER, SUCCESS } from '../../constant/commonConstants';
import { setLoginUserData } from '../../../utils/authUtils';


function* login(action) {
    let res = ""
    try {
        res = yield call(invokeApi, 'post', '/login', action.payload)
        if (res.status) {
            setLoginUserData(res);
            ApiResponseMessage(res.message, res.status ? SUCCESS : DANGER)
            yield put(loginSuccess(res));

        } else {
            ApiResponseMessage(res.message, DANGER);
            yield put(loginFailure(res.message));

        }
    } catch (error) {
        yield put(loginFailure(error.message));
    }
}

function* watchLoginRequest() {
    yield takeLatest(LOGIN_REQUEST, login);
}

function* LoginSaga() {
    yield all([fork(watchLoginRequest)])
}
export default LoginSaga
