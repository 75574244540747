import React, { useState } from "react";
import { AddSvg, SearchSvg } from "../Common/CommonSvg";
import CommonBackButton from "./CommonBackButton";
export default function TableHeader(props) {
  const [inputValue, setInputValue] = useState("");
  const { updateData, isLogModule, isBackBtn } = props;
  return (
    <div className="table-head">
      {isBackBtn == false ? <div></div> : <CommonBackButton />}
      <div>
        {props.searchBar && (
          <div className="search-box">
            <input
              type="search"
              className="form-control"
              placeholder="Search"
              value={inputValue}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  props.setSearchData(inputValue);
                }
              }}
              onChange={(e) => {
                setInputValue(e.target.value);
                if (e.target.value?.length <= 0) {
                  updateData({
                    search: null,
                  });
                  setInputValue(null);
                  props.setSearchData(null);
                }
              }}
            />
            <button
              className="search-btn"
              title="Search"
              onClick={() => {
                props.setSearchData(inputValue);
              }}
            >
              <SearchSvg />
            </button>
          </div>
        )}
        {props.buttonName && (
          <button className="btn btn-primary" onClick={props.href}>
            <AddSvg />
            {props.buttonName}
          </button>
        )}
      </div>
    </div>
  );
}
