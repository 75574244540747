import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  ADD_COUPONS_REQUEST,
  DELETE_COUPONS_REQUEST,
  REDEEM_COUPONS_REQUEST,
  REMOVE_COUPONS_REQUEST,
  UPDATE_COUPONS_REQUEST,
} from "./type";
import { handleApiCallException } from "../shared/action";
import { invokeApi } from "../../enverment/axiosHelper";
import {
  addRedeemCouponsSuccess,
  deleteCouponSuccess,
  getRedeemCouponsRequest,
  getRedeemCouponsSuccess,
  removeRedeemCouponsSuccess,
  updateRedeemCouponsSuccess,
} from "./action";
import { ApiResponseMessage } from "../../helper/tosterHelpers";
import { DANGER, SUCCESS } from "../constant/commonConstants";
function* getRedeemCoupons() {
  try {
    const response = yield call(invokeApi, "get", "/couponsList");
    if (response.status) {
      yield put(getRedeemCouponsSuccess(response.data));
    } else {
      yield put(getRedeemCouponsSuccess([]));
      handleApiCallException(response.message);
    }
  } catch (error) {
    handleApiCallException(error);
  }
}
function* addRedeemCoupons({ payload: data }) {
  const couponsData = {
    couponCode: data.couponCode.toUpperCase(),
    discountAmount: data.discountAmount,
    validityStartDate: data.validityStartDate,
    validityEndDate: data.validityEndDate,
    minPurchase: data.minPurchase,
  };

  try {
    const response = yield call(invokeApi, "post", "/addCoupons", couponsData);
    if (response.status) {
      yield put(addRedeemCouponsSuccess(response.data));
      ApiResponseMessage(response.message, SUCCESS);
      yield put(getRedeemCouponsRequest());
    } else {
      yield put(addRedeemCouponsSuccess());
      ApiResponseMessage(response.message, DANGER);
    }
  } catch (error) {
    handleApiCallException(error);
  }
}
function* updateRedeemCoupons({ payload: data }) {
  const couponsData = {
    couponCode: data.couponCode.toUpperCase(),
    discountAmount: data.discountAmount,
    validityStartDate: data.validityStartDate,
    validityEndDate: data.validityEndDate,
    usageLimit: data.usageLimit,
    minPurchase: data.minPurchase,
  };

  try {
    const response = yield call(
      invokeApi,
      "put",
      `/updateCoupons/${data.id}`,
      couponsData
    );
    if (response.status) {
      yield put(updateRedeemCouponsSuccess(response.data));
      ApiResponseMessage(response.message, SUCCESS);
      yield put(getRedeemCouponsRequest());
    } else {
      ApiResponseMessage(response.message, DANGER);
      handleApiCallException(response.message);
    }
  } catch (error) {
    handleApiCallException(error);
  }
}
function* removeRedeemCoupons({ payload: data }) {
  try {
    const response = yield call(invokeApi, "post", "/deleteCoupons", data);
    if (response.status) {
      yield put(removeRedeemCouponsSuccess(response.data));
      ApiResponseMessage(response.message, SUCCESS);
      yield put(getRedeemCouponsRequest());
    } else {
      ApiResponseMessage(response.message, DANGER);
      handleApiCallException(response.message);
    }
  } catch (error) {
    handleApiCallException(error);
  }
}
function* deleteRedeemCoupons({ payload: data }) {
  try {
    const response = yield call(invokeApi, "post", "/deleteCoupons", data);
    if (response.status) {
      yield put(deleteCouponSuccess(response.data));
      ApiResponseMessage(response.message, SUCCESS);
      yield put(getRedeemCouponsRequest());
    } else {
      ApiResponseMessage(response.message, DANGER);
      handleApiCallException(response.message);
    }
  } catch (error) {
    handleApiCallException(error);
  }
}

export function* CouponRequest() {
  yield takeEvery(REDEEM_COUPONS_REQUEST, getRedeemCoupons);
  yield takeEvery(ADD_COUPONS_REQUEST, addRedeemCoupons);
  yield takeEvery(UPDATE_COUPONS_REQUEST, updateRedeemCoupons);
  yield takeEvery(REMOVE_COUPONS_REQUEST, removeRedeemCoupons);
  yield takeEvery(DELETE_COUPONS_REQUEST, deleteRedeemCoupons);
}
function* CouponSaga() {
  yield all([fork(CouponRequest)]);
}
export default CouponSaga;
