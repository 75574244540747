import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { SignOutTostrMessage } from "../../helper/tosterHelpers";
import { LogoutSvg } from "./CommonSvg";
import { getUserName } from "../../utils/authUtils";

function Header(props) {
  const navigate = useNavigate();
  const logout = () => {
    SignOutTostrMessage();
    localStorage.clear();
    navigate("/");
  };
  const UserName = getUserName();
  const GetHeaderName = () => {
    return localStorage.getItem("currentPage");
  };
  return (
    <>
      <div className="main-header">
        <div className="left-head">
          <Button
            onClick={props.handleSubmit}
            className="hamburger_btn me-2 p-0 pt-1"
          >
            <label>
              <span></span>
              <span></span>
              <span></span>
            </label>
          </Button>
          <h1 className="page-title" id="pageTitle">
            {GetHeaderName()}
          </h1>
        </div>
        <div className="right-head">
          <UncontrolledDropdown className="profile-main" direction="down">
            <DropdownToggle color="link">
              <div className="profile_img">
                {/* <img src={Profile} alt="profile_img" /> */}
                <span>{UserName ? UserName.charAt(0).toUpperCase() : ""}</span>
              </div>
              <div className="profile_text">
                <p>{UserName}</p>
              </div>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={() => {
                  logout();
                }}
              >
                <LogoutSvg />
                Logout
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          {/* <Button color="danger" >
                        log out
                    </Button> */}
        </div>
      </div>
    </>
  );
}

export default Header;
