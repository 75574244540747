import React, { useState } from "react";
import { Row, Col, CardBody, Card, CardHeader } from "reactstrap";
import ApexCharts from "react-apexcharts";
import { connect } from "react-redux";
import { getDashboardListRequest } from "../../redux/dashBoard/action";
import { useEffect } from "react";
import {
  donutChartDeviceOptionSeriesFunction,
  donutChartOptionSeriesFunction,
  getLineChartData,
  lineChartOptionSeriesFunction,
} from "./Common";

function Dashboard(props) {
  const [donutChartData, setDonutChartData] = useState([]);
  const [donutChartDevice, setDonutChartDevice] = useState([]);
  const [lineChartData, setLineChartData] = useState([]);

  useEffect(() => {
    props.getDataList();
  }, []);

  useEffect(() => {
    if (props.LineChartData) {
      // here  we're pass data to lineChartOptionSeriesFunction/donutChartOptionSeriesFunction this function and that returns columns and option to show values in chart
      setLineChartData(
        lineChartOptionSeriesFunction({
          date: getLineChartData(props.LineChartData)?.DateArray,
          MonthlyTotalFailedStories: getLineChartData(props.LineChartData)
            ?.MonthlyTotalFailedStories,
          MonthlyTotalStories: getLineChartData(props.LineChartData)
            ?.MonthlyTotalStories,
        })
      );
    }

    if (props.DataList) {
      setDonutChartData(
        donutChartOptionSeriesFunction({
          totalPaidUsers: props.DataList?.totalPaidUsers || 0,
          totalFreeUsers: props.DataList?.totalFreeUsers || 0,
          totalUsers: props.DataList?.totalUsers || 0,
        })
      );
      setDonutChartDevice(
        donutChartDeviceOptionSeriesFunction({
          androidCount: props.DataList?.totalDeviceCount?.androidCount || 0,
          iOSCount: props.DataList?.totalDeviceCount?.iOSCount || 0,
          totalDevice: (props.DataList?.totalDeviceCount?.iOSCount + props.DataList?.totalDeviceCount?.androidCount) || 0,
        })
      );
    }
  }, [props.LineChartData, props.DataList]);

  const UserName = localStorage.getItem("fullName");

  return (
    <>
      <div className="main_dashboard">
        <Row className="mb-3">
          <Col lg={8} className="dashboard_main_section">
            <div className="dashboard_main_img">
              <h5>
                Welcome back,<br></br>
                <span>{UserName}!</span>
              </h5>
            </div>
            <div className="dashboard_card">
              <ul>
                <li>
                  <p>Token Expenses</p>
                  <span>{props.DataList?.totalExpenses || 0}</span>
                </li>
                <li>
                  <p>Total Registered Users</p>
                  <span>{props.DataList?.totalUsers || 0}</span>
                </li>
                <li>
                  <p>Revenue of Subscription</p>
                  <span>{props.DataList?.totalRevenue || 0}</span>
                </li>
              </ul>
            </div>
          </Col>
          <Col lg={4}>
            <Card className="chart_card">
              <CardHeader>
                <h3>Total Users</h3>
              </CardHeader>
              <CardBody>
                {donutChartData?.series?.length > 0 && (
                  <ApexCharts
                    options={donutChartData?.options}
                    series={donutChartData?.series}
                    type="radialBar"
                    height={270}
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={8}>
            <Card className="chart_card">
              <CardHeader>
                <h3>Total Created Stories and Failed Stories</h3>
              </CardHeader>
              <CardBody className="pb-0">
                {lineChartData?.series?.length > 0 && (
                  <ApexCharts
                    type="area"
                    options={lineChartData?.options}
                    series={lineChartData?.series}
                    height={265}
                  />
                )}
              </CardBody>
            </Card>
          </Col>
          <Col lg={4}>
            <Card className="chart_card h-100">
              <CardHeader>
                <h3>Device</h3>
              </CardHeader>
              <CardBody>
                {donutChartDevice?.series?.length > 0 && (
                  <ApexCharts
                    options={donutChartDevice?.options}
                    series={donutChartDevice?.series}
                    type="radialBar"
                    height={270}
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

const mapStateToProps = ({ dashboard }) => {
  let DataList;
  let lineChartData = [];

  if (dashboard && dashboard?.List) {
    console.log(dashboard.List.totalDeviceCount, "dashboard")
    DataList = dashboard?.List;
    lineChartData = dashboard?.List && dashboard?.List?.monthlySummaries;
  }
  return {
    DataList: dashboard?.List,
    LineChartData: lineChartData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDataList: () => dispatch(getDashboardListRequest()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
