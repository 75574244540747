export const GET_REPORT_STORT_SUCCESS = "GET_REPORT_STORT_SUCCESS";
export const GET_REPORT_STORT_REQUEST = "GET_REPORT_STORT_REQUEST";
export const GET_ACTIVE_SUCCESS = "GET_ACTIVE_SUCCESS";
export const GET_ACTIVE_REQUEST = "GET_ACTIVE_REQUEST";

export const GET_VIEW_STORY_REQUEST = "GET_VIEW_STORY_REQUEST";
export const GET_VIEW_STORY_SUCCESS = "GET_VIEW_STORY_SUCCESS";

export const CREATE_STORY_REQUEST = "CREATE_STORY_REQUEST";
export const CREATE_STORY_SUCCESS = "CREATE_STORY_SUCCESS";
