import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import {
  ADD_ITEMS_PLAN_REQUEST,
  EDIT_ITEMS_PLAN_REQUEST,
  GET_ITEMS_PLAN_REQUEST,
  REMOVE_ITEMS_PLAN_REQUEST,
} from "./type";
import { invokeApi } from "../../../enverment/axiosHelper";
import { handleApiCallException } from "../../shared/action";
import { DANGER, SUCCESS } from "../../constant/commonConstants";
import { ApiResponseMessage } from "../../../helper/tosterHelpers";
import {
  addItemsPlanSuccess,
  editItemsPlanSuccess,
  getItemsPlanRequest,
  getItemsPlanSuccess,
  removeItemsPlanSuccess,
} from "./actions";
function* fetchItem() {
  try {
    const response = yield call(invokeApi, "get", `/itemPlanList`);
    if (response.status) {
      yield put(getItemsPlanSuccess(response.data));
    } else {
      ApiResponseMessage(response.message, DANGER);
    }
  } catch (error) {
    yield put(handleApiCallException(error));
  }
}
function* addItem({ payload: data }) {
  try {
    const response = yield call(invokeApi, "post", `/addPlanItem`, data);
    if (response.status) {
      yield put(addItemsPlanSuccess(response.data));
      ApiResponseMessage(response.message, SUCCESS);
      yield put(getItemsPlanRequest());
    } else {
      ApiResponseMessage(response.message, DANGER);
      yield put(addItemsPlanSuccess([]));
    }
  } catch (error) {
    yield put(handleApiCallException(error));
  }
}
function* editItem({ payload: data }) {
  try {
    const response = yield call(
      invokeApi,
      "put",
      `/updateItemPlan/${data.id}`,
      data
    );
    if (response.status) {
      yield put(editItemsPlanSuccess(response.data));
      yield put(getItemsPlanRequest());
    } else {
      yield put(editItemsPlanSuccess([]));
      yield put(getItemsPlanRequest());
    }

    ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER);
  } catch (error) {
    yield put(handleApiCallException(error));
  }
}

function* removeItem({ payload: data }) {
  try {
    const response = yield call(invokeApi, "post", `/deleteItemPlan`, data);
    if (response.status) {
      yield put(removeItemsPlanSuccess(response.data));
      ApiResponseMessage(response.message, SUCCESS);
      yield put(getItemsPlanRequest());
    } else {
      yield put(removeItemsPlanSuccess([]));
    }
  } catch (error) {
    yield put(handleApiCallException(error));
  }
}
function* watchPlansSagaRequest() {
  yield takeLatest(GET_ITEMS_PLAN_REQUEST, fetchItem);
  yield takeLatest(ADD_ITEMS_PLAN_REQUEST, addItem);
  yield takeLatest(EDIT_ITEMS_PLAN_REQUEST, editItem);
  yield takeLatest(REMOVE_ITEMS_PLAN_REQUEST, removeItem);
}

function* itemsPlanSaga() {
  yield all([fork(watchPlansSagaRequest)]);
}

export default itemsPlanSaga;
