import {
    ADD_PLANS_REQUEST,
    ADD_PLANS_SUCCESS,
    EDIT_PLANS_REQUEST,
    EDIT_PLANS_SUCCESS,
    GET_PLANS_REQUEST,
    GET_PLANS_SUCCESS,
    REMOVE_PLANS_REQUEST,
    REMOVE_PLANS_SUCCESS,
} from "./type";

const initialState = {
    plans: null,
    addPlans: null,
    editPlans: null,
    removePlans: null,
};
const plansReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PLANS_REQUEST:
            return {
                ...state,
                plans: action.payload,
            };
        case GET_PLANS_SUCCESS:
            return {
                ...state,
                plans: action.payload,
            };
        case ADD_PLANS_REQUEST:
            return {
                ...state,
                addPlans: action.payload,
            };
        case ADD_PLANS_SUCCESS:
            return {
                ...state,
                addPlans: action.payload,
            };
        case EDIT_PLANS_REQUEST:
            return {
                ...state,
                editPlans: action.payload,
            };
        case EDIT_PLANS_SUCCESS:
            return {
                ...state,
                editPlans: action.payload,
            };
        case REMOVE_PLANS_REQUEST:
            return {
                ...state,
                removePlans: action.payload,
            };
        case REMOVE_PLANS_SUCCESS:
            return {
                ...state,
                removePlans: action.payload,
            };
        default:
            return { ...state };
    }
};
export default plansReducer;
