import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import {
  ADD_MASTER_REQUEST,
  DELETE_MASTER_REQUEST,
  EDIT_MASTER_REQUEST,
  GET_MASTER_REQUEST,
} from "./type";
import {
  addMasterDataSuccess,
  deleteMasterDataSuccess,
  editMasterDataSuccess,
  getMasterDataRequest,
  getMasterDataSuccess,
} from "./action";
import { handleApiCallException } from "../../shared/action";
import { invokeApi } from "../../../enverment/axiosHelper";
import { ApiResponseMessage } from "../../../helper/tosterHelpers";
import { DANGER, SUCCESS } from "../../constant/commonConstants";

function* getMasterData(action) {
  try {
    const res = yield call(
      invokeApi,
      "post",
      "/MasterCharacter",
      action.payload
    );
    if (res.status) {
      yield put(getMasterDataSuccess(res.data));
    } else {
      yield put(getMasterDataSuccess());
    }
  } catch (error) {
    yield put(handleApiCallException(error));
  }
}

function* addMasterData({ payload: data }) {
  try {
    const response = yield call(
      invokeApi,
      "post",
      "/addMasterCharacter",
      data.formData
    );
    if (response.status) {
      yield put(addMasterDataSuccess(response.data));
      yield put(
        getMasterDataRequest({
          page: data.currentPage,
          limit: data.dataPerPage,
        })
      );
    } else {
      yield put(addMasterDataSuccess());
    }
    ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER);
  } catch (error) {
    yield put(handleApiCallException(error));
  }
}

function* deleteMasterData({ payload: data }) {
  try {
    const response = yield call(
      invokeApi,
      "post",
      "/deleteMasterCharacter",
      data
    );
    if (response.status) {
      yield put(deleteMasterDataSuccess(response.data));
      yield put(
        getMasterDataRequest({
          page: data.currentPage,
          limit: data.dataPerPage,
        })
      );
    } else {
      yield put(deleteMasterDataSuccess());
    }
    ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER);
  } catch (error) {
    yield put(handleApiCallException(error));
  }
}

function* editMasterData({ payload: data }) {
  const formData = data.formData;
  const convertedData = {};
  for (const [key, value] of formData.entries()) {
    convertedData[key] = value;
  }
  try {
    const response = yield call(
      invokeApi,
      "put",
      `/updateMasterCharacter/${convertedData.id}`,
      data.formData
    );
    if (response.status) {
      yield put(editMasterDataSuccess(response.data));
      yield put(
        getMasterDataRequest({
          page: data.currentPage,
          limit: data.dataPerPage,
        })
      );
    } else {
      yield put(editMasterDataSuccess());
    }
    ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER);
  } catch (error) {
    yield put(handleApiCallException(error));
  }
}

function* watchMasterDataRequest() {
  yield takeLatest(GET_MASTER_REQUEST, getMasterData);
  yield takeEvery(ADD_MASTER_REQUEST, addMasterData);
  yield takeEvery(DELETE_MASTER_REQUEST, deleteMasterData);
  yield takeEvery(EDIT_MASTER_REQUEST, editMasterData);
}

function* masterSaga() {
  yield all([fork(watchMasterDataRequest)]);
}

export default masterSaga;
