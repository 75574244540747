import React, { useState } from 'react'
import { StarSvg } from '../../../components/Common/CommonFrontSvg';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Breadcrumb, BreadcrumbItem } from 'reactstrap';

function PrivacyPolicy(props) {
    // const [isOpen, setIsOpen] = useState(false);
    // const toggle = () => setIsOpen(!isOpen);  

    const [open, setOpen] = useState('1');
    const toggle = (id) => {
        if (open === id) {
        setOpen();
        } else {
        setOpen(id);
        }
    };

    return (
        <>
            <section className='term-conditions-baneer p-0'>
                <div className='container'>
                    <div className='inner-page-banner'>
                        <h2>Privacy Policy</h2>
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <a href="/">
                                    Home
                                </a>
                            </BreadcrumbItem>
                            <BreadcrumbItem active>
                                Privacy Policy
                            </BreadcrumbItem>
                        </Breadcrumb>
                        <div className='shape-box'>
                            <StarSvg />
                            <StarSvg />
                        </div>
                    </div>
                </div>
            </section>
           
           <section className='terms-conditions-details'>
                <div className='container'>                                      
                    <Accordion open={open} toggle={toggle} className="terms-conditions-details-list pb-2 m-0">                         
                        <AccordionItem>
                            <AccordionHeader targetId="1" className="terms-conditions-list-title">
                                Privacy Policy
                            </AccordionHeader>
                            <AccordionBody accordionId="1">
                                <ul>
                                    <li>Effective date: [Insert Effective Date] At KidLitKatalyst, we value your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your data when you use our AI-generated stories mobile application and services ("App"). By accessing or using our App, you consent to the practices described in this Privacy Policy.</li>
                                </ul>
                            </AccordionBody>
                        </AccordionItem>    
                        <AccordionItem>
                            <AccordionHeader targetId="2" className="terms-conditions-list-title">
                                Information We Collect
                            </AccordionHeader>
                            <AccordionBody accordionId="2">
                                <ul>
                                    <li><b>Personal Information:</b> We may collect certain personal information, such as your name, email address, and age, when you create an account on our App. Additionally, if you are a parent or guardian setting up an account for your child, we may collect their name and age as well.</li>
                                    <li><b>Usage Information:</b> We gather data about how you interact with our App, including pages visited, features used, and the duration of your sessions. This information is collected to improve the user experience and enhance our services.</li>
                                    <li><b>Device Information:</b> To optimize our services, we may collect information about the device you use to access our App, including the device type, operating system, unique device identifiers, and mobile network information.</li>
                                    <li><b>Location Information:</b> We do not collect precise location information. However, if you grant us permission, we may collect and store general location data to offer region-specific content and improve our services.</li>
                                </ul>
                            </AccordionBody>
                        </AccordionItem>    
                        <AccordionItem>
                            <AccordionHeader targetId="3" className="terms-conditions-list-title">
                                How We Use Your Information
                            </AccordionHeader>
                            <AccordionBody accordionId="3">
                                <ul>
                                    <li>We utilize the information we collect to personalize your experience on our App, providing AI-generated stories tailored to your interests and reading level.</li>
                                    <li>To enhance our services, we may analyze usage patterns and behavior to improve story recommendations and app functionality.</li>
                                    <li>Your email address may be used to send you important updates, promotional offers, and relevant notifications related to our App. You can opt-out of receiving promotional emails at any time.</li>
                                    <li>We may use aggregated and anonymized data for research, statistical analysis, and marketing purposes.</li>
                                </ul>
                            </AccordionBody>
                        </AccordionItem>    
                        <AccordionItem>
                            <AccordionHeader targetId="4" className="terms-conditions-list-title">
                                Data Security
                            </AccordionHeader>
                            <AccordionBody accordionId="4">
                                <ul>
                                    <li>We employ industry-standard security measures to protect your data from unauthorized access, disclosure, alteration, or destruction.</li>
                                    <li>While we strive to ensure the security of your data, please understand that no method of transmission over the internet or electronic storage is entirely secure. Therefore, we cannot guarantee its absolute security.</li>
                                </ul>
                            </AccordionBody>
                        </AccordionItem>    
                        <AccordionItem>
                            <AccordionHeader targetId="5" className="terms-conditions-list-title">
                                Data Sharing
                            </AccordionHeader>
                            <AccordionBody accordionId="5">
                                <ul>
                                    <li>We do not sell, trade, or rent your personal information to third parties.</li>
                                </ul>
                            </AccordionBody>
                        </AccordionItem>    
                        <AccordionItem>
                            <AccordionHeader targetId="6" className="terms-conditions-list-title">
                                Children's Privacy
                            </AccordionHeader>
                            <AccordionBody accordionId="6">
                                <ul>
                                    <li>We do not knowingly collect personal information from children without verifiable parental consent. If we discover that a child has provided us with personal information without parental consent, we will take steps to delete this data.</li>
                                </ul>
                            </AccordionBody>
                        </AccordionItem>    
                        <AccordionItem>
                            <AccordionHeader targetId="7" className="terms-conditions-list-title">
                                Changes to this Privacy Policy
                            </AccordionHeader>
                            <AccordionBody accordionId="7">
                                <ul>
                                    <li>We may update this Privacy Policy from time to time to reflect changes in our practices or legal obligations.</li>
                                    <li>We will notify you of any material changes to this Privacy Policy by posting the revised version on our App.</li>
                                </ul>
                            </AccordionBody>
                        </AccordionItem>    
                        <AccordionItem>
                            <AccordionHeader targetId="8" className="terms-conditions-list-title">
                                Contact Us
                            </AccordionHeader>
                            <AccordionBody accordionId="8">
                                <ul>
                                    <li>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at kidlitkatalyst@cinutedigital.com.</li>
                                </ul>
                            </AccordionBody>
                        </AccordionItem>                       
                    </Accordion>   
                    <p>Thank you for choosing KidLitKatalyst! We are committed to providing you and your child with an enriching and safe storytelling experience.</p>                                                        
                </div>
           </section>          
        </>

    )
}


export default PrivacyPolicy