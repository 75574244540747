import {
  GET_USER_LOG_REQUEST,
  GET_USER_LOG_SUCCESS,
  GET_GROUP_LOG_LIST_REQUEST,
  GET_GROUP_LOG_LIST_SUCCESS,
} from "./type";

//Activity Log
export const getUserLogRequest = (pageInfo) => ({
  type: GET_USER_LOG_REQUEST,
  payload: pageInfo,
});
export const getUserLogSuccess = (user) => ({
  type: GET_USER_LOG_SUCCESS,
  payload: user,
});

export const getGroupLogListRequest = (pageInfo) => ({
  type: GET_GROUP_LOG_LIST_REQUEST,
  payload: pageInfo,
});
export const getGroupLogListSuccess = (user) => ({
  type: GET_GROUP_LOG_LIST_SUCCESS,
  payload: user,
});
