import { ADD_VOICE_REQUEST, ADD_VOICE_SUCCESS, DELETE_VOICE_REQUEST, DELETE_VOICE_SUCCESS, EDIT_VOICE_REQUEST, EDIT_VOICE_SUCCESS, GET_VOICE_FAILURE, GET_VOICE_REQUEST, GET_VOICE_SUCCESS } from "./type";


export const getVoiceRequest = (Data) => (
    {
        type: GET_VOICE_REQUEST,
        payload: Data

    }
);
export const getVoiceSuccess = (Data) => ({
    type: GET_VOICE_SUCCESS,
    payload: Data

});

export const getVoiceFailure = (error) => ({
    type: GET_VOICE_FAILURE,
    payload: error,
});
export const addVoiceRequest = (data) => ({
    type: ADD_VOICE_REQUEST,
    payload: data
}
);
export const addVoiceSuccess = (data) => ({
    type: ADD_VOICE_SUCCESS,
    payload: data

});
export const deleteVoiceRequest = (data) => {

    return {
        type: DELETE_VOICE_REQUEST,
        payload: data
    }
};
export const deleteVoiceSuccess = (placeId) => {
    return {
        type: DELETE_VOICE_SUCCESS,
        payload: placeId

    }
};
export const editVoiceRequest = (data) => (
    {
        type: EDIT_VOICE_REQUEST,
        payload: data
    }
);
export const editVoiceSuccess = (updateData) => ({
    type: EDIT_VOICE_SUCCESS,
    payload: updateData
});