import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { GET_SUPPORT_REQUEST } from "./type";
import { handleApiCallException } from "../shared/action";
import { invokeApi } from "../../enverment/axiosHelper";
import { getSupportSuccess } from "./action";

function* getSupport({ payload: data }) {
    try {
        const response = yield call(invokeApi, "post", "/contactUsList", data)
        if (response.status) {
            yield put(getSupportSuccess(response.data))
        } else {
            handleApiCallException(response.message)
        }
    } catch (error) {
        handleApiCallException(error)
    }
}
export function* SupportRequest() {
    yield takeLatest(GET_SUPPORT_REQUEST, getSupport);
}
function* SupportSaga() {
    yield all([fork(SupportRequest)]);
}
export default SupportSaga;
