import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  CakeSvg,
  CallSvg,
  ChildSvg,
  GenderBoySvg,
  GenderGirlSvg,
  LeftArrowSvg,
} from "../../../components/Common/CommonSvg";
import { Card } from "reactstrap";
import { Image } from "react-bootstrap";
import { getDateTimeFromDateTimeString } from "../../../helper/DataTimeHelpers";
import ChildImg from "../../../assets/images/parents.png";
import { commonConfirmBox } from "../../../helper/messageHelper";
import { connect } from "react-redux";
import {
  getChildRequest,
  removeUserRequest,
} from "../../../redux/user/usermanagement/action";
function ViewChildList(props) {
  const { removeList, childData, GetChildData } = props;
  const location = useLocation();
  const [getChild, setGetChild] = useState(null);
  const [Child, setChild] = useState(0);

  const GetData = location?.state?.UserData;
  const handleToggleActive = async (d) => {
    const updatedData = {
      refreshChilListId: GetData?.parentId,
      id: d.id,
      isActive: !d.isActive,
      parentId: d?.parentId,
      userType: d.userType,
    };
    const response = await commonConfirmBox(
      d.id &&
        `Do you want to ${
          updatedData.isActive ? "active" : "Inactive"
        } this child?`
    );
    if (response) {
      await removeList(updatedData);
    }
  };
  useEffect(() => {
    if (GetData.parentId !== null) {
      childData(GetData?.parentId);
    }
  }, [GetData?.parentId, childData]);
  useEffect(() => {
    if (GetChildData) {
      setGetChild(GetChildData);
    }
  }, [GetChildData]);
  useEffect(() => {
    if (props.childCount) {
      setChild(props.childCount);
    }
  }, [props.childCount]);

  const handleDeleteClick = async (data) => {
    const response = await commonConfirmBox(
      data.isActive
        ? // "Do you want to inactive this spice-up?"
          "Do you want to inactive this Sub Parent?"
        : //  "Do you want to active this spice-up?"
          "Do you want to active this Sub Parent?"
    );
    if (response) {
      props.removeList({
        refreshChilListId: GetData?.parentId,
        id: data.id,
        userType: data.userType,
        isActive: !data.isActive,
      });
    }
  };

  return (
    <>
      <Link className="back-btn btn" title="Back" to="/usermanagement">
        <span>
          <LeftArrowSvg />
        </span>
        Back
      </Link>
      <div className="usermanagement-view mt-3">
        <div className="parent-list m-0">
          <Card>
            <div className="parent-head">
              <div>
                <h4>{GetData?.firstName + " " + GetData?.lastName}</h4>
                <p>{GetData?.email}</p>
              </div>
              <p>{getDateTimeFromDateTimeString(GetData?.created_date)}</p>
            </div>
            <div className="parent-date">
              <div>
                <div className="date-gender-box">
                  <span className="child-box badge rounded-pill">
                    <ChildSvg />
                    {Child}
                  </span>

                  {GetData?.gender && (
                    <span
                      className={`badge gender-box rounded-pill ${
                        GetData.gender === "Female" ? " bg-pink" : "bg-info "
                      }`}
                    >
                      {GetData.gender === "Female" && <GenderGirlSvg />}
                      {GetData.gender === "Male" && <GenderBoySvg />}
                      {GetData?.gender}
                    </span>
                  )}
                </div>
                <span></span>
              </div>
            </div>
          </Card>

          {getChild
            ?.filter((x) => x.userType === "SubParent")
            ?.map((x) => (
              <Card className="sub-parent">
                <div className="parent-head">
                  <div>
                    <h4>{x?.firstName + " " + x?.lastName}</h4>
                    <p>{x?.email ? x?.email : ""}</p>
                  </div>
                  <div>
                    <p>{getDateTimeFromDateTimeString(x?.createdDate)}</p>
                  </div>
                </div>
                <div className="parent-date">
                  <div>
                    <div className="date-gender-box">
                      {/* <span className="child-box badge rounded-pill">
                    <ChildSvg />
                    {Child}
                  </span> */}
                      <span
                        className={`badge gender-box rounded-pill ${
                          x.gender === "Female" ? " bg-pink" : "bg-info "
                        }`}
                      >
                        {x.gender === "Male" ? (
                          <GenderBoySvg />
                        ) : (
                          <GenderGirlSvg />
                        )}
                        {x?.gender}
                      </span>
                      <span className="subparent-badge">{"Sub Parent"}</span>
                    </div>
                    <span>
                      <div className="switch">
                        <div className="switch">
                          <input
                            type="checkbox"
                            checked={x.isActive}
                            onChange={() => handleDeleteClick(x)}
                          />
                          <label
                            className="switch-check"
                            onClick={() => {
                              handleDeleteClick(x);
                            }}
                          >
                            <div className="switch-btn"></div>
                            <div className="active-switch">Active</div>
                            <div className="deactive-switch">Inactive</div>
                          </label>
                        </div>
                      </div>
                    </span>
                  </div>
                </div>
              </Card>
            ))}
        </div>
        <div className="child-list">
          {getChild &&
            getChild?.map((d, index) => {
              if (d.userType === "Children") {
                return (
                  <Card key={index}>
                    <div className="child-data">
                      <div className="child-data-head">
                        {d.gender === "Male" && (
                          <>
                            <span className="badge bg-info">
                              <GenderBoySvg /> Male
                            </span>
                          </>
                        )}
                        {d.gender === "Female" && (
                          <>
                            <span className="badge bg-pink">
                              <GenderGirlSvg /> Female
                            </span>
                          </>
                        )}
                        {!d.gender && <span></span>}
                        <div className="switch mb-1">
                          <input
                            type="checkbox"
                            checked={d.isActive}
                            onChange={() => handleToggleActive(d)}
                          />
                          <label
                            className="switch-check"
                            onClick={() => handleToggleActive(d)}
                          >
                            <div className="switch-btn"></div>
                            <div className="active-switch">Active</div>
                            <div className="deactive-switch">Inactive</div>
                          </label>
                        </div>
                      </div>
                      <div className="child-detail">
                        <div className="img-box">
                          <Image
                            src={d.profileImage ? d.profileImage : ChildImg}
                          />
                        </div>
                        <h4>
                          {d.firstName} {d.lastName ? d.lastName : ""}
                        </h4>
                        <p>{d.email}</p>
                        <span>
                          {d.birthdate !== null && (
                            <p className="date-box">
                              <CakeSvg />
                              {getDateTimeFromDateTimeString(d?.birthdate)}
                            </p>
                          )}
                          {d.contactNo !== null && (
                            <p>
                              <CallSvg /> {d.contactNo}
                            </p>
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="child-data-footer">
                      <p>
                        <b>User</b> {d.userType}
                      </p>
                      <p>
                        <b>Relationship </b> {d.relationship}
                      </p>
                    </div>
                  </Card>
                );
              }
              return null;
            })}
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  let ChildData = [];
  let childCount;
  if (state?.user.child && state?.user?.child?.childCount) {
    childCount = state?.user?.child?.childCount;
  }
  if (
    state?.user?.child?.childDetails &&
    state?.user?.child?.childDetails.length > 0
  ) {
    ChildData = state.user.child.childDetails;
  }
  return {
    GetChildData: ChildData,
    childCount: childCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeList: (data) => {
      dispatch(removeUserRequest(data));
    },
    childData: (data) => {
      dispatch(getChildRequest(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewChildList);
