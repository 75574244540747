import { useEffect, useState } from "react";
import { connect } from "react-redux";
import CommonTable from "../../../components/Table/CommonTable";
import CustomisedModal from "../../../components/Modal/CustomisedModal";
import {
  deleteSceneDataRequest,
  getSceneDataRequest,
} from "../../../redux/master/scene/action";
import {
  commonConfirmBox,
  commonMessangerBox,
} from "../../../helper/messageHelper";
import { getDateTimeFromDateTimeString } from "../../../helper/DataTimeHelpers";
import AddScene from "./AddScene";
import { EditSvg } from "../../../components/Common/CommonSvg";

function Scene(props) {
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage, setDataPerPage] = useState(10);
  const [getList, setGetList] = useState([]);
  const [searchData, setSearchData] = useState();
  useEffect(() => {
    props.getList({
      limit: dataPerPage,
      page: currentPage,
      search: searchData,
    });
  }, [currentPage, dataPerPage, searchData]);
  useEffect(() => {
    setCurrentPage(1);
  }, [dataPerPage, searchData]);
  useEffect(() => {
    if (props.getData) {
      setGetList(props.getData);
    }
  }, [props.getData]);
  // useEffect(() => {
  //   if (searchData) {
  //     props.getList({
  //       limit: dataPerPage,
  //       page: currentPage,
  //       search: searchData,
  //     });
  //     setGetList(props.getData);
  //   }
  // }, []);

  const columns = [
    {
      dataField: "sceneName",
      text: "Scene Name",
      formatter: (cell, row) => <>{row.sceneName}</>,
      sort: true,
    },
    {
      dataField: "created_date",
      text: "Created Date",
      formatter: (cell, row) => (
        <>{getDateTimeFromDateTimeString(row.created_date)}</>
      ),
      sort: true,
      headerClasses: "date-box",
    },
    {
      dataField: "status",
      text: " Status",
      formatter: (cell, row) => (
        <>
          <div className="switch">
            <input
              type="checkbox"
              checked={row.isActive}
              onChange={() => handleDeleteClick(row)}
            />
            <label
              className="switch-check"
              onClick={() => {
                handleDeleteClick(row);
              }}
            >
              <div className="switch-btn"></div>
              <div className="active-switch">Active</div>
              <div className="deactive-switch">Inactive</div>
            </label>
          </div>
        </>
      ),
      sort: false,
      headerClasses: "status-box", // Add custom class to header
    },
    {
      dataField: "Action",
      // text: "Action",
      formatter: (cell, row) => (
        <>
          <a
            className="btn btn-link edit-btn d-inline"
            onClick={() => handleEditClick(row)}
            title="Edit"
          >
            <EditSvg />
          </a>
        </>
      ),
      sort: false,
      classes: "text-center",
      headerClasses: "action-box text-center", // Add custom class to header
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const handleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleEditClick = (row) => {
    if (row?.isActive) {
      setIsModalOpen(!isModalOpen);
      setIsEdit(row);
    } else {
      commonMessangerBox("Before updating scene you have to activate status");
    }
  };

  const handleDeleteClick = async (row) => {
    const response = await commonConfirmBox(
      row.isActive
        ? "Do you want to inactive this scene?"
        : "Do you want to active this scene?"
    );
    if (response) {
      props.removeList({
        id: row.id,
        isActive: !row.isActive,
        currentPage,
      });
    }
  };
  return (
    <>
      <CommonTable
        buttonName={"Add scene"}
        data={getList ? getList : []}
        columns={columns}
        Header={true}
        setSearchData={setSearchData}
        setDataPerPage={setDataPerPage}
        updateData={props.getList}
        dataPerPage={dataPerPage}
        pagination={true}
        currentPage={currentPage}
        totalRecords={props?.totalRecords}
        searchBar={true}
        href={handleModal}
        setCurrentPage={setCurrentPage}
      />
      {isModalOpen && (
        <CustomisedModal
          isModalOpen={isModalOpen}
          modalName={!isEdit ? "Add Scene" : "Edit Scene"}
          onModalDismiss={() => {
            handleModal();
            setIsEdit(false);
          }}
        >
          <AddScene
            onModalDismiss={() => {
              handleModal();
              setIsEdit(null);
            }}
            data={isEdit}
          />
        </CustomisedModal>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  let DataList = [];
  let totalRecords = 0;
  if (
    state?.scene?.getScene?.sceneList &&
    state?.scene?.getScene?.sceneList?.length > 0
  ) {
    DataList = state?.scene?.getScene?.sceneList;
    totalRecords = state?.scene?.getScene?.totalRecords;
  }
  return {
    getData: DataList,
    totalRecords: totalRecords,
    isLoading: state.isLoading?.Requests?.get_scene,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getList: (data) => {
      dispatch(getSceneDataRequest(data));
    },
    removeList: (data) => {
      dispatch(deleteSceneDataRequest(data));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Scene);
