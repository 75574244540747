import React from 'react'
import Slider from "react-slick";
import ScrollAnimation from 'react-animate-on-scroll';
import Aboutus from "../../../assets/front-images/about-us-mockup.png";
import BoatShape from "../../../assets/front-images/boat-shape.png";
import FeatureImg from "../../../assets/front-images/features-img.png";
import FeatureImg2 from "../../../assets/front-images/features-img2.png";
import FeatureImg3 from "../../../assets/front-images/features-img3.png";
import FeatureImg4 from "../../../assets/front-images/features-img4.png";
import TrainImg from "../../../assets/front-images/train.png";
import SubscribeMockup from "../../../assets/front-images/subscribe-mockup.png";
import Roundshape from "../../../assets/front-images/round-dots-white.png";
import Dropshape from "../../../assets/front-images/drop-shape.png";
import Dropshape2 from "../../../assets/front-images/drop-shape.png";
import PlansImg from "../../../assets/front-images/plan-img.png";
import PlansImg2 from "../../../assets/front-images/plan-img2.png";
import PlansImg3 from "../../../assets/front-images/plan-img3.png";
import UserImg from "../../../assets/front-images/testimonial-img.png";
import CalltoactionMockup from "../../../assets/front-images/call-to-action-mockup.png";
import Playstore from "../../../assets/front-images/google-play.png";
import Applestore from "../../../assets/front-images/apple-store.png";
import PlaneShape from "../../../assets/front-images/plan-shape.png";
import BannerSlide from "../../../assets/front-images/slider-mockup.png";
import BannerSlide2 from "../../../assets/front-images/slider-mockup2.png";
import BannerSlide3 from "../../../assets/front-images/slider-mockup3.png";
import { Field, Form, Formik } from 'formik';
import { AirballoonSvg, BirdSvg, GirlDrawSvg, GirlPlaySvg, PapperPlaneSvg, QuoteSvg, RounddotedSvg, SunSvg, Ticksvg, TopArrowSvg, TwitterSvg } from '../../../components/Common/CommonFrontSvg';
import { connect } from 'react-redux';
import { sendemailRequest } from '../../../redux/landingpage/action';
import SimpleReactValidator from 'simple-react-validator';
import { useRef } from 'react';
import { __t } from '../../../i18n/translator';
import { mainSlide, testimonialSlide } from './commonFunction';

function HomePage(props) {
    const validator = useRef(new SimpleReactValidator());

    const handleSubmit = (values) => {
        if (validator.current.allValid()) {
            props.SendEmailRequest(values)
        } else {
            validator.current.showMessages();
        }

    };
    return (
        < >

            <section id='main' className='main-banner'>
                <div className='container'>
                    <div className='main-inner-banner'>
                        <Slider {...mainSlide}>
                            <div>
                                <div className='banner-slide slide-1'>
                                    <ScrollAnimation animateIn="animate__fadeIn" duration={0.3} className='slide-content'>
                                        <h1>Story Telling App For Your Children</h1>
                                        <p>Unlock Imagination with KidLitKatalyst AI-Personalized Stories for Kids</p>
                                        <span className='app_downloads_btn'>
                                            <a href='javascript:void(0);' title='Play Store'>
                                                <img src={Playstore} alt='Play store' />
                                            </a>
                                            <a href='javascript:void(0);' title='Apple store'>
                                                <img src={Applestore} alt='Apple store' />
                                            </a>
                                        </span>
                                    </ScrollAnimation>
                                    <ScrollAnimation animateIn="zoomIn" duration={0.3} className='img-box'>
                                        <img src={BannerSlide} alt='Mobile Mockup' />
                                    </ScrollAnimation>
                                </div>
                            </div>
                            <div>
                                <div className='banner-slide slide-2'>
                                    <ScrollAnimation animateIn="animate__fadeIn" duration={0.3} className='slide-content'>
                                        <h1>Encourage your kid to explore and engage in creative activities to help increase their imagination</h1>
                                        <p>Fuel Your Child's Imagination, Inspire Creativity with KidLitKatalyst!</p>
                                        <span>
                                            <a href='javascript:void(0);' title='Play Store'>
                                                <img src={Playstore} alt='Play store' />
                                            </a>
                                            <a href='javascript:void(0);' title='Apple store'>
                                                <img src={Applestore} alt='Apple store' />
                                            </a>
                                        </span>
                                    </ScrollAnimation>
                                    <ScrollAnimation animateIn="zoomIn" duration={0.3} className='img-box'>
                                        <img src={BannerSlide2} alt='Mobile Mockup' />
                                    </ScrollAnimation>
                                </div>
                            </div>
                            <div>
                                <div className='banner-slide slide-3'>
                                    <ScrollAnimation animateIn="animate__fadeIn" duration={0.3} className='slide-content'>
                                        <h1>A Child Writing a Story and Drawing Illustrations</h1>
                                        <p>Fostering Young Authors & Artists: Unleash Creativity with KidLitKatalyst!</p>
                                        <span>
                                            <a href='javascript:void(0);' title='Play Store'>
                                                <img src={Playstore} alt='Play store' />
                                            </a>
                                            <a href='javascript:void(0);' title='Apple store'>
                                                <img src={Applestore} alt='Apple store' />
                                            </a>
                                        </span>
                                    </ScrollAnimation>
                                    <ScrollAnimation animateIn="zoomIn" duration={0.3} className='img-box'>
                                        <img src={BannerSlide3} alt='Mobile Mockup' />
                                    </ScrollAnimation>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
            </section>

            <section className='about-us' id='about'>
                <div className="container">
                    <div className='row'>
                        <div className='col-6'>
                            <div className='img-box'>
                                <ScrollAnimation animateIn="zoomIn" duration={0.3}>
                                    <img src={Aboutus} alt='Mockup' />
                                </ScrollAnimation>
                                <div className='shape-box'>
                                    {/* <img src={AboutShape} alt='Round' /> */}
                                    <RounddotedSvg />
                                </div>
                            </div>
                        </div>
                        <div className='col-6 overflow-hidden'>
                            <ScrollAnimation animateIn="animate__fadeInRight" duration={0.3} className='content-box'>
                                <div className='common-title'>
                                    <span className='badge-title'>About Our App</span>
                                    <h2 className='title-box'>About our App</h2>
                                </div>
                                <p>At KidLit Katalyst, we're on a mission to ignite young imaginations through the art of storytelling. Our innovative app blends the wonder of captivating tales with the precision of AI personalization, crafting unique adventures for every child. We believe that stories have the power to shape young minds, fostering creativity, empathy, and a passion for learning. </p>
                                <p>With a commitment to safety, education, and entertainment, we invite children and parents to embark on a literary journey like no other.</p>
                            </ScrollAnimation>
                        </div>
                    </div>
                </div>
                <div className='about-us-shapes'>
                    <img src={BoatShape} alt='Paper Boat' />
                    {/* <BoatSvg /> */}
                    {/* <img src={PapperPlane} alt='Paper Plane' /> */}
                    <PapperPlaneSvg />
                </div>
            </section>

            <section className='process p-0'>
                <div className='container'>
                    <div className='process-inner text-center overflow-hidden'>
                        <ScrollAnimation animateIn="animate__fadeInDown" duration={0.3} className='common-title'>
                            <span className='badge-title'>Process</span>
                            <h2 className='title-box'>Create your own story</h2>
                        </ScrollAnimation>
                        <div className='process-list'>
                            <ScrollAnimation animateIn="animate__fadeInLeft" duration={0.3} className='process-item'>
                                <span>01</span>
                                <h4>Create your own prompt</h4>
                                <p>Craft your very own story with KidLit Katalyst! Take the reins of creativity and let your imagination soar. Begin by selecting your characters, choosing the perfect place, defining the genre, adding excitement, and setting the language tone. Your personalized story adventure awaits!</p>
                            </ScrollAnimation>
                            <ScrollAnimation animateIn="animate__fadeIn" duration={0.3} className='process-item'>
                                <span>02</span>
                                <h4>Embrace your unique taste</h4>
                                <p>In the world of storytelling, your preferences matter. With KidLit Katalyst, we celebrate your unique taste by creating stories tailored just for you. Our AI-powered process begins by understanding what captivates your imagination.
So, why settle for ordinary tales when you can embrace your unique taste with KidLit Katalyst? Your next unforgettable story is just a click away.</p>
                            </ScrollAnimation>
                            <ScrollAnimation animateIn="animate__fadeInRight" duration={0.3} className='process-item'>
                                <span>03</span>
                                <h4>Enjoy your wonderful story</h4>
                                <p>Finally, it's time to immerse yourself in the world of KidLit Katalyst. Sit back, relax, and enjoy the wonderful stories we've crafted just for you. It's a reading experience like no other, where every adventure is a reflection of your one-of-a-kind imagination.</p>
                            </ScrollAnimation>
                        </div>
                    </div>
                </div>
                <div className='process-shapes'>
                    {/* <img src={GirlDraw} alt='Girl draw' /> */}
                    <GirlDrawSvg />
                </div>
            </section>

            <section className='features' id='features'>
                <div className='container'>
                    <ScrollAnimation animateIn="animate__fadeInDown" duration={0.3} className='common-title text-center'>
                        <span className='badge-title'>Features</span>
                        <h2 className='title-box'>Discovering the World a Visual Journey</h2>
                        <p>Our revolutionary app harnesses the power of artificial intelligence to deliver a one-of-a-kind storytelling experience for children. With our AI-generated stories, your child will embark on thrilling adventures, explore imaginative worlds, and ignite their love for adventures like never before. Here are the exceptional features that set KidLit Katalyst apart</p>
                    </ScrollAnimation>
                    <div className='features-list'>
                        <ScrollAnimation animateIn="animate__fadeInLeft" duration={0.3} className='features-item'>
                            <img src={FeatureImg} alt='Features Image' />
                            <div className='content-box'>
                                <h4>Create user account</h4>
                                <p>KidLitKatalyst makes it easy to create user accounts for children, parents, and all family members. Enjoy personalized storytelling adventures together, each tailored to individual preferences. Start your family's story-creation journey today!</p>
                            </div>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn="animate__fadeInRight" duration={0.3} className='features-item'>
                            <img src={FeatureImg2} alt='Features Image' />
                            <div className='content-box'>
                                <h4>Create Story</h4>
                                <p>Prepare to be amazed as our advanced AI algorithms create unique and captivating stories tailored to your child's interests, reading level, and preferences. Say goodbye to one-size-fits-all narratives – with KidLit Katalyst, every story is a personalized journey.</p>
                            </div>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn="animate__fadeInLeft" duration={0.3} className='features-item'>
                            <img src={FeatureImg3} alt='Features Image' />
                            <div className='content-box'>
                                <h4>Story Library</h4>
                                <p>Whether your child loves fairy tales, space adventures, mysteries, or historical quests, our diverse library of AI-generated stories covers a wide range of genres. We guarantee they'll find tales that match their wildest imaginations.</p>
                            </div>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn="animate__fadeInRight" duration={0.3} className='features-item'>
                            <img src={FeatureImg4} alt='Features Image' />
                            <div className='content-box'>
                                <h4>Story Player</h4>
                                <p>We believe in immersive storytelling. Our app brings stories to life through interactive elements that engage young adventures. They can interact with characters, make decisions that impact the plot, and be part of the magic unfolding on the pages.</p>
                            </div>
                        </ScrollAnimation>
                    </div>
                </div>
                <div className='features-shapes'>
                    {/* <img src={SunImg} alt='Sun' /> */}
                    <SunSvg />
                    <img src={TrainImg} alt='Train' />
                </div>
            </section>

            <section className='subscribe'>
                <div className='container'>
                    <div className='subscribe-inner'>
                        <img src={SubscribeMockup} alt='Mobile Mockup' />
                        <ScrollAnimation animateIn="animate__fadeIn" duration={0.3} className='content-box'>
                            <h3>Encourage your kid to explore and engage in creative activities to help increase their imagination!</h3>
                            <Formik
                                initialValues={{ email: '' }}
                                onSubmit={handleSubmit}
                            >
                                {({ handleSubmit, values }) => (
                                    <Form onSubmit={handleSubmit}>
                                        <div className='form-group'>
                                            <Field
                                                type="email"
                                                id="email"
                                                className="form-control"
                                                placeholder="Enter your email address"
                                                name="email"
                                            />

                                            <button type='submit' className='btn btn-primary' title='Subscribe'>Subscribe</button>
                                        </div>
                                        {validator.current.message(
                                            __t("validations.auth.email"),
                                            values.email,
                                            "required",
                                            {
                                                messages: {
                                                    required: __t("validations.auth.email"),
                                                },
                                            }
                                        )}
                                    </Form>
                                )}
                            </Formik>
                        </ScrollAnimation>
                        <div className='subscribe-inner-shapes'>
                            <img src={Roundshape} alt='shape' />
                            <img src={Dropshape} alt='shape' />
                            <img src={Dropshape2} alt='shape' />
                        </div>
                    </div>
                </div>
                <div className='subscribe-shapes'>
                    {/* <img src={Airballoon} alt='Airballoon' /> */}
                    <AirballoonSvg />
                </div>
            </section>

            <section className='plans'>
                <div className='container'>
                    <ScrollAnimation animateIn="animate__fadeInDown" duration={0.3} className='common-title text-center'>
                        <span className='badge-title'>Plans</span>
                        <h2 className='title-box'>Our Popular Plans</h2>
                    </ScrollAnimation>
                    <div className='plans-list'>
                        <ScrollAnimation animateIn="animate__fadeInLeft" duration={0.3} className='card'>
                            <h5>Free</h5>
                            <h3>₹0</h3>
                            <ul>
                                <li>
                                    <Ticksvg />
                                    Create 5 stories
                                </li>
                                <li>
                                    <Ticksvg />
                                    Add 1 characters
                                </li>
                                <li>
                                    <Ticksvg />
                                    Add 1 Place
                                </li>
                                <li>
                                    <Ticksvg />
                                    Add 1 Event
                                </li>
                                <li>
                                    <Ticksvg />
                                    Add 1 scence
                                </li>
                            </ul>
                            <button type='submit' className='btn btn-primary' title='Get Started'>Get Started</button>
                            <div className='plans-inner-shapes'>
                                <img src={PlansImg} alt='AirBalloon' />
                            </div>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn="animate__fadeIn" duration={0.3} className='card'>
                            <h5>VIP 20</h5>
                            <h3>₹50</h3>
                            <ul>
                                <li>
                                    <Ticksvg />
                                    Create 20 stories
                                </li>
                                <li>
                                    <Ticksvg />
                                    Add 3 characters
                                </li>
                                <li>
                                    <Ticksvg />
                                    Add 3 Place
                                </li>
                                <li>
                                    <Ticksvg />
                                    Add 3 Event
                                </li>
                                <li>
                                    <Ticksvg />
                                    Add 3 scence
                                </li>
                            </ul>
                            <button type='submit' className='btn btn-primary' title='Get Started'>Get Started</button>
                            <div className='plans-inner-shapes'>
                                <img src={PlansImg2} alt='AirBalloon' />
                            </div>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn="animate__fadeInRight" duration={0.3} className='card'>
                            <h5>VIP 30</h5>
                            <h3>₹60</h3>
                            <ul>
                                <li>
                                    <Ticksvg />
                                    Create 30 stories
                                </li>
                                <li>
                                    <Ticksvg />
                                    Add 5 characters
                                </li>
                                <li>
                                    <Ticksvg />
                                    Add 5 Place
                                </li>
                                <li>
                                    <Ticksvg />
                                    Add 5 Event
                                </li>
                                <li>
                                    <Ticksvg />
                                    Add 5 scence
                                </li>
                            </ul>
                            <button type='submit' className='btn btn-primary' title='Get Started'>Get Started</button>
                            <div className='plans-inner-shapes'>
                                <img src={PlansImg3} alt='AirBalloon' />
                            </div>
                        </ScrollAnimation>
                    </div>
                </div>
                <div className='plans-shapes'>
                    {/* <img src={GirlPlay} alt='Girl Img' /> */}
                    <GirlPlaySvg />
                </div>
            </section>

            <section className='testimonials'>
                <ScrollAnimation animateIn="animate__fadeInDown" duration={0.3} className='common-title text-center'>
                    <span className='badge-title'>Testimonials</span>
                    <h2 className='title-box'>What do they say after using the Kidlit Katalyst</h2>
                </ScrollAnimation>
                <ScrollAnimation animateIn="animate__fadeInRight" duration={0.3} className='testimonials-list'>
                    <Slider {...testimonialSlide}>
                        <div>
                            <div className='card'>
                                <QuoteSvg />
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                                <div className='testimonial-user'>
                                    <img src={UserImg} alt='User' />
                                    <div>
                                        <h6>James Gouse</h6>
                                        <span>writer and comedian</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='card'>
                                <QuoteSvg />
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                                <div className='testimonial-user'>
                                    <img src={UserImg} alt='User' />
                                    <div>
                                        <h6>James Gouse</h6>
                                        <span>writer and comedian</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='card'>
                                <QuoteSvg />
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                                <div className='testimonial-user'>
                                    <img src={UserImg} alt='User' />
                                    <div>
                                        <h6>James Gouse</h6>
                                        <span>writer and comedian</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='card'>
                                <QuoteSvg />
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                                <div className='testimonial-user'>
                                    <img src={UserImg} alt='User' />
                                    <div>
                                        <h6>James Gouse</h6>
                                        <span>writer and comedian</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </ScrollAnimation>
            </section>

            <section className='call-to-action'>
                <div className='container'>
                    <div className='call-to-action-inner'>
                        <ScrollAnimation animateIn="animate__fadeIn" duration={0.3} className='content-box'>
                            <h3>Discover Your Child's Favorite Story!</h3>
                            <p>Explore a world of storytelling with us and unlock the magic of your child's favorite tale. KidLit Katalyst helps you uncover the stories that captivate your little one's heart and imagination. Let's embark on a journey to find their perfect story!</p>
                            <span>
                                <a href='javascript:void(0);' title='Play Store'>
                                    <img src={Playstore} alt='Play store' />
                                </a>
                                <a href='javascript:void(0);' title='Apple store'>
                                    <img src={Applestore} alt='Apple store' />
                                </a>
                            </span>
                        </ScrollAnimation>
                        <img src={CalltoactionMockup} alt='Mobile Mockup' />
                    </div>
                </div>
                <div className='call-to-action-shapes'>
                    {/* <img src={BirdShape} alt='shape' /> */}
                    <BirdSvg />
                    <img src={PlaneShape} alt='shape' />
                </div>
            </section>

        </>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        SendEmailRequest: (data) => dispatch(sendemailRequest(data))

    }
}
export default connect(null, mapDispatchToProps)(HomePage)