import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import {
  ADD_LANGUAGE_REQUEST,
  DELETE_LANGUAGE_REQUEST,
  EDIT_LANGUAGE_REQUEST,
  GET_LANGUAGE_REQUEST,
} from "./type";
import {
  getLanguageFailure,
  getLanguageSuccess,
  addLanguageSuccess,
  deleteLanguageSuccess,
  getLanguageRequest,
  editLanguageSuccess,
} from "./action";
import { DANGER, SUCCESS } from "../../constant/commonConstants";
import { invokeApi } from "../../../enverment/axiosHelper";
import { ApiResponseMessage } from "../../../helper/tosterHelpers";
import { handleApiCallException } from "../../shared/action";
function* getLanguageSaga(action) {
  let res = "";
  try {
    res = yield call(invokeApi, "get", "/masterLanguageList", action.payload);
    if (res.status) {
      yield put(getLanguageSuccess(res.data));
    } else {
      yield put(getLanguageSuccess());
    }
    // ApiResponseMessage(res.message, res.status ? SUCCESS : DANGER);
  } catch (error) {
    // yield put(handleApiCallException(error));
    yield put(getLanguageFailure(error.message));
  }
}

function* addLanguageSaga(action) {
  try {
    const response = yield call(
      invokeApi,
      "post",
      "/addMasterLanguage",
      action.payload
    );
    if (response.status) {
      yield put(addLanguageSuccess(response.data));
      yield put(getLanguageRequest({}));
    } else {
      yield put(addLanguageSuccess());
    }
    ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER);
  } catch (error) {
    yield put(handleApiCallException(error));
  }
}

function* removeLanguage(action) {
  try {
    const response = yield call(invokeApi, "post", "/deleteMasterLanguage", {
      id: action.payload.id,
      isActive: action.payload.isActive,
    });
    if (response.status) {
      yield put(
        deleteLanguageSuccess({
          id: action.payload.id,
          isActive: action.payload.isActive,
        })
      );
      yield put(
        getLanguageRequest({
          page: action.payload.currentPage,
          limit: 10,
        })
      );
    } else {
      yield put(deleteLanguageSuccess());
    }
    ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER);
  } catch (error) {
    yield put(handleApiCallException(error));
  }
}
function* updateLanguage(action) {
  const formData = action.payload;
  const convertedData = {};
  for (const [key, value] of formData.entries()) {
    convertedData[key] = value;
  }
  try {
    const response = yield call(
      invokeApi,
      "put",
      `/updateMasterLanguage/${convertedData.id}`,
      action.payload
    );
    if (response.status) {
      yield put(editLanguageSuccess(response.data));
      yield put(getLanguageRequest({}));
    } else {
      yield put(editLanguageSuccess());
    }
    ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER);
  } catch (error) {
    yield put(handleApiCallException(error));
  }
}
function* watchLanguageRequest() {
  yield takeLatest(GET_LANGUAGE_REQUEST, getLanguageSaga);
  yield takeEvery(ADD_LANGUAGE_REQUEST, addLanguageSaga);
  yield takeEvery(DELETE_LANGUAGE_REQUEST, removeLanguage);
  yield takeEvery(EDIT_LANGUAGE_REQUEST, updateLanguage);
}
function* LanguageSaga() {
  yield all([fork(watchLanguageRequest)]);
}

export default LanguageSaga;
