import ShowMoreText from "react-show-more-text";
export const getFormattedDescription = (cell) => {
    return (
        <ShowMoreText
            lines={3}
            more={<b>Read more</b>}
            less={<b>Read less</b>}
            className="show-more-content"
            anchorClass="show-more-link"
            expandByClick={true}
            expanded={false}
            keepNewLines={true}
        >
            {cell}
        </ShowMoreText>
    );
};
