export const addPlansInitialState = (values) => {
  let value = values === undefined ? "" : values;
  return {
    planName: value?.plan_name,
    totalStory: value?.total_story,
    totalCharacter: value?.total_character,
    totalEvent: value?.total_event,
    totalPlace: value?.total_place,
    totalScene: value?.total_scene,
    price: value?.price,
    taxPercent: value?.taxPercent,
  };
};
