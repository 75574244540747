import React from "react";
import Modal from "react-bootstrap/Modal";

export default function CustomisedModal(props) {
    return (
        <Modal show={props.isModalOpen} className={props.modalSize} centered>
            <Modal.Header closeButton onClick={() => props.onModalDismiss(false)}>
                <Modal.Title>{props.modalName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{props.children}</Modal.Body>
        </Modal>
    );
}
