import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import {
  ADD_SPICE_UP_REQUEST,
  DELETE_SPICE_UP_REQUEST,
  EDIT_SPICE_UP_REQUEST,
  GET_SPICE_UP_REQUEST,
} from "./type";
import {
  getSpiceUpSuccess,
  addSpiceUpSuccess,
  deleteSpiceUpSuccess,
  getSpiceUpRequest,
  editSpiceUpSuccess,
} from "./action";
import { DANGER, SUCCESS } from "../../constant/commonConstants";
import { invokeApi } from "../../../enverment/axiosHelper";
import { ApiResponseMessage } from "../../../helper/tosterHelpers";
import { handleApiCallException } from "../../shared/action";

function* getSpiceUpSaga(action) {
  let res = "";
  try {
    res = yield call(invokeApi, "post", "/masterSpiceupList", action.payload);
    if (res.status) {
      yield put(getSpiceUpSuccess(res.data));
    } else {
      yield put(getSpiceUpSuccess());
    }
  } catch (error) {
    yield put(handleApiCallException(error.message));
  }
}

function* addSpiceUpSaga(action) {
  try {
    const response = yield call(
      invokeApi,
      "post",
      "/addMasterSpiceUp",
      action.payload
    );
    if (response.status) {
      yield put(addSpiceUpSuccess(response.data));
      yield put(getSpiceUpRequest({}));
    } else {
      yield put(addSpiceUpSuccess());
    }
    ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER);
  } catch (error) {
    yield put(handleApiCallException(error));
  }
}

function* removeSpiceUp(action) {
  try {
    const response = yield call(
      invokeApi,
      "post",
      "/deleteMasterSpiceUp",
      action.payload.data
    );
    if (response.status) {
      yield put(deleteSpiceUpSuccess(response.data));
      yield put(getSpiceUpRequest(action.payload.dispatch));
    } else {
      yield put(deleteSpiceUpSuccess());
    }
    ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER);
  } catch (error) {
    yield put(handleApiCallException(error));
  }
}
function* updateSpiceUp(action) {
  const formData = action.payload;
  const convertedData = {};
  for (const [key, value] of formData.entries()) {
    convertedData[key] = value;
  }
  try {
    const response = yield call(
      invokeApi,
      "put",
      `/updateMasterSpiceUp/${convertedData.id}`,
      action.payload
    );

    if (response.status) {
      yield put(editSpiceUpSuccess(response.data));
      yield put(getSpiceUpRequest({}));
    } else {
      yield put(editSpiceUpSuccess());
    }
    ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER);
  } catch (error) {
    yield put(handleApiCallException(error));
  }
}

function* watchSpiceUpRequest() {
  yield takeLatest(GET_SPICE_UP_REQUEST, getSpiceUpSaga);
  yield takeEvery(ADD_SPICE_UP_REQUEST, addSpiceUpSaga);
  yield takeEvery(DELETE_SPICE_UP_REQUEST, removeSpiceUp);
  yield takeEvery(EDIT_SPICE_UP_REQUEST, updateSpiceUp);
}
function* SpiceUpSaga() {
  yield all([fork(watchSpiceUpRequest)]);
}

export default SpiceUpSaga;
