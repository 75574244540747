import {
    EMAIL_DETAILS_REQUEST,
    EMAIL_DETAILS_SUCCESS,
    EMAIL_REQUEST,
    EMAIL_SUCCESS,
    EMAIL_TEMPLATE_REQUEST,
    EMAIL_TEMPLATE_SUCCESS,
    PLACEHOLDER_REQUEST,
    PLACEHOLDER_SUCCESS,
    ROLE_REQUEST,
    ROLE_SUCCESS,
    UPDATE_EMAIL_TEMPLATE_REQUEST,
    UPDATE_EMAIL_TEMPLATE_RESPONSE,
    UPDATE_EMAIL_TEMPLATE_STATUS_REQUEST,
    UPDATE_EMAIL_TEMPLATE_STATUS_RESPONSE,
} from "./type";

const initialState = {
    emailData: null,
    placeholderData: null,
    roleData: null,
    emailTemplateData: null
};

const emailReducer = (state = initialState, action) => {
    switch (action.type) {
        case EMAIL_REQUEST:
            return {
                ...state,
                emailData: action.payload,
            };
        case EMAIL_SUCCESS:
            return {
                ...state,
                emailData: action.payload,
            };
        case ROLE_REQUEST:
            return {
                ...state,
                roleData: action.payload,
            };
        case ROLE_SUCCESS:
            return {
                ...state,
                roleData: action.payload,
            };
        case PLACEHOLDER_REQUEST:
            return {
                ...state,
                placeholderData: action.payload,
            };
        case PLACEHOLDER_SUCCESS:
            return {
                ...state,
                placeholderData: action.payload,
            };
        case EMAIL_DETAILS_REQUEST:
            return {
                ...state,
                emailDetailsData: action.payload,
            };
        case EMAIL_DETAILS_SUCCESS:
            return {
                ...state,
                emailDetailsData: action.payload,
            };
        case EMAIL_TEMPLATE_REQUEST:
            return {
                ...state,
                emailTemplateData: action.payload,
            };
        case EMAIL_TEMPLATE_SUCCESS:
            return {
                ...state,
                emailTemplateData: action.payload,
            };
        case UPDATE_EMAIL_TEMPLATE_STATUS_REQUEST:
            return {
                ...state,
                responseTemplateType: action.payload,
            };
        case UPDATE_EMAIL_TEMPLATE_STATUS_RESPONSE:
            return {
                ...state,
                responseTemplateType: action.payload,
            };
        case UPDATE_EMAIL_TEMPLATE_REQUEST:
            return {
                ...state,
                responseType: action.payload,
            };
        case UPDATE_EMAIL_TEMPLATE_RESPONSE:
            return {
                ...state,
                responseType: action.payload,
            };
        default:
            return { ...state };
    }
};
export default emailReducer;
