import React from "react";
import { connect } from "react-redux";
import {
  getItemsPlanRequest,
  removeItemsPlanRequest,
} from "../../../redux/subscription/items/actions";
import { useEffect } from "react";
import { Card } from "reactstrap";
import { useState } from "react";
import AddItemPlans from "./AddItemPlans";
import { AddSvg, EditSvg } from "../../../components/Common/CommonSvg";
import {
  commonConfirmBox,
  commonMessangerBox,
} from "../../../helper/messageHelper";

function Items(props) {
  const { isAddItem, setIsAddItem, getItemList, getItemPlans } = props;
  useEffect(() => {
    getItemList();
  }, []);
  const [isEdit, setIsEdit] = useState(false);

  const handleEditClick = (row) => {
    if (row?.is_active) {
      setIsAddItem(true);
      setIsEdit(row);
    } else {
      commonMessangerBox("Before updating item you have to activate status");
    }
  };

  const handleDeleteClick = async (row) => {
    const response = await commonConfirmBox(
      row.is_active
        ? "Do you want to inactive this item?"
        : "Do you want to active this item?"
    );
    if (response) {
      props.removePlan({
        id: row.id,
        isActive: !row.is_active,
      });
    }
  };

  //   props.setIsAddItem(false);
  //   props.setIsEdit(null);
  const handleAddItem = () => {
    setIsAddItem(true);
  };
  return (
    <>
      <div className="subscription-items-box">
        <div>
          <Card>
            {/* add-subscription-box click plus btn than add-subscription-box class hide and plan-form show*/}
            {isAddItem ? (
              <div className="plan-form">
                <AddItemPlans
                  data={isEdit}
                  setIsEdit={setIsEdit}
                  setIsAddItem={setIsAddItem}
                />
              </div>
            ) : (
              <div className="add-subscription-box" onClick={handleAddItem}>
                <AddSvg />
                <h3> Create Items</h3>
              </div>
            )}
          </Card>
        </div>
        {getItemPlans &&
          getItemPlans.map((d, index) => {
            return (
              <div key={index}>
                <Card>
                  <div>
                    <span className="mb-2">₹{d.price}</span>
                    <ul>
                      <li>Add {d.total_item + " " + d.item_name}</li>
                    </ul>
                  </div>
                  <div className="mt-2">
                    <div className="switch">
                      <input
                        type="checkbox"
                        onChange={() => handleDeleteClick(d)}
                        checked={d?.is_active ? true : false}
                      />
                      <label
                        className="switch-check"
                        onClick={() => {
                          handleDeleteClick(d);
                        }}
                      >
                        <div className="switch-btn"></div>
                        <div className="active-switch">Active</div>
                        <div className="deactive-switch">Inactive</div>
                      </label>
                    </div>
                    <a
                      className="btn btn-link edit-btn"
                      title="Edit"
                      onClick={() => handleEditClick(d)}
                    >
                      <EditSvg />
                    </a>
                  </div>
                </Card>
              </div>
            );
          })}
      </div>
    </>
  );
}
const mapStateToProps = (state) => {
  let getData = [];

  if (
    state?.itemPlans?.items?.list.length > 0 &&
    state?.itemPlans?.items?.list
  ) {
    getData = state.itemPlans.items.list;
  }
  return { getItemPlans: getData };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getItemList: () => dispatch(getItemsPlanRequest()),
    removePlan: (data) => dispatch(removeItemsPlanRequest(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Items);
