import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    getDateTimeFromDateTimeString,
    getDateTimezone,
} from "../../helper/DataTimeHelpers";
import { DateSvg, WatchSvg } from "../../components/Common/CommonSvg";
import { getSupportRequest } from "../../redux/support/action";
import { getInitials } from "../../utils/generalUtils";
import TableHeader from "../../components/Table/TableHeader";
import CustomPagination from "../../components/Table/CommonPagination";
import { getFormattedDescription } from "../../utils/readmoreUtils";

function Support(props) {
    const [currentPage, setCurrentPage] = useState(1);
    const [dataPerPage, setDataPerPage] = useState(10);
    const [searchData, setSearchData] = useState("");
    const pagination = {
        limit: dataPerPage,
        page: currentPage,
    };
    useEffect(() => {
        props.getList(pagination);
    }, [currentPage, dataPerPage]);
    useEffect(() => {
        setCurrentPage(1);
        props.getList(pagination);
    }, [dataPerPage]);

    useEffect(() => {
        if (searchData) {
            props.getList({
                limit: dataPerPage,
                page: currentPage,
                search: searchData,
            });
        }
    }, [searchData]);

    return (
        <>
            <TableHeader
                updateData={props.getList}
                searchBar={true}
                setSearchData={setSearchData}
            />

            <ul className="support-tickets-list mt-2">
                {props?.getData?.map((x, index) => (
                    <li key={index}>
                        <div>
                            <div>
                                <span className="user-box">{getInitials(x?.name)}</span>
                                <div className="user-name">
                                    <h5>{x?.name}</h5>
                                    <p>{x?.email}</p>
                                </div>
                            </div>
                            <div>
                                <p>
                                    <DateSvg /> {getDateTimeFromDateTimeString(x?.createdDate)}
                                </p>
                                <p>
                                    <WatchSvg />
                                    {getDateTimezone(x?.createdDate)}
                                </p>
                            </div>
                        </div>
                        <p className="message-box">{getFormattedDescription(x?.message)}</p>
                    </li>
                ))}
            </ul>
            {props?.getData?.length > 0 ? (
                <CustomPagination
                    dataPerPage={dataPerPage}
                    currentPage={currentPage}
                    totalData={props.totalRecords}
                    setCurrentPage={setCurrentPage}
                    setDataPerPage={setDataPerPage}
                />
            ) : <h4 className="text-center mt-5 mb-5">No Data Found</h4>}
        </>
    );
}
const mapStateToProps = (state) => {
    let supportData;
    let totalRecords;
    if (
        state?.support?.getSupport?.contactUsList &&
        state.support.getSupport.contactUsList.length > 0
    ) {
        supportData = state.support.getSupport.contactUsList;
        totalRecords = state.support.getSupport.totalRecords;
    }

    return {
        getData: supportData,
        totalRecords: totalRecords,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getList: (data) => {
            dispatch(getSupportRequest(data));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Support);
