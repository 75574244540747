import { LOGIN_FAILURE, LOGIN_REQUEST, LOGIN_SUCCESS } from "./type";

const initialState = {
    user: null,
    error: null,
    loading: false,
};

const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_REQUEST:
            return { ...state, loading: true, error: null };
        case LOGIN_SUCCESS:
            return { ...state, user: action.payload, loading: false };
        case LOGIN_FAILURE:
            return { ...state, error: action.payload, loading: false };
        default:
            return state;
    }
};

export default loginReducer;
