import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field } from "formik";
import { loginRequest } from "../../redux/auth/login/action";
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import { Link, useNavigate } from "react-router-dom";
import { __t } from "../../i18n/translator";
import Logo from "../../assets/images/logo.png";
import Logoshape from "../../assets/images/banner-shape1.png";
import Logoshape2 from "../../assets/images/banner-shape2.png";
import Logoshape3 from "../../assets/images/banner-shape3.png";
import { Button, Spinner } from "reactstrap";
import { ShowSvg, HideSvg } from "../../components/Common/CommonSvg";
import { updateCurrentPageName } from "../../utils/authUtils";

const LoginForm = (props) => {
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      navigate("/home");
    }
  }, [navigate]);
  const validator = useRef(new SimpleReactValidator());
  const initialValues = {
    email: "",
    password: "",
  };

  const [showPassword, setShowPassword] = useState(false);
  const handlePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (values) => {
    if (validator.current.allValid()) {
      props.LoginApiRequest(values);
    } else {
      validator.current.showMessages();
    }
  };

  useEffect(() => {
    if (
      localStorage.getItem("accessToken") !== null &&
      props?.loginReducer?.user !== null
    ) {
      navigate("/home");
      updateCurrentPageName("Dashboard");
    }
  }, [
    localStorage.getItem("accessToken") !== null &&
      props?.loginReducer?.user !== null,
  ]);
  return (
    <div className="login-page">
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ isSubmitting, values }) => (
          <div className="login-main">
            <div className="main-logo" title="Kidlit Katalyst">
              <img alt="Kidlit Katalyst" src={Logo} />
            </div>
            <div className="login-head">
              <h2>Login</h2>
              <p>Enter your details to sign in to your account.</p>
            </div>
            <Form>
              <div className="form-group slide-left-box">
                <label htmlFor="email">Email ID</label>
                <Field
                  type="email"
                  id="email"
                  name="email"
                  className="form-control"
                  placeholder="Enter your email id"
                />
                {validator.current.message(
                  __t("validations.auth.email"),
                  values.email,
                  "required",
                  {
                    messages: {
                      required: __t("validations.auth.email"),
                    },
                  }
                )}
              </div>

              <div className="form-group mb-0 slide-left-box">
                <label htmlFor="password">Password</label>
                <Field
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  className="form-control"
                  placeholder="Enter valid password"
                />
                <div className="d-flex" onClick={handlePassword}>
                  {showPassword ? (
                    <div type={"show"} className="show-hide-password hide-icon">
                      <ShowSvg />
                    </div>
                  ) : (
                    <div type={"hide"} className="show-hide-password">
                      <HideSvg />
                    </div>
                  )}
                </div>
                {validator.current.message(
                  __t("validations.auth.password"),
                  values.password,
                  "required",
                  {
                    messages: {
                      required: __t("validations.auth.password"),
                    },
                  }
                )}
              </div>
              <Button
                className="btn-outline-primary mt-4"
                title="Login"
                type="submit"
                disabled={isSubmitting}
              >
                {props.loading ? <Spinner size={"sm"} /> : "Login"}
              </Button>
            </Form>
            <Link title="Forgot Password" className="mt-3" to="/forgot">
              Forgot Password
            </Link>
          </div>
        )}
      </Formik>
      <div className="shape-box">
        <img alt="Shape" src={Logoshape2} />
        <img alt="Shape" src={Logoshape3} />
        <img alt="Shape" src={Logoshape} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loginReducer: state.login,
    loading: state.login.loading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    LoginApiRequest: (creds) => {
      dispatch(loginRequest(creds));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
