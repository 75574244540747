import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import CommonPagination from "./CommonPagination";
import TableHeader from "./TableHeader";
export default function CommonTable(props) {
  const {
    data,
    columns,
    buttonName,
    setDataPerPage,
    totalRecords,
    setCurrentPage,
    dataPerPage,
    currentPage,
    pagination,
    setSearchData,
    updateData,
    isBackBtn,
  } = props;
  return (
    <>
      {props?.Header && (
        <TableHeader
          isBackBtn={isBackBtn}
          updateData={updateData}
          href={props.href}
          buttonName={buttonName}
          searchBar={props.searchBar}
          setSearchData={setSearchData}
        />
      )}
      <>
        <BootstrapTable
          id="myTable"
          classes={props.customClass}
          striped={true}
          bootstrap4
          keyField="id"
          data={data}
          columns={columns}
          bordered={true}
        />
      </>
      {pagination &&
        (totalRecords > 0 && data.length > 0 ? (
          <CommonPagination
            dataPerPage={dataPerPage}
            totalData={totalRecords}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            setDataPerPage={setDataPerPage}
          />
        ) : (
          <h4 className="text-center mt-5 mb-5">No Data Found</h4>
        ))}
    </>
  );
}
