import { Field } from "formik";
import React from "react";
import { CodeComponent } from "../../helper/otpHelper";
import { __t } from "../../i18n/translator";
import { useNavigate } from "react-router-dom";
export default function OtpView(props) {
  const navigate = useNavigate();

  const { values, validator, setFieldValue, otpFail } = props;
  const resandOtp = () => {
    navigate("/forgot");
    localStorage.removeItem("accessToken");
  };

  return (
    <>
      <div className="login-head text-center">
        <h3>Please enter your verification code</h3>
        <p>
          We have sent a verification code to your registered email address.
        </p>
      </div>
      <div
        className={
          otpFail ? "wrong_otp form-group otp-box" : "form-group otp-box"
        }
      >
        <Field
          as={CodeComponent}
          digits={6}
          onChange={(opt) => {
            setFieldValue("otp", opt);
          }}
          name="otp"
        />

        <span>
          If you didn't receive a code,{" "}
          <a
            href="javascript:void(0);"
            title="Resend"
            onClick={() => resandOtp()}
          >
            Resend
          </a>
        </span>
        {validator.current.message(
          __t("validations.auth.Otp"),
          values.otp,
          "required",
          {
            messages: {
              required: __t("validations.auth.Otp"),
            },
          }
        )}
      </div>
    </>
  );
}
