import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Field, Form, Formik } from "formik";
import SimpleReactValidator from "simple-react-validator";
import { __t } from "../../../i18n/translator";
import { addEventInitialState } from "./CommonFunction";
import { addEventListRequest, editEventListRequest } from "../../../redux/master/event/action";
import { DANGER } from "../../../redux/constant/commonConstants";
import { ApiResponseMessage } from "../../../helper/tosterHelpers";

function AddEvent(props) {
    const params = props?.data || {};
    const validator = useRef(new SimpleReactValidator());
    const [EventData] = useState(
        addEventInitialState(props?.data)
    );
    const handleSubmit = async (values) => {
        if (validator.current.allValid()) {
            if (params.id) {
                if (EventData !== values) {
                    props.updateEvent(values)
                } else {
                    ApiResponseMessage(__t("validations.common.same_Data_warning"), DANGER)
                }
            } else {
                props.addEvent(values)
            }
            setFormSubmitted(true)
            props.onModalDismiss(false)


        } else {
            validator.current.showMessages();
        }
    };
    const [formSubmitted, setFormSubmitted] = useState(false)

    useEffect(() => {
        if (formSubmitted && props.createEvent != null) {
            props.onModalDismiss(false)
        }
    }, [props.createEvent != null]);

    const operationType = !params.id ? "Submit" : "Update";
    return (
        <>

            <Formik initialValues={EventData} onSubmit={handleSubmit}>
                {({ isSubmitting, values, setFieldValue }) => (
                    <Form>
                        <div className="form-group mb-2">
                            <label htmlFor="eventName">Event Name <span className="text-danger required">*</span></label>
                            <Field type="text" id="eventName" className="form-control" placeholder="Event name" name="eventName" />
                            {validator.current.message(
                                __t("validations.event.eventName"),
                                values.eventName,
                                "required",
                                {
                                    messages: {
                                        required: __t("validations.event.eventName"),
                                    },
                                }
                            )}
                        </div>
                        <div className="btn-box">
                            <button className="btn btn-primary" type="submit" disabled={isSubmitting}>
                                {operationType}
                            </button>
                            <button className="btn btn-outline" type="button"
                                onClick={() => {
                                    props.onModalDismiss(false)
                                }}
                            >
                                cancel
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        createEvent: state?.event?.createEvent,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addEvent: (data) => dispatch(addEventListRequest(data)),
        updateEvent: (data) => dispatch(editEventListRequest(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEvent);
