import React, { useState } from "react";
import TableHeader from "../../../components/Table/TableHeader";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import ActivityLog from "./ActivityLog";
import TransactionLog from "./TransactionLog";

function Logs(props) {
  // const [tabActive, setTabActive] = useState("ActivityLog");
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage, setDataPerPage] = useState(10);
  const [searchData, setSearchData] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <TableHeader isLogModule={true} href={handleModal} setSearchData={setSearchData} updateData={() => { setSearchData() }} searchData={searchData} searchBar={true} />
      <div className="logs_table">
        <ActivityLog setSearchData={setSearchData} searchData={searchData} dataPerPage={dataPerPage} currentPage={currentPage} setDataPerPage={setDataPerPage} setCurrentPage={setCurrentPage} />
      </div>
    </>
  );
}

export default Logs;
