import { useEffect, useState } from "react";
import { connect } from "react-redux";
import CommonTable from "../../../components/Table/CommonTable";
import {
  deleteLanguageRequest,
  getLanguageRequest,
} from "../../../redux/master/language/action";
import {
  commonConfirmBox,
  commonMessangerBox,
} from "../../../helper/messageHelper";
import AddLanguage from "./AddLanguage";
import CustomisedModal from "../../../components/Modal/CustomisedModal";
import { EditSvg } from "../../../components/Common/CommonSvg";

function Language(props) {
  const { setIsModalOpen, tabActive, isModalOpen, handleModal } = props;
  const columns = [
    {
      dataField: "languageName",
      text: "Name",
      formatter: (cell, row) => <>{row.languageName}</>,
      sort: true,
    },
    {
      dataField: "languageImage",
      text: "Image",
      formatter: (cell, row) => (
        <div className="table-img-box sm">
          <img
            src={row.languageImage}
            alt={"language Image"}
            height="auto"
            width="100px"
          />
        </div>
      ),
    },
    {
      dataField: "status",
      text: " Status",
      formatter: (cell, row) => (
        <>
          <div className="switch">
            <input
              type="checkbox"
              onChange={() => handleDeleteClick(row)}
              checked={row.isActive}
            />
            <label
              className="switch-check"
              onClick={() => {
                handleDeleteClick(row);
              }}
            >
              <div className="switch-btn"></div>
              <div className="active-switch">Active</div>
              <div className="deactive-switch">Inactive</div>
            </label>
          </div>
        </>
      ),
      sort: false,
      headerClasses: "status-box", // Add custom class to header
    },
    {
      dataField: "Action",
      // text: "Action",
      formatter: (cell, row) => (
        <>
          <a
            className="btn btn-link edit-btn d-inline"
            title="Edit"
            onClick={() => handleEditClick(row)}
          >
            <EditSvg />
          </a>
        </>
      ),
      sort: false,
      classes: "text-center",
      headerClasses: "action-box text-center", // Add custom class to header
    },
  ];
  const [getList, setGetList] = useState([]);

  useEffect(() => {
    if (tabActive === "Language" && props.getData) {
      setGetList(props.getData);
    }
  }, [tabActive, props.getData]);

  const [isEdit, setIsEdit] = useState();
  const handleEditClick = (row) => {
    if (row?.isActive) {
      setIsModalOpen(!isModalOpen);
      setIsEdit(row);
    } else {
      commonMessangerBox(
        "Before updating language you have to activate status"
      );
    }
  };
  const handleDeleteClick = async (row) => {
    const response = await commonConfirmBox(
      row.isActive
        ? "Do you want to inactive this language?"
        : "Do you want to active this language?"
    );
    if (response) {
      props.removeList({
        id: row.id,
        isActive: !row.isActive,
      });
    }
  };
  useEffect(() => {
    props.getList();
  }, []);
  return (
    <>
      <CommonTable
        data={getList ? getList : []}
        columns={columns}
        Header={false}
        totalRecords={props?.totalRecords}
        updateData={getList ? getList : []}
      />

      {tabActive === "Language" && isModalOpen && (
        <CustomisedModal
          CustomisedModal
          isModalOpen={isModalOpen}
          modalName={!isEdit ? "Add Language" : "Edit Language"}
          onModalDismiss={() => {
            handleModal();
            setIsEdit(false);
          }}
        >
          <AddLanguage
            onModalDismiss={() => {
              handleModal();
              setIsEdit(false);
            }}
            data={isEdit}
          />
        </CustomisedModal>
      )}
    </>
  );
}
const mapStateToProps = (state) => {
  let DataList;
  let totalRecords = 0;
  if (
    state?.language?.getLanguage?.language_list &&
    state?.language?.getLanguage?.language_list?.length > 0
  ) {
    DataList = state?.language?.getLanguage?.language_list;
    totalRecords = state?.language?.getLanguage?.totalRecords;
  }
  return {
    getData: DataList,
    totalRecords: totalRecords,
    isLoading: state.isLoading?.Requests?.get_scene,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getList: (data) => {
      dispatch(getLanguageRequest(data));
    },
    removeList: (data) => {
      dispatch(deleteLanguageRequest(data));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Language);
