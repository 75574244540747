import {
    ADD_COUPONS_REQUEST,
    ADD_COUPONS_SUCCESS,
    DELETE_COUPONS_REQUEST,
    DELETE_COUPONS_SUCCESS,
    REDEEM_COUPONS_REQUEST,
    REDEEM_COUPONS_SUCCESS,
    REMOVE_COUPONS_REQUEST,
    REMOVE_COUPONS_SUCCESS,
    UPDATE_COUPONS_REQUEST,
    UPDATE_COUPONS_SUCCESS,
} from "./type";

export const getRedeemCouponsRequest = (data) => {
    return {
        type: REDEEM_COUPONS_REQUEST,
    };
};

export const getRedeemCouponsSuccess = (data) => {
    return {
        type: REDEEM_COUPONS_SUCCESS,
        payload: data,
    };
};

export const addRedeemCouponsRequest = (data) => {
    return {
        type: ADD_COUPONS_REQUEST,
        payload: data,
    };
};

export const addRedeemCouponsSuccess = (data) => {
    return {
        type: ADD_COUPONS_SUCCESS,
        payload: data,
    };
};

export const updateRedeemCouponsRequest = (data) => {
    return {
        type: UPDATE_COUPONS_REQUEST,
        payload: data,
    };
};

export const updateRedeemCouponsSuccess = (data) => {
    return {
        type: UPDATE_COUPONS_SUCCESS,
        payload: data,
    };
};

export const removeRedeemCouponsRequest = (data) => {

    return {
        type: REMOVE_COUPONS_REQUEST,
        payload: data,
    };
};

export const removeRedeemCouponsSuccess = (data) => {
    return {
        type: REMOVE_COUPONS_SUCCESS,
        payload: data,
    };
};

export const deleteCouponRequest = (data) => {
    return {
        type: DELETE_COUPONS_REQUEST,
        payload: data,
    };
};

export const deleteCouponSuccess = (data) => {
    return {
        type: DELETE_COUPONS_SUCCESS,
        payload: data,
    };
};

