import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { getViewStoryRequest } from "../../redux/reportstory/action";
import { Button } from "reactstrap";

function ViewReportStory(props) {
  const { getStory, data, getStoryData } = props;

  useEffect(() => {
    getStory(data.story_id);
  }, [data.story_id]);

  return (
    <div className="report-view-story">
      <p>{getStoryData.storyScript}</p>
      <div className="text-center ">
        <button
          className="btn btn-outline m-auto mt-3"
          type="button"
          onClick={() => {
            props.onModalDismiss(false);
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  let getStoryReport = [];
  if (state?.reportStory?.viewStory?.storylist) {
    getStoryReport = state.reportStory.viewStory.storylist;
  }

  return {
    getStoryData: getStoryReport,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getStory: (data) => {
      dispatch(getViewStoryRequest(data));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewReportStory);
