import {
  ADD_API_MANGEMENT_DETAILS_REQUEST,
  ADD_API_MANGEMENT_DETAILS_SUCCESS,
  GET_API_MANAGEMENT_DETAILS_REQUEST,
  GET_API_MANAGEMENT_DETAILS_SUCCESS,
  UPDATE_API_MANGEMENT_DETAILS_REQUEST,
  UPDATE_API_MANGEMENT_DETAILS_SUCCESS,
} from "./type";

const initialState = {
  data: null,
  remove: null,
  loading: false,
  child: null,
  updateApiManagementData: null,
};

const apiManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_API_MANAGEMENT_DETAILS_REQUEST:
      return {
        ...state,
        data: action.payload,
      };

    case ADD_API_MANGEMENT_DETAILS_REQUEST:
      return {
        ...state,
        data: action.payload,
      };

    case ADD_API_MANGEMENT_DETAILS_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };

    case GET_API_MANAGEMENT_DETAILS_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };

    case UPDATE_API_MANGEMENT_DETAILS_REQUEST:
      return {
        ...state,
        updateApiManagementData: action.payload,
      };
    case UPDATE_API_MANGEMENT_DETAILS_SUCCESS:
      return {
        ...state,
        updateApiManagementData: action.payload,
      };

    default:
      return { ...state };
  }
};

export default apiManagementReducer;
