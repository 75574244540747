import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Progress } from "reactstrap";
import { Field, Form, Formik } from "formik";
import SimpleReactValidator from "simple-react-validator";
import { __t } from "../../../i18n/translator";
import { addSpiceUpInitialState } from "./CommonFunction";
import {
    addSpiceUpRequest,
    editSpiceUpRequest,
} from "../../../redux/master/spiceup/action";

import { handleFileUpload } from "../../../helper/imageUploadHelper";
import {
    DeleteSvg,
    FileIconSvg,
    ImageSvg,
} from "../../../components/Common/CommonSvg";
import { ApiResponseMessage } from "../../../helper/tosterHelpers";
import { DANGER } from "../../../redux/constant/commonConstants";

function AddSpiceUp(props) {
    const params = props?.data || "";
    const validator = useRef(new SimpleReactValidator());
    const [SpiceUpData] = useState(
        addSpiceUpInitialState(props?.data)
    );
    const [imgUpload, setImgUpload] = useState(null);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const handleSubmit = async (values) => {
        if (validator.current.allValid()) {
            const formData = new FormData();
            formData.append("spice_upImage", values.spice_upImage);
            formData.append("spice_upName", values.spice_upName);
            if (params.id) {
                if (SpiceUpData !== values) {
                    formData.append("id", params.id);
                    props.updateSpiceUp(formData);

                } else {
                    ApiResponseMessage(__t("validations.common.same_Data_warning"), DANGER)
                }
            } else {
                props.addSpiceUp(formData);
                props.onModalDismiss(false);

            }
            setFormSubmitted(true);
            props.onModalDismiss(false);
        } else {
            validator.current.showMessages();
        }
    };
    useEffect(() => {
        if (formSubmitted) {
            if (params.id) {
                if (props.updateSpiceUpData) {
                    props.onModalDismiss(false);
                    setFormSubmitted(false);
                }
            } else {
                if (props.createSpiceUp) {
                    props.onModalDismiss(false);
                    setFormSubmitted(false);
                }
            }
        }
    }, [formSubmitted, params.id, props.updateSpiceUpData, props.updateSpiceUp]);
    const operationType = !params.id ? "Submit" : "Update";
    const [imageName, setImageName] = useState("");
    const [uploadProgress, setUploadProgress] = useState(0);

    const handleFileChange = (event, setFieldValue) => {
        const { files } = event.currentTarget;
        const selectedFile = files[0];
        let FileType = "spice_upImage";
        handleFileUpload(
            selectedFile,
            FileType,
            setFieldValue,
            setImgUpload,
            setImageName,
            setUploadProgress
        );
    };
    const handleClearImage = (setFieldValue) => {
        setFieldValue("spice_upImage", null);
        setImgUpload([]);
        setImageName("");
        setUploadProgress(0);
    };
    useEffect(() => {
        params.id && setUploadProgress(100);
    }, [params.id]);

    return (
        <>
            <Formik initialValues={SpiceUpData} onSubmit={handleSubmit}>
                {({ isSubmitting, values, setFieldValue }) => (
                    <Form>
                        <div className="form-group mb-2">
                            <label htmlFor="spice_upName">Name <span className="text-danger required">*</span></label>
                            <Field type="text" id="spice_upName" className="form-control" placeholder="Name" name="spice_upName" />
                            {validator.current.message(
                                __t("validations.spiceup.spice_upName"),
                                values.spice_upName,
                                "required",
                                {
                                    messages: {
                                        required: __t("validations.spiceup.spice_upName"),
                                    },
                                }
                            )}
                        </div>
                        <div className="dropzone mt-3">

                            <label
                                htmlFor="spice_upImage"
                                className="dropzone-container mb-2"
                            >
                                <div className="file-icon">
                                    <ImageSvg />
                                </div>
                                <div className="dropzone-title">
                                    Drag and drop your files or
                                    <span className="browse"> browse</span> your files
                                </div>
                            </label>
                            <input
                                id="spice_upImage"
                                name="spice_upImage"
                                type="file"
                                accept="image/*"
                                onChange={(event) => handleFileChange(event, setFieldValue)}
                                className="form-control"
                            />

                            {validator.current.message(
                                __t("validations.spiceup.spice_upImage"),
                                values.spice_upImage,
                                "required",
                                {
                                    messages: {
                                        required: __t("validations.spiceup.spice_upImage"),
                                    },
                                }
                            )}

                            {values.spice_upImage && (
                                <div className="attachment-data mt-2">
                                    <div className="attachment-head">
                                        <a
                                            href={imgUpload === null ? values.spice_upImage : imgUpload}
                                            title={imgUpload === null && typeof values.spice_upImage === "string"
                                                ? values.spice_upImage.substring(
                                                    values.spice_upImage.lastIndexOf("/") + 1
                                                )
                                                : imageName
                                                    ? imageName
                                                    : "File Name"}                                            
                                            className="file-box"
                                        >
                                            {/* <FileIconSvg />
                                            <h6>
                                                {imgUpload === null &&
                                                    typeof values.spice_upImage === "string"
                                                    ? values.spice_upImage.substring(
                                                        values.spice_upImage.lastIndexOf("/") + 1
                                                    )
                                                    : imageName
                                                        ? imageName
                                                        : "File Name"}
                                            </h6> */}
                                            <img src={imgUpload === null ? values.spice_upImage : imgUpload} alt="Spice up Image"/>
                                        </a>
                                        <a
                                            onClick={() => handleClearImage(setFieldValue)}
                                            className="remove-box"
                                            title="remove"
                                        >
                                            <DeleteSvg />
                                        </a>
                                    </div>
                                    {uploadProgress < 100 && (
                                        <Progress
                                            animated
                                            color="primary"
                                            className="progress_bar mt-1"
                                            style={{
                                                height: "0.5rem",
                                            }}
                                            value={uploadProgress}
                                        ></Progress>
                                    )}
                                </div>
                            )}
                        </div>
                        <div className="btn-box">
                            <button
                                className="btn btn-primary"
                                type="submit"
                                disabled={isSubmitting}
                            >
                                {operationType}
                            </button>
                            <button
                                className="btn btn-outline"
                                type="button"

                                onClick={() => {
                                    props.onModalDismiss(false);
                                }}
                            >
                                cancel
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        createSpiceUp: state?.spiceup?.createSpiceUp,
        updateSpiceUpData: state?.spiceup?.updateSpiceUp,
        // getSpiceUpData: state?.SpiceUp?.getSpiceUp?.data?.SpiceUplist,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addSpiceUp: (data) => dispatch(addSpiceUpRequest(data)),
        updateSpiceUp: (data) => dispatch(editSpiceUpRequest(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSpiceUp);
