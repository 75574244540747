import React, { useEffect, useRef, useState } from "react";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Button, Spinner } from "reactstrap";
import SimpleReactValidator from "simple-react-validator";
import { ApiResponseMessage } from "../../helper/tosterHelpers";
import Logo from "../../assets/images/logo.png";
import Logoshape from "../../assets/images/banner-shape1.png";
import Logoshape2 from "../../assets/images/banner-shape2.png";
import Logoshape3 from "../../assets/images/banner-shape3.png";
import { otpRequest, passwordRequest } from "../../redux/auth/forgot/action";

import PasswordView from "./PasswordView";
import OtpView from "./OtpView";

const SetNewPasswod = (props) => {
  const { loading } = props;
  const validator = useRef(new SimpleReactValidator());
  const navigate = useNavigate();
  const storedToken = JSON.parse(localStorage.getItem("accessToken"));
  const initialValues = {
    token: storedToken,
    otp: "",
    password: "",
    confirmPassword: "",
  };
  const [isOtpValid, setIsOtpValid] = useState(false);
  const [isValid, setIsValid] = useState(false);
  useEffect(() => {
    // const { status } = props.Activation || {};
    // const accessToken = localStorage.getItem("accessToken");

    if (props?.forgotPassApiResp && props.Activation?.status) {
      setIsOtpValid(true);
    }
  }, [props.Activation?.status, props?.forgotPassApiResp]);

  const handleSubmit = (values) => {
    if (validator.current.allValid()) {
      if (values.password === values.confirmPassword) {
        const formattedValues = {
          token: values.token,
          password: values.password,
          otp: parseInt(values.otp),
        };
        props.userActivationApiRequest(formattedValues);
        setIsValid(true);
      } else {
        ApiResponseMessage("Password and confirm password do not match.");
      }
    } else {
      validator.current.showMessages();
    }
  };
  const handleOtp = (values) => {
    if (!validator.current.allValid()) {
      validator.current.showMessages();
      return;
    }
    const formattedValues = {
      token: values.token,
      otp: parseInt(values.otp),
    };
    props.OtpVerify(formattedValues);
  };
  let buttonText;

  if (isOtpValid) {
    buttonText = loading ? <Spinner size="sm" /> : "Signup";
  } else {
    buttonText = loading ? <Spinner size="sm" /> : "Submit";
  }
  useEffect(() => {
    if (localStorage.getItem("accessToken") && isValid === true) {
      navigate("/");
      localStorage.clear();
    }
  }, [isValid === true]);
  return (
    <div className="login-page setnew-password-page">
      <Formik
        initialValues={initialValues}
        onSubmit={isOtpValid ? handleSubmit : handleOtp}
      >
        {({ values, setFieldValue }) => (
          <div className="login-main">
            <div className="main-logo" title="Kidlit Katalyst">
              <img alt="Kidlit Katalyst" src={Logo} />
            </div>
            <Form>
              {isOtpValid ? (
                <>
                  <PasswordView validator={validator} values={values} />
                </>
              ) : (
                <OtpView
                  validator={validator}
                  values={values}
                  setFieldValue={setFieldValue}
                />
              )}
              <Button
                className="btn-outline-primary mt-4"
                title={buttonText == "Signup" ? "sign up" : "submit"}
                type="submit"
              >
                {buttonText == "Signup" ? "Sign up" : buttonText}
              </Button>
            </Form>
            <a
              title="Back to login"
              className="mt-3"
              onClick={() => {
                localStorage.clear();
                navigate("/login");
              }}
            >
              Back to login
            </a>
          </div>
        )}
      </Formik>
      <div className="shape-box">
        <img alt="Shape" src={Logoshape2} />
        <img alt="Shape" src={Logoshape3} />
        <img alt="Shape" src={Logoshape} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    forgotPassApiResp: state?.forgot?.user,
    Activation: state.forgot.activation,
    loading: state.forgot.loading,
    otpFail: state.forgot?.otpfail ? state.forgot?.otpfail : false,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    userActivationApiRequest: (data) => {
      dispatch(passwordRequest(data));
    },
    OtpVerify: (data) => {
      dispatch(otpRequest(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SetNewPasswod);
