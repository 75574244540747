import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { handleApiCallException } from "../shared/action";
import { invokeApi } from "../../enverment/axiosHelper";
import { getDashboardListSuccess } from "./action";
import { GET_DASHBOARD_DATA_REQUEST } from "./type";

function* dashBoardDataList({ payload: data }) {
  try {
    const response = yield call(invokeApi, "get", "/adminDashboard", data);
    if (response.status) {
      yield put(getDashboardListSuccess(response.data));
    } else {
      yield put(getDashboardListSuccess([]));
      handleApiCallException(response.message);
    }
  } catch (error) {
    handleApiCallException(error);
  }
}
export function* DashboardSagaWatcher() {
  yield takeLatest(GET_DASHBOARD_DATA_REQUEST, dashBoardDataList);
}
function* DashboardSaga() {
  yield all([fork(DashboardSagaWatcher)]);
}
export default DashboardSaga;
