import { GET_RATE_US_REQUEST, GET_RATE_US_SUCCESS } from "./type";

export const getRateListRequest = (data) => {
    return {
        type: GET_RATE_US_REQUEST,
        payload: data,
    };
};
export const getRateListSuccess = (data) => {
    return {
        type: GET_RATE_US_SUCCESS,
        payload: data,
    };
};
