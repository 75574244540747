import { useEffect, useState } from "react";
import { getDateTimeFromDateTimeString } from "../../helper/DataTimeHelpers";
import TableHeader from "../../components/Table/TableHeader";
import CommonTable from "../../components/Table/CommonTable";
import { getWebSubscriptionRequest } from "../../redux/subscription/webSubsription/action";
import { connect } from "react-redux";

function WebSubsription(props) {
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage, setDataPerPage] = useState(10);
  useEffect(() => {
    setCurrentPage(1);
  }, [dataPerPage]);
  useEffect(() => {
    props?.getUserLogs({
      limit: dataPerPage,
      page: currentPage,
    });
  }, [currentPage, dataPerPage]);
  const columns = [
    {
      dataField: "email",
      text: "email",
      sort: true,
    },
    {
      dataField: "created_date",
      text: " created date",
      formatter: (cell, row) => <>{getDateTimeFromDateTimeString(row.created_date)}</>,
      sort: true,
      headerClasses: "date-time-box",
    },

  ];
  return (
    <>
      <TableHeader />
      <CommonTable
        data={props.webSubscription ? props.webSubscription : []}
        columns={columns}
        setCurrentPage={setCurrentPage}
        dataPerPage={dataPerPage}
        setDataPerPage={setDataPerPage}
        currentPage={currentPage}
        pagination={true}
        totalRecords={props.totalRecords}
        updateData={props.getList}
      />
    </>
  );
}
const mapStateToProps = ({ WebSubscription }) => {
  let webSubscription = [];
  let totalRecords = [];
  if (WebSubscription?.webSubscription?.list?.length > 0) {
    return {
      webSubscription: WebSubscription.webSubscription.list,
      totalRecords: WebSubscription.webSubscription?.totalRecords|| 0,
    };
  }
  return {
    webSubscription: webSubscription,
    totalRecords: totalRecords,
  };
};
const mapDispatchToProps = (dispatch) => {
  return { getUserLogs: (data) => dispatch(getWebSubscriptionRequest(data)) };
};
export default connect(mapStateToProps, mapDispatchToProps)(WebSubsription);
