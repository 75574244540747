import React, { useState } from 'react'
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import TableHeader from "../../components/Table/TableHeader";

const LogsView = () => {
  return (
    <>
          Hello
            {/* <TableHeader
                href={handleModal}
                buttonName={tabActive === "Activelogs" ? "Add Voice" : "Add Errorlogs"}
                searchBar={false}
            />
            <Nav className="nav-underline logs_tab">
                <NavItem>
                    <NavLink>                        
                        <span>Active Logs</span>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink>                        
                        <span>Error Logs</span>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink>                        
                        <span>Transaction Logs</span>
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent className='card voice_tabcontent'>
                <TabPane>      
                123              
                </TabPane>
                <TabPane>      
                231             
                </TabPane>
                <TabPane>         
                312          
                </TabPane>
            </TabContent> */}
    </>
  );
};
export default LogsView;
