import { GET_SUPPORT_REQUEST, GET_SUPPORT_SUCCESS } from "./type";

export const getSupportRequest = (data) => {
    return {
        type: GET_SUPPORT_REQUEST,
        payload: data,
    };
};

export const getSupportSuccess = (data) => {
    return {
        type: GET_SUPPORT_SUCCESS,
        payload: data,
    };
};
