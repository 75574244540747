import { useEffect, useState } from "react";
import { connect } from "react-redux";
import CommonTable from "../../components/Table/CommonTable";
import { getPaymentHistoryDetailsRequest } from "../../redux/paymentHistory/action";
import { getDateTimeFromDateTimeString } from "../../helper/DataTimeHelpers";
import PacketHistoryCard, { INAPP, STRIPE } from "./Index";

function PaymentHistory(props) {
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage, setDataPerPage] = useState(10);
  const [searchData, setSearchData] = useState(null);
  const [getList, setGetList] = useState([]);
  const [tabActive, setTabActive] = useState(STRIPE);

  useEffect(() => {
    setCurrentPage(1);
  }, [dataPerPage, searchData]);

  useEffect(() => {
    if (props.getData) {
      setGetList(props.getData);
    }
  }, [props.getData]);

  useEffect(() => {
    props.getList({
      limit: dataPerPage,
      page: currentPage,
      search: searchData !== null ? searchData : null,
      stripe: tabActive == INAPP ? false : true,
    });
    searchData !== null && setCurrentPage(1);
  }, [currentPage, dataPerPage, searchData, tabActive]);

  return (
    <>
      <PacketHistoryCard
        tabActive={tabActive}
        setTabActive={setTabActive}
        setSearchData={setSearchData}
        setCurrentPage={setCurrentPage}
        setDataPerPage={setDataPerPage}
      >
        <CommonTable
          isBackBtn={false}
          data={getList ? getList : []}
          columns={columns}
          dataPerPage={dataPerPage}
          currentPage={currentPage}
          setDataPerPage={setDataPerPage}
          setCurrentPage={setCurrentPage}
          pagination={true}
          totalRecords={props?.totalRecords}
          Header={true}
          setSearchData={setSearchData}
          updateData={props.getList}
          searchBar={true}
          buttonName={false}
        />
      </PacketHistoryCard>
    </>
  );
}

const mapStateToProps = ({ paymentHistory }) => {
  let PaymentHistoryData = [];
  let totalRecords;

  if (paymentHistory?.data && paymentHistory?.data?.list) {
    PaymentHistoryData = paymentHistory?.data?.list;
    totalRecords = paymentHistory?.data?.totalRecords;
  }
  return {
    getData: PaymentHistoryData,
    totalRecords: totalRecords,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getList: (data) => {
      dispatch(getPaymentHistoryDetailsRequest(data));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PaymentHistory);

const columns = [
  {
    dataField: "Name",
    text: "Name",
    formatter: (cell, row) => <>{row?.Name}</>,
    sort: true,
  },
  {
    dataField: "EmailAddress",
    text: "Email",
    formatter: (cell, row) => <>{row?.EmailAddress}</>,
    sort: true,
  },
  {
    dataField: "transactionId",
    text: "TransactionId",
    formatter: (cell, row) => <>{row?.transactionId}</>,
    sort: true,
  },
  {
    dataField: "paymentType",
    text: "PaymentType",
    formatter: (cell, row) => <>{row?.paymentType}</>,
    sort: true,
  },
  {
    dataField: "amount",
    text: "Amount",
    formatter: (cell, row) => <>{`₹${row?.amount}`}</>,
    sort: true,
  },
  {
    dataField: "created_date",
    text: "CreatedDate",
    formatter: (cell, row) => (
      <>{getDateTimeFromDateTimeString(row?.created_date)}</>
    ),
    sort: true,
  },
  {
    dataField: "status",
    text: "Status",
    formatter: (cell, row) => (
      <>
        <span
          className={
            row.status === "succeeded" || row.status === "success"
              ? "badge badge-success text-capitalize"
              : "badge badge-danger text-capitalize"
          }
        >
          {row.status === "succeeded" || row.status === "success"
            ? "succeed"
            : "failed"}
        </span>
      </>
    ),
    sort: true,
  },
];
