export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILURE = "GET_USER_FAILURE";

export const ACTIVE_CHILD_REQUEST = "ACTIVE_CHILD_REQUEST";
export const ACTIVE_CHILD_SUCCESS = "ACTIVE_CHILD_SUCCESS";
export const GET_CHILD_REQUEST = "GET_CHILD_REQUEST";
export const GET_CHILD_SUCCESS = "GET_CHILD_SUCCESS";

export const SELECT_FAMILY_REQUEST = "SELECT_FAMILY_REQUEST";
export const SELECT_FAMILY_SUCCESS = "SELECT_FAMILY_SUCCESS";
