
export const removeTags = (str) => {
  if (str) {
    return str.replace(/(<([^>]+)>)/gi, '')
  } else {
    return ''
  }
}


export const getInitials = function (string) {
  if (string) {
    var names = string.split(' '),
      initials = names[0].substring(0, 1).toUpperCase()

    if (names?.length > 1) {
      initials += names[names?.length - 1].substring(0, 1).toUpperCase()
    }
    return initials
  } else {
    return ''
  }
}