import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { FormGroup, Progress, Label, Input } from "reactstrap";
import { Field, Form, Formik } from "formik";
import SimpleReactValidator from "simple-react-validator";
import { __t } from "../../../i18n/translator";
import { addThemeInitialState } from "./CommonFunction";
import {
  addThemeRequest,
  editThemeRequest,
} from "../../../redux/master/theme/action";
import {
  DeleteSvg,
  FileIconSvg,
  ImageSvg,
  UploadSvg,
} from "../../../components/Common/CommonSvg";
import { handleFileUpload } from "../../../helper/imageUploadHelper";
import { ApiResponseMessage } from "../../../helper/tosterHelpers";
import { DANGER } from "../../../redux/constant/commonConstants";
import {
  getImageWidth,
  handleMultipleFileUpload,
} from "../../../helper/multipleImageUploadHelper";
import { commonConfirmBox } from "../../../helper/messageHelper";
function AddTheme(props) {
  const params = props?.data || "";
  const [themeTypes, setThemeTypes] = useState();
  const [currTheme, setCurrTheme] = useState(null);
  const validator = useRef(new SimpleReactValidator());
  const [ThemeData] = useState(addThemeInitialState(props?.data));
  const [imgUpload, setImgUpload] = useState([]);
  const [allImages, setAllImages] = useState([]);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const handleModalDismiss = () => {
    props.onModalDismiss();
  };
  const handleSubmit = async (values) => {
    if (validator.current.allValid()) {
      const formData = new FormData();
      formData.append("themeColor", values.color);
      formData.append("themeType", values.themeType);
      await Promise.all(
        allImages.map(async (file, index) => {
          const width = await getImageWidth(file);
          formData.append(width, file);
        })
      );
      if (params.id) {
        if (JSON.stringify(ThemeData) !== JSON.stringify(values)) {
          formData.append("id", params?.id);
          props.updateTheme(formData);
          props.onModalDismiss(false);
        } else if (themeTypes !== currTheme) {
          formData.append("id", params?.id);
          props.updateTheme(formData);
          props.onModalDismiss(false);
        } else {
          ApiResponseMessage(
            __t("validations.common.same_Data_warning"),
            DANGER
          );
        }
      } else {
        props.addTheme(formData);
      }
      setFormSubmitted(true);
    } else {
      validator.current.showMessages();
    }
  };
  useEffect(() => {
    setThemeTypes(params.themeType);
    setCurrTheme(params.themeType);
  }, [params.id]);
  useEffect(() => {
    if (formSubmitted) {
      handleModalDismiss();
    }
  }, [formSubmitted, params.id, props.createTheme, props.updateTheme]);

  const handleFileChange = (event, setFieldValue) => {
    const { files } = event.currentTarget;
    const selectedFiles = Array.from(files);
    let FileType = "theme";
    handleMultipleFileUpload(
      selectedFiles,
      FileType,
      setFieldValue,
      setImgUpload,
      setAllImages,
      allImages
    );
  };

  const handleClearImage = (setFieldValue, images, index) => {
    const updatedImgUpload = [...images];
    const updatedImg = [...imgUpload];
    const allImage = [...allImages];
    updatedImgUpload.splice(index, 1);
    updatedImg.splice(index, 1);
    allImage.splice(index, 1);
    setFieldValue("theme", updatedImgUpload);
    setImgUpload(updatedImg);
    setAllImages(allImage)
  };

  return (
    <>
      <Formik initialValues={ThemeData} onSubmit={handleSubmit}>
        {({ isSubmitting, values, setFieldValue }) => {
          console.log(values, "valuesvalues")
          return (
            <Form>
              <div className="form-group mb-3 float-start">
                <label className="mb-2" htmlFor="themeType">
                  Theme Type <span className="text-danger required">*</span>
                </label>
                <div className="theme-box">
                  {/* <FormGroup check>
                    <Label check for="top">
                      <Field
                        type="radio"
                        name="themeType"
                        id="top"
                        value="top"
                      />
                      Top
                    </Label>
                  </FormGroup> */}
                  <FormGroup check>
                    <Input type="radio" name="themeType" id="top" value="top"/>
                    <Label check for="top">
                      Top align
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                  <Input type="radio" name="themeType" id="bottom" value="bottom"/>
                    <Label check for="bottom">
                      Bottom align
                    </Label>
                    {/* <Label check for="bottom">
                      <Field
                        type="radio"
                        name="themeType"
                        id="bottom"
                        value="bottom"
                      />
                      Bottom
                    </Label> */}
                  </FormGroup>
                </div>
                {validator.current.message(
                  __t("validations.common.type"),
                  values?.themeType,
                  "required",
                  {
                    messages: {
                      required: __t("validations.common.type"),
                    },
                  }
                )}
              </div>
              <div className="form-group float-start mb-2 ms-3">
                <label htmlFor="color">
                  Text color <span className="text-danger required">*</span>
                </label>
                <Field
                  type="color"
                  id="color"
                  placeholder="color"
                  name="color"
                  className="w-100"
                />
                {validator.current.message(
                  __t("validations.spiceup.color"),
                  values.color,
                  "required",
                  {
                    messages: {
                      required: __t("validations.spiceup.color"),
                    },
                  }
                )}
              </div>
              {/* <div className="form-group mb-2">
                <label htmlFor="color">
                  Device Type<span className="text-danger required">*</span>
                </label>
                <Field
                  as="select"
                  id="device"
                  name="device"
                  className="form-control"
                >
                  <option value="common" label="Common" />
                  <option value="mobile" label="mobile" />
                  <option value="tablet" label="tablet" />
                  <option value="landscape" label="landscape " />
                  {/* Add more options as needed */}
              {/* </Field>
                {validator.current.message(
                  __t("validations.spiceup.color"),
                  values.color,
                  "required",
                  {
                    messages: {
                      required: __t("validations.spiceup.color"),
                    },
                  }
                )}
              </div> */}
              <div className="dropzone w-100">
                <>
                  <label htmlFor="theme" className="dropzone-container mb-2 w-100">
                    <div className="file-icon">
                      <ImageSvg />
                    </div>
                    <div className="dropzone-title">
                      Drag and drop your files or{" "}
                      <span className="browse">browse</span> your files
                    </div>
                  </label>

                  <input
                    id="theme"
                    name="theme"
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={(event) => handleFileChange(event, setFieldValue)}
                    className="form-control"
                  />
                </>
                {validator.current.message(
                  __t("validations.theme.themeImage"),
                  values.theme?.length > 0,
                  "required",
                  {
                    messages: {
                      required: __t("validations.theme.themeImage"),
                    },
                  }
                )}

                <div className="theme-images">
                  {imgUpload?.length > 0 &&
                    imgUpload.map((img, index) => (
                    <div key={index} className="attachment-data">
                      <div className="attachment-head">
                        <a
                          href={img.imgUrl}
                          title={img.name ? img.name : "File Name"}
                          className="file-box"
                        >
                          <img
                            src={img.imgUrl}
                            alt={`Uploaded Image ${index + 1}`}
                          />
                        </a>
                        <a
                          onClick={async () => {
                            const response = await commonConfirmBox(
                              "Do you want to remove this image?"
                            );
                            if (response) {
                              handleClearImage(
                                setFieldValue,
                                values.theme,
                                index
                              );
                            }
                          }}
                          className="remove-box"
                          title="remove"
                        >
                          <DeleteSvg />
                        </a>
                        device: {img.device}
                      </div>
                      {/* {uploadProgress < 100 && (
                        <Progress
                          animated
                          color="primary"
                          className="progress_bar mt-1"
                          style={{
                            height: "0.5rem",
                          }}
                          value={uploadProgress}
                        ></Progress>
                      )} */}
                    </div>
                  ))}
                </div>
              </div>

              <div className="btn-box">
                <button
                  className="btn btn-primary gap-2 d-flex"
                  type="submit"
                  disabled={isSubmitting}
                >
                  <UploadSvg />
                  Upload
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    createTheme: state?.theme?.createTheme,
    updateData: state?.theme?.updateTheme,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addTheme: (data) => {
      dispatch(addThemeRequest(data));
    },
    updateTheme: (data) => dispatch(editThemeRequest(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTheme);
