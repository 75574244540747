import { ADD_LANGUAGE_REQUEST, ADD_LANGUAGE_SUCCESS, DELETE_LANGUAGE_REQUEST, DELETE_LANGUAGE_SUCCESS, EDIT_LANGUAGE_REQUEST, EDIT_LANGUAGE_SUCCESS, GET_LANGUAGE_FAILURE, GET_LANGUAGE_REQUEST, GET_LANGUAGE_SUCCESS } from "./type";


export const getLanguageRequest = (Data) => (
    {
        type: GET_LANGUAGE_REQUEST,
        payload: Data

    }
);
export const getLanguageSuccess = (Data) => ({
    type: GET_LANGUAGE_SUCCESS,
    payload: Data

});

export const getLanguageFailure = (error) => ({
    type: GET_LANGUAGE_FAILURE,
    payload: error,
});
export const addLanguageRequest = (data) => ({
    type: ADD_LANGUAGE_REQUEST,
    payload: data
}
);
export const addLanguageSuccess = (data) => ({
    type: ADD_LANGUAGE_SUCCESS,
    payload: data

});
export const deleteLanguageRequest = (placeId) => (
    {
        type: DELETE_LANGUAGE_REQUEST,
        payload: placeId
    }
);
export const deleteLanguageSuccess = (placeId) => ({
    type: DELETE_LANGUAGE_SUCCESS,
    payload: placeId


});
export const editLanguageRequest = (data) => (
    {
        type: EDIT_LANGUAGE_REQUEST,
        payload: data
    }
);
export const editLanguageSuccess = (updateData) => ({
    type: EDIT_LANGUAGE_SUCCESS,
    payload: updateData
});