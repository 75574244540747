import { useEffect, useState } from "react";
import { connect } from "react-redux";
import CommonTable from "../../../components/Table/CommonTable";
import {
  deleteVoiceRequest,
  getVoiceRequest,
} from "../../../redux/master/voice/action";
import {
  commonConfirmBox,
  commonMessangerBox,
} from "../../../helper/messageHelper";
import AddVoice from "./AddVoice";
import CustomisedModal from "../../../components/Modal/CustomisedModal";
import { EditSvg } from "../../../components/Common/CommonSvg";
function Voice(props) {
  const { setIsModalOpen, tabActive, isModalOpen, handleModal } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage, setDataPerPage] = useState(10);
  const [getList, setGetList] = useState([]);
  useEffect(() => {
    props.getList({
      limit: dataPerPage,
      page: currentPage,
    });
  }, [dataPerPage, currentPage]);
  useEffect(() => {
    if (tabActive === "Voice") {
      if (props.getData) {
        setGetList(props.getData);
      }
    }
  }, [props.getData]);
  const columns = [
    {
      dataField: "languageName",
      text: "Name",
      formatter: (cell, row) => <>{row.languageName}</>,
      sort: true,
    },
    {
      dataField: "voiceImage",
      text: "Image",
      formatter: (cell, row) => (
        <div className="table-img-box sm">
          <img
            src={row.voiceImage}
            height="auto"
            alt={"Voice Image"}
            width="100px"
          />
        </div>
      ),
    },
    {
      dataField: "languageCode",
      text: " Code",
      formatter: (cell, row) => <>{row.languageCode}</>,
      sort: true,
      headerClasses: "status-box",
    },
    // {
    //     dataField: "ssmlGender",
    //     text: "Gender",
    //     formatter: (cell, row) => <>{row.ssmlGender}</>,
    //     sort: true,
    //     headerClasses: "status-box",
    // },

    {
      dataField: "status",
      text: " Status",
      formatter: (cell, row) => (
        <>
          <div className="switch">
            <input
              type="checkbox"
              onChange={() => handleDeleteClick(row)}
              checked={row.isActive}
            />
            <label
              className="switch-check"
              onClick={() => {
                handleDeleteClick(row);
              }}
            >
              <div className="switch-btn"></div>
              <div className="active-switch">Active</div>
              <div className="deactive-switch">Inactive</div>
            </label>
          </div>
        </>
      ),
      sort: false,
      headerClasses: "status-box", // Add custom class to header
    },
    {
      dataField: "Action",
      // text: "Action",
      formatter: (cell, row) => (
        <>
          <a
            className="btn btn-link edit-btn d-inline"
            title="Edit"
            onClick={() => handleEditClick(row)}
          >
            <EditSvg />
          </a>
        </>
      ),
      sort: false,
      classes: "text-center",
      headerClasses: "action-box text-center", // Add custom class to header
    },
  ];
  const [isEdit, setIsEdit] = useState(false);

  const handleEditClick = (row) => {
    if (row?.isActive) {
      setIsModalOpen(!isModalOpen);
      setIsEdit(row);
    } else {
      commonMessangerBox("Before updating voice you have to activate status");
    }
  };

  const handleDeleteClick = async (row) => {
    const response = await commonConfirmBox(
      row.isActive
        ? "Do you want to inactive this voice?"
        : "Do you want to active this voice?"
    );
    if (response) {
      props.removeList({
        id: row.id,
        isActive: !row.isActive,
        currentPage,
      });
    }
  };
  return (
    <>
      <CommonTable
        Header={false}
        data={getList ? getList : []}
        setCurrentPage={setCurrentPage}
        columns={columns}
        setDataPerPage={setDataPerPage}
        dataPerPage={dataPerPage}
        currentPage={currentPage}
        totalRecords={props?.totalRecords}
        updateData={props.getList}
      />
      {tabActive === "Voice" && isModalOpen && (
        <CustomisedModal
          isModalOpen={isModalOpen}
          modalName={!isEdit ? "Add Voice" : "Edit Voice"}
          onModalDismiss={() => {
            handleModal();
            setIsEdit(false);
          }}
        >
          <AddVoice
            onModalDismiss={() => {
              handleModal(false);
              setIsEdit(false);
            }}
            data={isEdit}
          />
        </CustomisedModal>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  let DataList;
  let totalRecords = 0;

  if (
    state?.voice?.getVoice?.voicelist &&
    state?.voice?.getVoice?.voicelist?.length > 0
  ) {
    DataList = state?.voice?.getVoice?.voicelist;
    totalRecords = state?.voice?.getVoice?.totalRecords;
  }
  return {
    getData: DataList,
    totalRecords: totalRecords,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getList: (data) => {
      dispatch(getVoiceRequest(data));
    },
    removeList: (data, currentPage) => {
      dispatch(deleteVoiceRequest(data, currentPage));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Voice);
