import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import {
  ADD_EVENT_REQUEST,
  DELETE_EVENT_REQUEST,
  EDIT_EVENT_REQUEST,
  GET_EVENT_REQUEST,
} from "./type";
import {
  getEventListFailure,
  getEventListSuccess,
  addEventListSuccess,
  deleteEventListSuccess,
  getEventListRequest,
  editEventListSuccess,
} from "./action";
import { DANGER, SUCCESS } from "../../constant/commonConstants";
import { invokeApi } from "../../../enverment/axiosHelper";
import { ApiResponseMessage } from "../../../helper/tosterHelpers";
import { handleApiCallException } from "../../shared/action";
function* getEventListSaga(action) {
  let res = "";
  try {
    res = yield call(invokeApi, "post", "/masterEventList", action.payload);
    if (res.status) {
      yield put(getEventListSuccess(res));
    } else {
      yield put(getEventListSuccess());
    }
    // ApiResponseMessage(res.message, res.status ? SUCCESS : DANGER);
  } catch (error) {
    // yield put(handleApiCallException(error));
    yield put(getEventListFailure(error.message));
  }
}

function* addEventListSaga(action) {
  try {
    const response = yield call(
      invokeApi,
      "post",
      "/addMasterEvent",
      action.payload
    );
    if (response.status) {
      yield put(addEventListSuccess(response.data));
      yield put(getEventListRequest({}));
    } else {
      yield put(addEventListSuccess());
    }
    ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER);
  } catch (error) {
    yield put(handleApiCallException(error));
  }
}

function* removeEventList({ payload: data }) {
  try {
    const response = yield call(invokeApi, "post", "/deleteMasterEvent", {
      id: data.id,
      isActive: data.isActive,
    });
    if (response.status) {
      yield put(deleteEventListSuccess(response.data));
      yield put(
        getEventListRequest({
          currentPage: data.currentPage,
          dataPerPage: data.dataPerPage,
        })
      );
      ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER);
    } else {
      yield put(deleteEventListSuccess());
    }
  } catch (error) {
    yield put(handleApiCallException(error));
  }
}
function* updateEventList(action) {
  const { id } = action.payload;
  try {
    const response = yield call(
      invokeApi,
      "put",
      `/updateMasterEvent/${id}`,
      action.payload
    );
    if (response.status) {
      yield put(editEventListSuccess(response.data));
      yield put(getEventListRequest({}));
    } else {
      yield put(editEventListSuccess());
    }
    ApiResponseMessage(response.message, response.status ? SUCCESS : DANGER);
  } catch (error) {
    yield put(handleApiCallException(error));
  }
}
function* watchEventListRequest() {
  yield takeLatest(GET_EVENT_REQUEST, getEventListSaga);
  yield takeEvery(ADD_EVENT_REQUEST, addEventListSaga);
  yield takeEvery(DELETE_EVENT_REQUEST, removeEventList);
  yield takeEvery(EDIT_EVENT_REQUEST, updateEventList);
}
function* EventSaga() {
  yield all([fork(watchEventListRequest)]);
}

export default EventSaga;
