import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Progress } from "reactstrap";
import { Form, Formik } from "formik";
import SimpleReactValidator from "simple-react-validator";
import { __t } from "../../../i18n/translator";
import {
  addMasterDataRequest,
  editMasterDataRequest,
} from "../../../redux/master/character/action";
import { addCharecterInitialState } from "./CommonFunction";
import { handleFileUpload } from "../../../helper/imageUploadHelper";
import {
  DeleteSvg,
  FileIconSvg,
  ImageSvg,
} from "../../../components/Common/CommonSvg";
import { ApiResponseMessage } from "../../../helper/tosterHelpers";
import { DANGER } from "../../../redux/constant/commonConstants";
import uploadImg from "../../../assets/images/place4.png";

function AddMasterCharacter(props) {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const params = props?.data || {};
  const { dataPerPage, currentPage } = props;
  const validator = useRef(new SimpleReactValidator());
  const [CharacterData] = useState(addCharecterInitialState(props?.data));
  const handleSubmit = async (values) => {
    if (validator.current.allValid()) {
      const formData = new FormData();
      formData.append("characterAvtar", values.characterAvtar);
      if (params.id) {
        if (CharacterData !== values) {
          formData.append("id", params.id);
          props.updateCharacter({ formData, dataPerPage, currentPage });
        } else {
          ApiResponseMessage(
            __t("validations.common.same_Data_warning"),
            DANGER
          );
        }
        props.onModalDismiss(false);
      } else {
        props.addCharacter({ formData, dataPerPage, currentPage });
      }
      setFormSubmitted(true);
    } else {
      validator.current.showMessages();
    }
  };
  useEffect(() => {
    if (formSubmitted && props.createMaster !== null) {
      props.onModalDismiss(false);
      setFormSubmitted(false);
    }
  }, [formSubmitted && props.createMaster !== null]);
  const operationType = !params.id ? "Submit" : "Update";
  const [imgUpload, setImgUpload] = useState(null);
  const [imageName, setImageName] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const handleFileChange = (event, setFieldValue) => {
    const { files } = event.currentTarget;
    const selectedFile = files[0];
    let FileType = "characterAvtar";
    handleFileUpload(
      selectedFile,
      FileType,
      setFieldValue,
      setImgUpload,
      setImageName,
      setUploadProgress
    );
  };
  const handleClearImage = (setFieldValue) => {
    setFieldValue("characterAvtar", null);
    setImgUpload([]);
    setImageName("");
    setUploadProgress(0);
  };
  useEffect(() => {
    params.id && setUploadProgress(100);
  }, [params.id]);
  return (
    <>
      <Formik initialValues={CharacterData} onSubmit={handleSubmit}>
        {({ isSubmitting, values, setFieldValue }) => (
          <Form>
            <div className="dropzone">
              <>
                <label
                  htmlFor="characterAvtar"
                  className="dropzone-container mb-2"
                >
                  <div className="file-icon">
                    <ImageSvg />
                  </div>
                  <div className="dropzone-title">
                    Drag and drop your files or{" "}
                    <span className="browse">browse</span> your files
                  </div>
                </label>
                <input
                  id="characterAvtar"
                  name="characterAvtar"
                  type="file"
                  accept="image/*"
                  onChange={(event) => handleFileChange(event, setFieldValue)}
                  className="form-control"
                />
              </>

              {validator.current.message(
                __t("validations.characterImage.Image"),
                values.characterAvtar,
                "required",
                {
                  messages: {
                    required: __t("validations.characterImage.Image"),
                  },
                }
              )}

              {values.characterAvtar && (
                <div className="attachment-data">
                  <div className="attachment-head">
                    <a
                      href={
                        imgUpload === null ? values.characterAvtar : imgUpload
                      }
                      title={
                        imgUpload === null &&
                        typeof values.characterAvtar === "string"
                          ? values.characterAvtar.substring(
                              values.characterAvtar.lastIndexOf("/") + 1
                            )
                          : imageName
                          ? imageName
                          : "File Name"
                      }
                      className="file-box"
                    >
                      {/* <FileIconSvg /> */}
                      {/* <h6>
                                                {imgUpload === null &&
                                                    typeof values.characterAvtar === "string"
                                                    ? values.characterAvtar.substring(
                                                        values.characterAvtar.lastIndexOf("/") + 1
                                                    )
                                                    : imageName
                                                        ? imageName
                                                        : "File Name"}
                                            </h6> */}
                      <img
                        src={
                          imgUpload === null ? values.characterAvtar : imgUpload
                        }
                        alt="Character Image"
                      />
                    </a>
                    <a
                      onClick={() => handleClearImage(setFieldValue)}
                      className="remove-box"
                      title="remove"
                    >
                      <DeleteSvg />
                    </a>
                  </div>
                  {uploadProgress < 100 && (
                    <Progress
                      animated
                      color="primary"
                      className="progress_bar mt-1"
                      style={{
                        height: "0.5rem",
                      }}
                      value={uploadProgress}
                    ></Progress>
                  )}
                </div>
              )}
            </div>

            <div className="btn-box">
              <button
                className="btn btn-primary"
                type="submit"
                disabled={isSubmitting}
              >
                {operationType}
              </button>
              <button
                className="btn btn-outline"
                type="button"
                onClick={() => {
                  props.onModalDismiss(false);
                }}
              >
                cancel
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    createMaster: state?.master?.createMaster,
  };
};
const mapDispatchToProps = (dispatch) => ({
  addCharacter: (data) => {
    dispatch(addMasterDataRequest(data));
  },
  updateCharacter: (data) => {
    dispatch(editMasterDataRequest(data));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(AddMasterCharacter);
