import React, { useEffect, useRef } from "react";
import { Formik, Form, Field } from "formik";
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import { Link, useNavigate } from "react-router-dom";
import { __t } from "../../i18n/translator";
import Logo from "../../assets/images/logo.png";
import Logoshape from "../../assets/images/banner-shape1.png";
import Logoshape2 from "../../assets/images/banner-shape2.png";
import Logoshape3 from "../../assets/images/banner-shape3.png";
import { forgetPasswordRequest } from "../../redux/auth/forgot/action";
import { Button } from "reactstrap";

const ForgotPassword = (props) => {
  const navigate = useNavigate();
  const validator = useRef(new SimpleReactValidator());
  const initialValues = {
    email: "",
  };

  const handleSubmit = async (values) => {
    if (validator.current.allValid()) {
      props.forgetRequest(values);
      JSON.parse(localStorage.setItem("email", values.email));
    } else {
      validator.current.showMessages();
    }
  };
  useEffect(() => {
    if (localStorage.getItem("accessToken") && props.forgot.user !== null) {
      navigate("/forgot-password");
    }
  }, [props.forgot]);
  return (
    <div className="login-page">
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ isSubmitting, values }) => (
          <div className="login-main">
            <div className="main-logo" title="Kidlit Katalyst">
              <img alt="Kidlit Katalyst" src={Logo} />
            </div>
            <div className="login-head">
              <h2>Forgot Password</h2>
              <p>
                No worries, enter your registered email ID,
                <br></br>
                we'll email you an OTP to reset your password.
              </p>
            </div>
            <Form>
              <div className="form-group mb-0 slide-left-box">
                <label htmlFor="email">Email ID</label>
                <Field
                  type="email"
                  id="email"
                  name="email"
                  className="form-control"
                  placeholder="Enter valid email id"
                />
                {validator.current.message(
                  __t("validations.auth.email"),
                  values.email,
                  "required",
                  {
                    messages: {
                      required: __t("validations.auth.email"),
                    },
                  }
                )}
              </div>
              <Button
                className="btn-outline-primary mt-4"
                title="submit"
                type="submit"
                disabled={isSubmitting}
              >
                Submit
              </Button>
            </Form>
            <Link title="Back to login" className="mt-3" to="/login">
              Back to login
            </Link>
          </div>
        )}
      </Formik>
      <div className="shape-box">
        <img alt="Shape" src={Logoshape2} />
        <img alt="Shape" src={Logoshape3} />
        <img alt="Shape" src={Logoshape} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    forgot: state.forgot,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    forgetRequest: (creds) => {
      dispatch(forgetPasswordRequest(creds));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
