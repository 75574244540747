import React, { useEffect, useState } from "react";
import CommonTable from "../../components/Table/CommonTable";
import { connect } from "react-redux";
import {
  creditStoryRequest,
  getActiveRequest,
  getReportStoryRequest,
} from "../../redux/reportstory/action";
import { getDateTimeFromDateTimeString } from "../../helper/DataTimeHelpers";
import { commonConfirmBox } from "../../helper/messageHelper";
import { EditSvg, ViewSvg } from "../../components/Common/CommonSvg";
import CustomisedModal from "../../components/Modal/CustomisedModal";

import { Button } from "reactstrap";
import ViewReportStory from "./ViewReportStory";

function ReportStory(props) {
  const { totalRecords } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage, setDataPerPage] = useState(10);
  const [searchData, setSearchData] = useState("");
  const [isView, setIsView] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleViewClick = (row) => {
    setIsModalOpen(!isModalOpen);
    setIsView(row);
  };
  const handleDeleteClick = async (row) => {
    const response = await commonConfirmBox(
      !row.is_true &&
      "By unreporting, this story will be visible to user. Are you sure you want to unreport this story?"
    );
    if (response) {
      props.removeList({
        id: row.story_id,
        currentPage,
        dataPerPage,
      });
    }
  };
  const handlePublish = async (row) => {
    const response = await commonConfirmBox(
      row.is_true
        ? "Do you want to inactive this story?"
        : "Do you want to active this story?"
    );
    if (response) {
      props.removeList({
        id: row.story_id,
        currentPage,
        dataPerPage,
      });
    }
  };
  useEffect(() => {
    setCurrentPage(1);
  }, [dataPerPage, searchData]);
  useEffect(() => {
    props.getList({
      limit: dataPerPage,
      page: currentPage,
      searchText: searchData,
    });
  }, [currentPage, dataPerPage, searchData]);
  // useEffect(() => {
  //   if (searchData) {
  //     props.getList({
  //       limit: dataPerPage,
  //       page: currentPage,
  //       searchText: searchData,
  //     });
  //   }
  // }, [searchData]);

  const columns = [
    {
      dataField: "placeImage",
      text: "Image",
      formatter: (cell, row) => (
        <div className="table-img-box">
          <img
            src={row.placeImage}
            alt="Place Image"
            height="auto"
            width="100px"
          />
        </div>
      ),
      sort: true,
      headerClasses: "table-head-img-box",
    },
    {
      dataField: "title",
      text: "Title / Story Prompt",
      sort: true,
      formatter: (cell, row) => <><b className="mb-1 d-block">{row.title}</b><p>{row.story_prompt}</p></>,
      headerClasses: "story-title-box",
    },
    // {
    //   dataField: "story_prompt",
    //   text: "Story Prompt",
    //   sort: true,
    //   formatter: (cell, row) => <p>{row.story_prompt}</p>,
    // },
    {
      dataField: "email",
      text: "Email",
      formatter: (cell, row) => <>{row.email}</>,
      sort: true,
    },
    {
      dataField: "created_date",
      text: "Created Date",
      sort: true,
      formatter: (cell, row) => getDateTimeFromDateTimeString(row.created_date),
      headerClasses: "date-box",
    },
    {
      dataField: "report_reason",
      text: "Reason",
      sort: true,
      formatter: (cell, row) => <p>{row.report_reason}</p>,
      headerClasses: "date-box",
    },
    {
      dataField: "status",
      formatter: (cell, row) => {
        if (row?.isCredit) {
          return (
          <div className="d-flex gap-2 justify-content-end status-box">
              <button
                type="button"
                class="edit-btn btn btn-success btn-sm"
                disabled
              >
                Story Credited
              </button>
              <button
                Link
                className="btn btn-link text-success d-inline"
                onClick={() => handleViewClick(row)}
                state={{ storyData: row }}
              >
                <ViewSvg />
              </button>
            </div>


          );
        } else {
          return (
            <div className="d-flex gap-2 justify-content-end">
              <Button
                color="success"
                size="sm"
                className="edit-btn"
                onClick={async () => {
                  const response = await commonConfirmBox(
                    "Are you sure do you want to credit this story?"
                  );
                  if (response) {
                    props.creditStory({
                      storyId: row?.story_id,
                      parentId: row?.parentId,
                    });
                  }
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 1792 1792"
                >
                  <rect
                    x="0"
                    y="0"
                    width="1792"
                    height="1792"
                    fill="none"
                    stroke="none"
                  />
                  <path
                    fill="currentColor"
                    d="M1764 11q33 24 27 64l-256 1536q-5 29-32 45q-14 8-31 8q-11 0-24-5l-453-185l-242 295q-18 23-49 23q-13 0-22-4q-19-7-30.5-23.5T640 1728v-349l864-1059l-1069 925l-395-162q-37-14-40-55q-2-40 32-59L1696 9q15-9 32-9q20 0 36 11z"
                  />
                </svg>{" "}
                Credit
              </Button>
              <Button
                color="danger"
                size="sm"
                className="edit-btn"
                onClick={() => handleDeleteClick(row)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 1792 1792"
                >
                  <rect
                    x="0"
                    y="0"
                    width="1792"
                    height="1792"
                    fill="none"
                    stroke="none"
                  />
                  <path
                    fill="currentColor"
                    d="M1764 11q33 24 27 64l-256 1536q-5 29-32 45q-14 8-31 8q-11 0-24-5l-453-185l-242 295q-18 23-49 23q-13 0-22-4q-19-7-30.5-23.5T640 1728v-349l864-1059l-1069 925l-395-162q-37-14-40-55q-2-40 32-59L1696 9q15-9 32-9q20 0 36 11z"
                  />
                </svg>
                unreport
              </Button>
              <button
                Link
                className="btn btn-link text-success d-inline"
                onClick={() => handleViewClick(row)}
                state={{ storyData: row }}
              >
                <ViewSvg />
              </button>
            </div>
          );
        }
      },
      sort: false,
      headerClasses: "status-box",
    },
    // {
    //   dataField: "Action",
    //   // text: "Action",
    //   formatter: (cell, row) => (
    //     <>

    //     </>
    //   ),
    //   sort: false,
    //   classes: "text-center",
    //   headerClasses: "action-box text-center", // Add custom class to header
    // },
  ];
  return (
    <>
      <CommonTable
        data={props.getData ? props.getData : []}
        columns={columns}
        searchBar={true}
        customClass={"reportstory-table"}
        currentPage={currentPage}
        totalRecords={totalRecords}
        setSearchData={setSearchData}
        setDataPerPage={setDataPerPage}
        Header={true}
        dataPerPage={dataPerPage}
        pagination={true}
        updateData={() =>
          props.getList({
            limit: dataPerPage,
            page: currentPage,
            searchText: "",
          })
        }
        setCurrentPage={setCurrentPage}
      />
      {isModalOpen && (
        <CustomisedModal
          isModalOpen={isModalOpen}
          modalName={"View Story"}
          onModalDismiss={() => {
            setIsModalOpen(false);
            setIsView(null);
          }}
        >
          <ViewReportStory
            onModalDismiss={() => {
              setIsModalOpen(false);
              setIsView(null);
            }}
            data={isView}
          />
        </CustomisedModal>
      )}
    </>
  );
}
const mapStateToProps = (state) => {
  let DataList;
  let totalRecords = 0;

  if (
    state?.reportStory?.ReportStory?.list &&
    state?.reportStory.ReportStory.list.length > 0
  ) {
    DataList = state.reportStory.ReportStory.list;
    totalRecords = state.reportStory.ReportStory.totalRecords;
  }
  return {
    getData: DataList,
    totalRecords: totalRecords,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getList: (data) => {
      dispatch(getReportStoryRequest(data));
    },
    removeList: (data) => {
      dispatch(getActiveRequest(data));
    },
    creditStory: (data) => {
      dispatch(creditStoryRequest(data));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ReportStory);
