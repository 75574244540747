import {
    ADD_COUPONS_REQUEST,
    ADD_COUPONS_SUCCESS,
    DELETE_COUPONS_REQUEST,
    DELETE_COUPONS_SUCCESS,
    REDEEM_COUPONS_REQUEST,
    REDEEM_COUPONS_SUCCESS,
    REMOVE_COUPONS_REQUEST,
    REMOVE_COUPONS_SUCCESS,
    UPDATE_COUPONS_REQUEST,
    UPDATE_COUPONS_SUCCESS,

} from "./type";

const initialState = {
    getCoupons: null,
    addCoupons: null,
    updateCoupons: null,
    removeCoupons: null,
};
const couponsReducer = (state = initialState, action) => {
    switch (action.type) {
        case REDEEM_COUPONS_REQUEST:
            return {
                ...state
            };
        case REDEEM_COUPONS_SUCCESS:
            return {
                ...state,
                getCoupons: action.payload,
            };
        case DELETE_COUPONS_REQUEST:
            return {
                ...state
            };
        case DELETE_COUPONS_SUCCESS:
            return {
                ...state,
                deleteCoupon: action.payload,
            };
        case ADD_COUPONS_REQUEST:
            return {
                ...state,
                addCoupons: action.payload,
            };
        case ADD_COUPONS_SUCCESS:
            return {
                ...state,
                addCoupons: action.payload,
            };
        case UPDATE_COUPONS_REQUEST:
            return {
                ...state,
                updateCoupons: action.payload,
            };
        case UPDATE_COUPONS_SUCCESS:
            return {
                ...state,
                updateCoupons: action.payload,
            };
        case REMOVE_COUPONS_REQUEST:
            return {
                ...state,
                removeCoupons: action.payload,
            };
        case REMOVE_COUPONS_SUCCESS:
            return {
                ...state,
                removeCoupons: action.payload,
            };
        default:
            return { ...state };
    }
};
export default couponsReducer;
