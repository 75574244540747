import { ADD_PLANS_REQUEST, ADD_PLANS_SUCCESS, EDIT_PLANS_REQUEST, EDIT_PLANS_SUCCESS, GET_PLANS_REQUEST, GET_PLANS_SUCCESS, REMOVE_PLANS_REQUEST, REMOVE_PLANS_SUCCESS } from "./type";

export const getPlansRequest = () => {
    return {
        type: GET_PLANS_REQUEST,
    };
};
export const getPlansSuccess = (plans) => {
    return {
        type: GET_PLANS_SUCCESS,
        payload: plans,
    };
};

export const addPlansRequest = (plans) => {
    return {
        type: ADD_PLANS_REQUEST,
        payload: plans,
    };
};
export const addPlansSuccess = (plans) => {
    return {
        type: ADD_PLANS_SUCCESS,
        payload: plans,
    };
};
export const editPlansRequest = (plans) => {
    return {
        type: EDIT_PLANS_REQUEST,
        payload: plans,
    };
};
export const editPlansSuccess = (plans) => {
    return {
        type: EDIT_PLANS_SUCCESS,
        payload: plans,
    };
};

export const removePlansRequest = (plans) => {
    return {
        type: REMOVE_PLANS_REQUEST,
        payload: plans,
    };
};
export const removePlansSuccess = (plans) => {
    return {
        type: REMOVE_PLANS_SUCCESS,
        payload: plans,
    };
};
