export const GET_API_MANAGEMENT_DETAILS_REQUEST =
  "GET_API_MANAGEMENT_DETAILS_REQUEST";
export const GET_API_MANAGEMENT_DETAILS_SUCCESS =
  "GET_API_MANAGEMENT_DETAILS_SUCCESS";

export const UPDATE_API_MANGEMENT_DETAILS_REQUEST =
  "UPDATE_API_MANGEMENT_DETAILS_REQUEST";
export const UPDATE_API_MANGEMENT_DETAILS_SUCCESS =
  "UPDATE_API_MANGEMENT_DETAILS_SUCCESS";

export const ADD_API_MANGEMENT_DETAILS_REQUEST =
  "ADD_API_MANGEMENT_DETAILS_REQUEST";
export const ADD_API_MANGEMENT_DETAILS_SUCCESS =
  "ADD_API_MANGEMENT_DETAILS_SUCCESS";
