import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import {
  ADD_PLANS_REQUEST,
  EDIT_PLANS_REQUEST,
  GET_PLANS_REQUEST,
  REMOVE_PLANS_REQUEST,
} from "./type";
import { invokeApi } from "../../../enverment/axiosHelper";
import {
  addPlansSuccess,
  editPlansSuccess,
  getPlansRequest,
  getPlansSuccess,
  removePlansSuccess,
} from "./action";
import { handleApiCallException } from "../../shared/action";
import { DANGER, SUCCESS } from "../../constant/commonConstants";
import { ApiResponseMessage } from "../../../helper/tosterHelpers";
function* fetchPlans({ payload: data }) {
  try {
    const response = yield call(invokeApi, "get", `/planList`, data);
    if (response.status) {
      yield put(getPlansSuccess(response.data));
    } else {
      ApiResponseMessage(response.message, DANGER);
    }
  } catch (error) {
    yield put(handleApiCallException(error));
  }
}
function* addPlans({ payload: data }) {
  try {
    const response = yield call(invokeApi, "post", `/addPlan`, data);
    if (response.status) {
      yield put(addPlansSuccess(response.data));
      ApiResponseMessage(response.message, SUCCESS);
      yield put(getPlansRequest());
    } else {
      yield put(addPlansSuccess());
      ApiResponseMessage(response.message, DANGER);
    }
  } catch (error) {
    yield put(handleApiCallException(error));
  }
}
function* editPlans({ payload: data }) {
  try {
    const response = yield call(
      invokeApi,
      "put",
      `/updatePlan/${data.id}`,
      data
    );
    if (response.status) {
      yield put(editPlansSuccess(response.data));
      ApiResponseMessage(response.message, SUCCESS);
      yield put(getPlansRequest());
    } else {
      yield put(editPlansSuccess());
      ApiResponseMessage(response.message, DANGER);
    }
  } catch (error) {
    yield put(handleApiCallException(error));
  }
}
function* removePlans({ payload: data }) {
  try {
    const response = yield call(invokeApi, "post", "/deletePlan", data);
    if (response.status) {
      yield put(removePlansSuccess(response.data));
      ApiResponseMessage(response.message, SUCCESS);
      yield put(getPlansRequest({}));
    } else {
      yield put(removePlansSuccess());
      ApiResponseMessage(response.message, DANGER);
    }
  } catch (error) {
    yield put(handleApiCallException(error));
  }
}
function* watchPlansSagaRequest() {
  yield takeLatest(GET_PLANS_REQUEST, fetchPlans);
  yield takeLatest(ADD_PLANS_REQUEST, addPlans);
  yield takeLatest(EDIT_PLANS_REQUEST, editPlans);
  yield takeLatest(REMOVE_PLANS_REQUEST, removePlans);
}

function* plansSaga() {
  yield all([fork(watchPlansSagaRequest)]);
}

export default plansSaga;
