import React, { useState, useEffect } from "react";
import { getDateTimeFromDateTimeString } from "../../helper/DataTimeHelpers";
import { DateSvg, DeleteSvg, EditSvg } from "../../components/Common/CommonSvg";
import { connect } from "react-redux";
import CustomisedModal from "../../components/Modal/CustomisedModal";
import {
  commonConfirmBox,
  commonMessangerBox,
} from "../../helper/messageHelper";
import {
  getRedeemCouponsRequest,
  removeRedeemCouponsRequest,
} from "../../redux/redeemcoupons/action";
import AddCoupon from "./AddCoupon";
import TableHeader from "../../components/Table/TableHeader";
import { Link } from "react-router-dom";

function Coupons(props) {
  const [getList, setGetList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(null);
  const { getData } = props;
  useEffect(() => {
    props.getList();
  }, []);
  useEffect(() => {
    if (getData) {
      setGetList(getData);
    }
  }, [getData]);

  const handleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const handleEditClick = (row) => {
    if (row?.isActive) {
      setIsModalOpen(!isModalOpen);
      setIsEdit(row);
    } else {
      commonMessangerBox("Before updating coupons you have to activate status");
    }
  };

  const handleDeleteClick = async (row) => {
    const response = await commonConfirmBox(
      row.isActive === 1
        ? "Do you want to inactive this coupons?"
        : "Do you want to active this coupons?"
    );
    if (response) {
      props.removeCoupon({
        id: row.id,
        isActive: !row.isActive,
      });
    }
  };
  
  const handleDelete = async (row) => {
    const response = await commonConfirmBox("Do you want to delete this coupons?");
    if (response) {
      props.deleteCoupon({
        id: row.id,
        isActive: !row.isActive,
      });
    }
  };
  
  return (
    <>
      <TableHeader href={handleModal} buttonName={"Add Coupon"} />

      <ul className="coupons-list">
        {getList.map((e) => {
          return (
            <li className="coupon">
              <div className="coupon-left">
                <div className="dis-box">
                  <p>₹{e?.discountAmount}</p>
                  <span>off</span>
                </div>
                <div>
                  <div className="coupon-detail">
                    <span>{e?.couponCode}</span>
                    <div className="coupon-date">
                      <DateSvg />
                      {getDateTimeFromDateTimeString(e?.validityEndDate)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="coupon-con">
                <div>
                  <h5>₹{e?.minPurchase}</h5>
                  {/* <Link
                    className="btn btn-link edit-btn"
                    title="Edit"
                    onClick={() => handleDelete(e)}
                  >
                    <DeleteSvg />
                  </Link> */}
                  <Link
                    className="btn btn-link edit-btn"
                    title="Edit"
                    onClick={() => handleEditClick(e)}
                  >
                    <EditSvg />
                  </Link>
                </div>
                <div className="switch">
                  <input
                    type="checkbox"
                    checked={e?.isActive}
                    onChange={() => handleDeleteClick(e)}
                  />
                  <label
                    className="switch-check"
                    onClick={() => handleDeleteClick(e)}
                  >
                    <div className="switch-btn"></div>
                    <div className="active-switch">Active</div>
                    <div className="deactive-switch">Inactive</div>
                  </label>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
      {getList.length === 0 && (
        <h4 className="text-center mt-5 mb-5">No Data Found</h4>
      )}

      {isModalOpen && (
        <CustomisedModal
          CustomisedModal
          isModalOpen={isModalOpen}
          modalName={!isEdit ? "Add Coupon" : "Edit Coupon"}
          onModalDismiss={() => {
            handleModal();
            setIsEdit(null);
          }}
        >
          <AddCoupon
            onModalDismiss={() => {
              handleModal();
              setIsEdit(null);
            }}
            data={isEdit}
          />
        </CustomisedModal>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  let DataList = [];
  if (
    state?.couponsReducer?.getCoupons?.couponList &&
    state?.couponsReducer?.getCoupons?.couponList.length > 0
  ) {
    DataList = state.couponsReducer.getCoupons.couponList;
  }
  return {
    getData: DataList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getList: () => {
      dispatch(getRedeemCouponsRequest());
    },
    removeCoupon: (data) => {
      dispatch(removeRedeemCouponsRequest(data));
    },
    deleteCoupon: (data) => {
      dispatch(removeRedeemCouponsRequest(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Coupons);
