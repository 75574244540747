import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { GET_GROUP_LOG_LIST_REQUEST, GET_USER_LOG_REQUEST } from "./type";
import { getGroupLogListSuccess, getUserLogSuccess } from "./action";
import { invokeApi } from "../../../enverment/axiosHelper";
import { handleApiCallException } from "../../shared/action";

function* getUserLogData(action) {
  let res;
  try {
    res = yield call(invokeApi, "post", "/logList", action.payload);
    if (res.status) {
      yield put(getUserLogSuccess(res.data));
    } else {
      yield put(getUserLogSuccess());
    }
  } catch (error) {
    handleApiCallException(error.message);
  }
}

function* getGroupUserLogData(action) {
  let res;
  try {
    res = yield call(invokeApi, "post", "/groupLogList", action.payload);
    if (res.status) {
      yield put(getGroupLogListSuccess(res.data));
    } else {
      yield put(getGroupLogListSuccess());
    }
  } catch (error) {
    handleApiCallException(error.message);
  }
}

function* watchgetUserLogRequest() {
  yield takeLatest(GET_USER_LOG_REQUEST, getUserLogData);
  yield takeLatest(GET_GROUP_LOG_LIST_REQUEST, getGroupUserLogData);
}

function* getUserLogSaga() {
  yield all([fork(watchgetUserLogRequest)]);
}
export default getUserLogSaga;
