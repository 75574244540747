import {
    ADD_SCENE_REQUEST,
    ADD_SCENE_SUCCESS,
    DELETE_SCENE_REQUEST,
    DELETE_SCENE_SUCCESS,
    EDIT_SCENE_REQUEST,
    EDIT_SCENE_SUCCESS,
    GET_SCENE_FAILURE,
    GET_SCENE_REQUEST,
    GET_SCENE_SUCCESS,
} from "./type";

const initialState = {
    getScene: null,
    createScene: null,
    updateScene: null,
    removeScene: null,
};

const sceneReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SCENE_REQUEST:
            return {
                ...state,
            };
        case GET_SCENE_SUCCESS:
            return {
                ...state,
                getScene: action.payload,
            };
        case GET_SCENE_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        case ADD_SCENE_REQUEST:
            return {
                ...state,
                createScene: action.payload,
            };
        case ADD_SCENE_SUCCESS:
            return {
                ...state,
                createScene: action.payload,
            };
        case DELETE_SCENE_REQUEST:
            return {
                ...state,
                removeScene: action.payload,
            };
        case DELETE_SCENE_SUCCESS:
            return {
                ...state,
                removeScene: action.payload,
            };
        case EDIT_SCENE_REQUEST:
            return {
                ...state,
            };
        case EDIT_SCENE_SUCCESS:
            return {
                ...state,
                Scene: action.payload,
            };
        default:
            return { ...state };
    }
};

export default sceneReducer;
