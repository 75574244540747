import React, { useState } from 'react'
import Voice from './Voice'
import Language from './Language'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import TableHeader from '../../../components/Table/TableHeader'
import { LanguageSvg, VoiceSvg } from '../../../components/Common/CommonSvg'

export default function VoiceLanguage() {
    const [tabActive, setTabActive] = useState("Voice")
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleModal = () => {
        setIsModalOpen(!isModalOpen);
    };
    return (
        <>
            <TableHeader
                href={handleModal}
                buttonName={tabActive === "Voice" ? "Add Voice" : "Add Language"}
                searchBar={false}
            />
            <Nav className="nav-underline voice_tab">
                <NavItem>
                    <NavLink
                        className={tabActive === "Voice" ? "active" : " "}
                        onClick={() => setTabActive("Voice")}
                    >
                        <span><VoiceSvg /></span>
                        <span>Voice</span>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={tabActive === "Language" ? "active" : " "}
                        onClick={() => setTabActive("Language")}
                    >
                        <span><LanguageSvg /></span>
                        <span>Language</span>
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent className='card voice_tabcontent' activeTab={tabActive}>
                <TabPane tabId="Voice">
                    <Voice
                        setIsModalOpen={setIsModalOpen}
                        tabActive={tabActive}
                        isModalOpen={isModalOpen}
                        handleModal={handleModal}
                    />
                </TabPane>
                <TabPane tabId="Language">
                    <Language
                        setIsModalOpen={setIsModalOpen}
                        tabActive={tabActive}
                        isModalOpen={isModalOpen}
                        handleModal={handleModal}
                    />
                </TabPane>
            </TabContent>
        </>
    )
}
