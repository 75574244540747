import { ADD_EVENT_REQUEST, ADD_EVENT_SUCCESS, DELETE_EVENT_REQUEST, DELETE_EVENT_SUCCESS, EDIT_EVENT_REQUEST, EDIT_EVENT_SUCCESS, GET_EVENT_FAILURE, GET_EVENT_REQUEST, GET_EVENT_SUCCESS } from "./type";
export const getEventListRequest = (Data) => (
    {
        type: GET_EVENT_REQUEST,
        payload: Data

    }
);
export const getEventListSuccess = (Data) => {
    return {
        type: GET_EVENT_SUCCESS,
        payload: Data
    }
};

export const getEventListFailure = (error) => ({
    type: GET_EVENT_FAILURE,
    payload: error,
});
export const addEventListRequest = (data) => ({
    type: ADD_EVENT_REQUEST,
    payload: data
}
);
export const addEventListSuccess = (data) => ({
    type: ADD_EVENT_SUCCESS,
    payload: data

});
export const deleteEventListRequest = (eventId) => (
    {
        type: DELETE_EVENT_REQUEST,
        payload: eventId
    }
);
export const deleteEventListSuccess = (eventId) => ({
    type: DELETE_EVENT_SUCCESS,
    payload: eventId


});
export const editEventListRequest = (data) => (
    {
        type: EDIT_EVENT_REQUEST,
        payload: data
    }
);
export const editEventListSuccess = (updateData) => ({
    type: EDIT_EVENT_SUCCESS,
    payload: updateData
});