import React from 'react'
import { FormGroup, Input } from 'reactstrap'
import { commonConfirmBox } from '../../helper/messageHelper'

export default function ReactSwitch(props) {
    const { status, checkSwitch } = props;

    return (
        <FormGroup className='p-0 mb-0' switch>
            <div className="d-flex align-items-center justify-content-between">
                <label htmlFor="d-flex" className='mb-0'>Send Notification</label>
                <Input
                    onChange={async () => {
                        const response = await commonConfirmBox(
                            !status
                                ? 'Do you want to activate this template?'
                                : 'Do you want to inactive this template?',
                        )
                        if (response) {
                            checkSwitch(!status)
                        }
                    }}
                    type="switch"
                    id="custom-switch"
                    className="justify-content-center toggle_switch_btn"
                    checked={status || false} // Set default value to false
                />
            </div>
        </FormGroup>
    )
}